import client from '../client';

export const signInApi = ({ params }, cancelToken) =>
  client.post('/login', params, { cancelToken });

export const getResetPasswordTokenApi = ({ params }, cancelToken) =>
  client.get(`/reset-password/${params.slug}`, { cancelToken });

export const resetCoachPasswordApi = ({ params }, cancelToken) => {
  return client.post('/reset-password', params, { cancelToken });
};
