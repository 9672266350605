import { call, put, select } from 'redux-saga/effects';
import {
  getPositionGroupsAction,
  getGroupUsersAction,
  createPositionGroupsAction,
  editPositionGroupsAction,
  deletePositionGroupAction,
} from '../../actions/userGroups.actions';

import {
  getUserGroupsApi,
  getGroupUsersApi,
  createPositionGroupApi,
  editPositionGroupApi,
  removePositionGroupApi,
} from '../../services/userGroups.service';
import {
  createPositionGroupsError,
  editPositionGroupsError,
  deletePositionGroupsError,
} from '../../selectors/userGroups.selector';

export const watchGetPositionGroups = {
  ...getPositionGroupsAction,
  api: getUserGroupsApi,
};

export const watchGetGroupUsers = {
  ...getGroupUsersAction,
  api: getGroupUsersApi,
};

export const watchCreatePositionGroup = {
  ...createPositionGroupsAction,
  api: createPositionGroupApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const error = yield select(createPositionGroupsError);
    if (!error) {
      yield put(
        getPositionGroupsAction.request({
          page: action.params.page,
        }),
      );
    } else {
      console.log('error');
    }
  },
};

export const watchEditPositionGroup = {
  ...editPositionGroupsAction,
  api: editPositionGroupApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const error = yield select(editPositionGroupsError);
    if (!error) {
      yield put(
        getPositionGroupsAction.request({
          page: action.params.page,
        }),
      );
    } else {
      console.log('error');
    }
  },
};

export const watchDeletePositionGroup = {
  ...deletePositionGroupAction,
  api: removePositionGroupApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const error = yield select(deletePositionGroupsError);
    if (!error) {
      yield put(
        getPositionGroupsAction.request({
          page: action.params.page,
        }),
      );
    } else {
      console.log('error');
    }
  },
};
