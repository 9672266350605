import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_MIGRATION_STATUS } from './operations/queries/profile';
import { ATHENA_REDIRECT_PAGE, MIGRATION_IN_PROGRESS_PAGE } from './constants/migration';
import history from './history';

export const useMigrationStatus = () => {
  const { refetch, error } = useQuery(GET_MIGRATION_STATUS);
  const [pathName, setPathName] = useState('');
  useEffect(() => {
    const hist = history.listen(({ pathname: currentUrl }) => {
      setPathName(currentUrl);
    });
    return hist;
  }, [error]);

  useEffect(() => {
    refetch()
      .then(({ data: { instructorProfile } = {} } = {}) => {
        const isRedirectToAthena = instructorProfile?.school?.redirect_to_athena;
        if (isRedirectToAthena && !instructorProfile?.is_approved_email) {
          history.push(ATHENA_REDIRECT_PAGE);
        } else if (!isRedirectToAthena && instructorProfile?.school?.athena_migration_in_progress) {
          history.push(MIGRATION_IN_PROGRESS_PAGE);
        }
      })
      .catch(() => {
        // no op
      });
  }, [pathName]);
};
