import { errorToast } from '../components/Ui/Notification';
import { makeRequestAction, makeAction } from './action-generator';

export const setCurrentAlbumAction = makeAction('SET_CURRENT_ALBUM');

export const setCurrentCollectionAction = makeAction('SET_CURRENT_COLLECTION');

export const changeMediaOrderSync = makeAction('CHANGE_MEDIA_ORDER_SYNC');

export const getAlbumsAction = makeRequestAction('GET_ALBUMS', {
  onSuccess(params, response) {
    params.onSuccess && params.onSuccess();
    return {
      folders: response.data?.result?.folders,
      categories: response.data?.result?.categories,
      code: response.code,
    };
  },
});

export const addCollectionAction = makeRequestAction('ADD_COLLECTION', {
  onSuccess(params, response) {
    params.onSuccess && params.onSuccess();
    return response;
  },
});

export const getCollectionsAction = makeRequestAction('GET_COLLECTION', {
  onSuccess(params, response) {
    return {
      categories: response.data.result?.categories,
      folders: response.data.result?.folders,
      categoriesWithoutFolder: response.data.result?.categoriesWithoutFolder,
      categoryCode: response.code,
      message: response.message,
    };
  },
});

export const updateCollectionAction = makeRequestAction('UPDATE_COLLECTION', {
  onSuccess(params) {
    params.onSuccess && params.onSuccess();
    return undefined;
  },
});

export const deleteCollectionAction = makeRequestAction('DELETE_COLLECTION', {
  onSuccess(params, response) {
    return response;
  },
});

export const changeCollectionOrderAction = makeRequestAction('CHANGE_COLLECTION_ORDER', {
  onSuccess(params) {
    return {
      collections: JSON.parse(params.categories)?.categories,
    };
  },
});

export const getUserGroupsAction = makeRequestAction('GET_USER_GROUPS', {
  onSuccess(params, response) {
    return {
      groups: response.data?.result?.groups,
      defaultPlayers: response.data?.result?.players,
    };
  },
});

export const addAlbumAction = makeRequestAction('ADD_ALBUM', {
  onSuccess(params, response) {
    params.onFinish && params.onFinish();
    return response;
  },
  onFailure(params) {
    params.onFinish && params.onFinish();
  },
});

export const updateAlbumAction = makeRequestAction('UPDATE_ALBUM', {
  onSuccess(params, response) {
    params.onFinish && params.onFinish();
    return response;
  },
  onFailure(params) {
    params.onFinish && params.onFinish();
  },
});

export const deleteAlbumAction = makeRequestAction('DELETE_ALBUM', {
  onSuccess(params, response) {
    params.onFinish && params.onFinish();
    return response;
  },
  onFailure(params, response) {
    errorToast('Error', response.error?.message || 'Something went wrong.');
  },
});

export const moveCollectionIntoAlbumAction = makeRequestAction('MOVE_COLLECTION_INTO_FOLDER', {
  onSuccess(params, response) {
    return response;
  },
});

export const changeAlbumOrderAction = makeRequestAction('CHANGE_ALBUM_ORDER', {
  onSuccess(params, response) {
    return response;
  },
});

export const getAllMediaAction = makeRequestAction('GET_ALL_MEDIA', {
  onSuccess(params, response) {
    params.onSuccess && params.onSuccess();
    return {
      media: response.data.result,
      code: response.code,
      cat_id: params.category_id,
    };
  },
});

export const addMediaAction = makeRequestAction('ADD_MEDIA', {
  onSuccess(params, response) {
    params.onFinish && params.onFinish();
    return response;
  },
  onFailure(params) {
    params.onFinish && params.onFinish();
  },
});

export const addPresentationAction = makeRequestAction('ADD_PPT', {
  onSuccess(params, response) {
    params.onFinish && params.onFinish();
    return response;
  },
  onFailure(params, response) {
    params.onFinish && params.onFinish();
    errorToast('Error', response.error?.message || 'Something went wrong.');
  },
});

export const updateMediaAction = makeRequestAction('UPDATE_MEDIA', {
  onSuccess(params, response) {
    params.onFinish && params.onFinish();
    return response;
  },
  onFailure(params) {
    params.onFinish && params.onFinish();
  },
});

export const deleteMediaAction = makeRequestAction('DELETE_MEDIA', {
  onSuccess(params, response) {
    return response;
  },
});

export const changeMediaOrderAction = makeRequestAction('CHANGE_MEDIA_ORDER', {
  onSuccess(params, response) {
    return response;
  },
});
