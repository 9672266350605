import { gql } from '@apollo/client';

export const GET_PROFILE_STATUS = gql`
  query ProfileStatus {
    isLoggedIn @client
    instructorProfile {
      id
      is_password_temp
      is_password_exp
      name
      email
      type
      is_approved_email
      school {
        id
        name
        is_blocked
        created_at
        athena_migration_in_progress
        redirect_to_athena
      }
    }
  }
`;

export const GET_MIGRATION_STATUS = gql`
  query ProfileStatus {
    isLoggedIn @client
    instructorProfile {
      school {
        athena_migration_in_progress
        redirect_to_athena
      }
    }
  }
`;

export const GET_USER_CONTAINER_DATA = gql`
  query UserContainer {
    instructorProfile {
      id
      type
      isManager @client
      school {
        id
        category_text
        wording
      }
    }
  }
`;

export const GET_USER_GROUP_CONTAINER_DATA = gql`
  query UserGroupContainer {
    instructorProfile {
      id
      type
      isManager @client
      school {
        id
        wording
      }
    }
  }
`;

export const GET_PROFILE_PAGE_DATA = gql`
  query ProfilePage {
    instructorProfile {
      id
      name
      email
      image
      type
      isAdmin @client
      contact
      school {
        id
        name
      }
    }
  }
`;

export const GET_USER_HEADER_DATA = gql`
  query HeaderData {
    instructorProfile {
      id
      name
      image
      school {
        id
        name
        is_employee
        is_lesson_quiz_export_enabled
        wording
        dashboards {
          id
          tier
        }
      }
    }
  }
`;

export const GET_USER_TYPE = gql`
  query UserType {
    instructorProfile {
      id
      type
      isAdmin @client
      isManager @client
    }
  }
`;

export const GET_ADMIN_DATA = gql`
  query UserType {
    instructorProfile {
      id
      type
      isAdmin @client
      isManager @client
      school {
        name
      }
    }
  }
`;
