import { clearLocalStorage } from '../../utils/localStorage';
import history from '../../history';
import { MIGRATION_STATUS_ERROR_MSG } from '../../constants/errorMessages';

export const AUTH_CATEGORY = 'authentication';
export const MIGRATION_IN_PROGRESS_CATEGORY = 'migration_in_progress';
export const REDIRECT_TO_ATHENA_CATEGORY = 'redirect_to_athena';
export const GENERIC_ERROR_MSG = 'Something went wrong!';

/**
 * Handles completed responses
 *
 * @param {object} data The main data object returned from the mutation
 * @param {function} onSuccess Custom method for component-specific success handling
 * @param {function} onError Custom method for component-specific error handling
 */
export const handleOnCompleted = (data, onSuccess) => {
  if (onSuccess) {
    onSuccess(data);
  }
};

/**
 * Handles error responses based on whether or not the user is logged in
 *
 * @param {ApolloError} data ApolloError object from useMutation https://www.apollographql.com/docs/react/api/react/hooks/#function-signature-2
 * @param {function} onError Custom method for component-specific error handling
 */
export const handleOnError = (data, onError) => {
  if (isAuthError(data?.graphQLErrors)) {
    clearLocalStorage();
    history.push('/sign-in');
  } else if (isRedirectOnError(data?.graphQLErrors)) {
    history.push('/redirect-to-athena');
  } else if (isMigrationError(data?.graphQLErrors)) {
    history.push('/migration-in-progress');
  } else if (onError) {
    onError(data);
  }
};

/**
 * Checks if any error results from the user not being logged in
 *
 * @param {array} errors graphQLErrors array
 */
export const isAuthError = errors => {
  if (errors) {
    return errors.some(error => {
      const { extensions } = error;
      return extensions?.category === AUTH_CATEGORY;
    });
  }

  return false;
};

export const isMigrationError = errors => {
  if (errors) {
    return errors.some(error => {
      const { extensions } = error;
      return extensions?.category === MIGRATION_IN_PROGRESS_CATEGORY;
    });
  }
};

export const isRedirectOnError = errors => {
  if (errors) {
    return errors.some(error => {
      const { extensions } = error;
      return extensions?.category === REDIRECT_TO_ATHENA_CATEGORY;
    });
  }
};

/**
 * Gets the first error messages from an ApolloError object's graphQLErrors array, or returns a default if none exist
 *
 * @param {array} errors graphQLErrors array
 */
export const firstErrorMessage = data => {
  const { graphQLErrors } = data;
  return graphQLErrors?.[0]?.message ?? GENERIC_ERROR_MSG;
};
