import { makeRequestAction } from './action-generator';

export const forgotPasswordAction = makeRequestAction('FORGET_PASSWORD', {
  onSuccess(params, response) {
    params.onSuccess && params.onSuccess(response);
  },
  onFailure(params, error) {
    params.onFailure && params.onFailure(error?.error?.message);
  },
});
