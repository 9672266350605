import history from '../../history';
import { makeRequestAction } from '../action-generator';
import { success, error as errorAlert } from '../../components/NewUi/Alert';

export const getFolderGroupsAction = makeRequestAction('GET_FOLDER_GROUPS', {
  onSuccess(params, response) {
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const createFolderGroupAction = makeRequestAction('CREATE_FOLDER_GROUP', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const editFolderGroupTitleAction = makeRequestAction('EDIT_FOLDER_GROUP_TITLE', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const reorderFolderGroupsAction = makeRequestAction('REORDER_FOLDER_GROUPS', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const deleteFolderGroupAction = makeRequestAction('DELETE_FOLDER_GROUP', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const createFolderAction = makeRequestAction('CREATE_FOLDER', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const editFolderAction = makeRequestAction('EDIT_FOLDER', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const moveFolderAction = makeRequestAction('MOVE_FOLDER', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const duplicateFolderAction = makeRequestAction('DUPLICATE_FOLDER', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const reorderFoldersAction = makeRequestAction('REORDER_FOLDER', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const deleteFolderAction = makeRequestAction('DELETE_FOLDER', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const bulkDeleteFolderAction = makeRequestAction('BULK_DELETE_FOLDER', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const getPackagesAction = makeRequestAction('GET_PACKAGES', {
  onSuccess(params, response) {
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const createPackageAction = makeRequestAction('CREATE_PACKAGE', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const editPackageAction = makeRequestAction('EDIT_PACKAGE', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const reorderPackagesAction = makeRequestAction('REORDER_PACKAGES', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const duplicatePackageAction = makeRequestAction('DUPLICATE_PACKAGE', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const movePackageAction = makeRequestAction('MOVE_PACKAGE', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const deletePackageAction = makeRequestAction('[V_2] DELETE_PACKAGE', {
  onSuccess(params, response) {
    history.push(`/folder/${params.folderId}`);
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const getPackageAction = makeRequestAction('GET_PACKAGE', {
  onSuccess(params, response) {
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const createLessonAction = makeRequestAction('CREATE_LESSON', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const createQuizAction = makeRequestAction('CREATE_QUIZ', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const editLessonAction = makeRequestAction('EDIT_LESSON', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const editQuizAction = makeRequestAction('EDIT_QUIZ', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const reorderLessonsAction = makeRequestAction('REORDER_LESSONS', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const reorderQuizzesAction = makeRequestAction('REORDER_QUIZZES', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const duplicateLessonAction = makeRequestAction('DUPLICATE_LESSON', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const duplicateQuizAction = makeRequestAction('DUPLICATE_QUIZ', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const moveLessonAction = makeRequestAction('MOVE_LESSON', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const moveQuizAction = makeRequestAction('MOVE_QUIZ', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const deleteLessonAction = makeRequestAction('DELETE_LESSON', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});

export const deleteQuizAction = makeRequestAction('DELETE_QUIZ', {
  onSuccess(params, response) {
    success({ message: response?.message });
    return response;
  },
  onFailure(params, error) {
    errorAlert({ message: error?.error?.message });
    return error;
  },
});
