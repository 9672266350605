export const token = () => localStorage.getItem('user');
const LOCAL_API_ORIGN = 'http://localhost:8000';
///////////////////////////////////////////////

let REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
let REACT_APP_API_ORIGIN = process.env.REACT_APP_API_ORIGIN;
let REACT_APP_PLAYER_APP_URL = process.env.REACT_APP_PLAYER_APP_URL;
let REACT_APP_LANDING_PAGE = process.env.REACT_APP_LANDING_PAGE;

// Override environment for staging environment since there is only one build step in the P1 pipeline
if (process.env.REACT_APP_ENV === 'platformone' && window) {
  if (window.location?.href?.includes('learn-to-win.staging.dso.mil')) {
    REACT_APP_BASE_URL = 'https://learn-to-win.staging.dso.mil/api/coach';
    REACT_APP_API_ORIGIN = 'https://learn-to-win.staging.dso.mil';
    REACT_APP_PLAYER_APP_URL = 'https://learn-to-win.staging.dso.mil/student-web-app/';
    REACT_APP_LANDING_PAGE = 'https://learn-to-win.staging.dso.mil/instructor-web-app/';
  } else if (window.location?.href?.includes('learn-to-win-il4.apps.dso.mil')) {
    REACT_APP_BASE_URL = 'https://learn-to-win-il4.apps.dso.mil/api/coach';
    REACT_APP_API_ORIGIN = 'https://learn-to-win-il4.apps.dso.mil';
    REACT_APP_PLAYER_APP_URL = 'https://learn-to-win-il4.apps.dso.mil/student-web-app/';
    REACT_APP_LANDING_PAGE = 'https://learn-to-win-il4.apps.dso.mil/instructor-web-app/';
  }
}

export const athenaRedirectUrl = process.env.REACT_APP_ATHENA_REDIRECT_URL;
export const url = REACT_APP_BASE_URL || '';
export const apiOrigin = REACT_APP_API_ORIGIN || LOCAL_API_ORIGN;
export const AppUrl = REACT_APP_PLAYER_APP_URL;
export const ROUTE_BASE_NAME = process.env.REACT_APP_ROUTE_BASE_NAME || '';
export const toolUrlMac = process.env.REACT_APP_TOOL_URL_MAC;
export const toolUrlWin = process.env.REACT_APP_TOOL_URL_WIN;
export const env = process.env.REACT_APP_ENV;
export const sysOutage = process.env.REACT_APP_SYS_OUTAGE === 'true';
export const isOnPremise = env === 'onpremise';
export const isOnDoDNetwork = env === 'platformone';
export const isOnProduction = env === 'production';
export const isOnStaging = env === 'staging';
export const isOnDevelopment = env === 'development';
export const S3_HOST = process.env.REACT_APP_S3_HOST;
export const GTM_ID = 'GTM-WPWDWH3';
export const GA_TRACKING_ID = 'UA-162646015-1';
export const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
export const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_VALIDATION_MESSAGE =
  'Passwords must be at least 8 characters long and contain: one upper case character [A-Z], one lower case character [a-z], and one number [0-9]';
export const LANDING_PAGE_ORIGIN = REACT_APP_LANDING_PAGE;
export const KC_AUTH_SERVER_URL = process.env.REACT_APP_AUTH_SERVER_URL;
export const DOD_SUPPORT_EMAIL = 'dod_support@learntowin.zendesk.com';
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const CURR_COMMIT_ID = process.env.REACT_APP_COMMIT_ID;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_REDIRECT_URI = process.env.REACT_APP_AUTH0_REDIRECT_URI;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
export const AUTH0_SCOPE = process.env.REACT_APP_AUTH0_SCOPE;
export const HEAP_APP_KEY = process.env.REACT_APP_HEAP_APP_ID;
export const PENDO_API_KEY = process.env.REACT_APP_PENDO_API_KEY;
export const TRACKING_LAST_ACTIVE_SINCE_DATE =
  process.env.REACT_APP_TRACKING_LAST_ACTIVE_SINCE_DATE || '12/5/2022';

// Help Center Links
export const HELP_DESK_ROOT =
  process.env.REACT_APP_HELP_DESK_ROOT || 'https://learntowin.zendesk.com/hc/en-us';
export const HELP_DESK_NEW_SUPPORT_TICKET =
  process.env.REACT_APP_NEW_SUPPORT_TICKET ||
  'https://learntowin.zendesk.com/hc/en-us/requests/new';
export const HELP_DESK_DRAWING_RESPONSE =
  process.env.REACT_APP_HELP_DESK_DRAWING_RESPONSE || '4402921335067-Drawing-Response-Tool';
export const HELP_DESK_FREE_RESPONSE =
  process.env.REACT_APP_HELP_DESK_FREE_RESPONSE || '4402921320731-Free-Response-Upload-Template';
export const HELP_DESK_MEDIA_UPLOAD =
  process.env.REACT_APP_HELP_DESK_MEDIA_UPLOAD || '4402921355931-Embed-Functionality';
export const HELP_DESK_EDITING_LESSONS_AND_QUIZZES =
  process.env.REACT_APP_HELP_DESK_EDITING_LESSONS_AND_QUIZZES ||
  '4402921286299-Editing-Lessons-and-Quizzes';
export const HELP_DESK_RAPID_GRADING =
  process.env.REACT_APP_HELP_DESK_RAPID_GRADING || '4402923970843#h_01FCNV9QAFKXH6QXJ6GE2NMRA9';
export const HELP_DESK_CUSTOM_FEEDBACK =
  process.env.REACT_APP_HELP_DESK_CUSTOM_FEEDBACK || '4402923970843#h_01FCNV9YF67VM3Q4BNME0TCYAK';
export const HELP_DESK_VISDUE_WINDOWS =
  process.env.REACT_APP_HELP_DESK_VISDUE_WINDOWS || '4410067774747';
export const HELP_DESK_BULK_PUBLISHING =
  process.env.REACT_APP_HELP_DESK_BULK_PUBLISHING || '7505935747867-Bulk-Publishing';

// Feature Flags

export const isCertificatesEnabled = process.env.REACT_APP_IS_CERTIFICATES_ENABLED === 'true';
export const isOnNewLayout = process.env.REACT_APP_IS_ON_NEW_LAYOUT === 'true';
export const isPresentationEnabled = process.env.REACT_APP_IS_PRESENTATION_ENABLED === 'true';
export const isPendoEnabled = process.env.REACT_APP_IS_PENDO_ENABLED === 'true';
export const isHeapEnabled = process.env.REACT_APP_IS_HEAP_ENABLED === 'true';
export const isPollingEnabled = process.env.REACT_APP_IS_POLLING_ENABLED === 'true';
export const sunsetLeaderboard = process.env.REACT_APP_FULLY_SUNSET_LEADERBOARD === 'true';
export const getAuthScheme = () => localStorage.getItem('auth_scheme') || 'default';
export function trySetClientAuthScheme(scheme) {
  const validSchemes = ['sso'];

  // Verify scheme otherwise leave as previous value.
  if (scheme && validSchemes.includes(scheme)) {
    localStorage.setItem('auth_scheme', scheme);

    return true;
  }

  return false;
}

//  statuspage variables
export const STATUSPAGE_AUTH_TOKEN = process.env.REACT_APP_STATUSPAGE_AUTH_TOKEN;
export const STATUSPAGE_PAGE_ID = process.env.REACT_APP_STATUSPAGE_PAGE_ID;

// eslint-disable-next-line no-constant-condition
export const redirectPage = { token } ? window.location.pathname : '';
