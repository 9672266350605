import { call, put, select } from 'redux-saga/effects';
import {
  getFolderGroupsAction,
  createFolderGroupAction,
  editFolderGroupTitleAction,
  reorderFolderGroupsAction,
  deleteFolderGroupAction,
  createFolderAction,
  editFolderAction,
  deleteFolderAction,
  moveFolderAction,
  duplicateFolderAction,
  reorderFoldersAction,
  getPackagesAction,
  createPackageAction,
  duplicatePackageAction,
  movePackageAction,
  deletePackageAction,
  getPackageAction,
  editPackageAction,
  createLessonAction,
  createQuizAction,
  editLessonAction,
  editQuizAction,
  duplicateLessonAction,
  duplicateQuizAction,
  moveLessonAction,
  moveQuizAction,
  deleteLessonAction,
  deleteQuizAction,
  bulkDeleteFolderAction,
  reorderPackagesAction,
  reorderLessonsAction,
  reorderQuizzesAction,
} from '../../../actions/V2/packages.actions';
import {
  getFolderGroupsApi,
  createFolderGroupApi,
  editFolderGroupTitleApi,
  reorderFolderGroupsApi,
  deleteFolderGroupApi,
  createFolderApi,
  editFolderApi,
  deleteFolderApi,
  moveFolderApi,
  duplicateFolderApi,
  reorderFoldersApi,
  getPackagesApi,
  createPackageApi,
  duplicatePackageApi,
  movePackageApi,
  deletePackageApi,
  getPackageApi,
  editPackageApi,
  reorderPackagesApi,
  createLessonApi,
  createQuizApi,
  editLessonApi,
  editQuizApi,
  reorderLessonsApi,
  reorderQuizzesApi,
  duplicateLessonApi,
  duplicateQuizApi,
  moveLessonApi,
  moveQuizApi,
  deleteLessonApi,
  deleteQuizApi,
  bulkDeleteFoldersApi,
} from '../../../services/V2/packages.service';

import {
  editFolderGroupTitleError,
  reorderFolderGroupError,
  deleteFolderGroupError,
  editFolderError,
  moveFolderError,
  reorderFolderError,
  deleteFolderError,
  movePackageError,
  deletePackageError,
  editLessonError,
  moveLessonError,
  moveQuizError,
  deleteLessonError,
  deleteQuizError,
  bulkDeleteFolderError,
  reorderLessonsError,
  reorderQuizzesError,
  reorderPackagesError,
} from '../../../selectors/V2/packages.selectors';

export const watchGetFolderGroups = {
  ...getFolderGroupsAction,
  api: getFolderGroupsApi,
};

export const watchCreateFolderGroup = {
  ...createFolderGroupAction,
  api: createFolderGroupApi,
  *load(fetch, action) {
    yield call(fetch, action);
    yield put(getFolderGroupsAction.request());
  },
};

export const watchEditFolderGroupTitle = {
  ...editFolderGroupTitleAction,
  api: editFolderGroupTitleApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(editFolderGroupTitleError);
    if (err) {
      yield put(getFolderGroupsAction.request());
    }
  },
};

export const watchReorderFolderGroups = {
  ...reorderFolderGroupsAction,
  api: reorderFolderGroupsApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(reorderFolderGroupError);
    if (err) {
      yield put(getFolderGroupsAction.request());
    }
  },
};

export const watchDeleteFolderGroup = {
  ...deleteFolderGroupAction,
  api: deleteFolderGroupApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(deleteFolderGroupError);
    if (err) {
      yield put(getFolderGroupsAction.request());
    }
  },
};

export const watchCreateFolder = {
  ...createFolderAction,
  api: createFolderApi,
  *load(fetch, action) {
    yield call(fetch, action);
    yield put(getFolderGroupsAction.request());
  },
};

export const watchEditFolder = {
  ...editFolderAction,
  api: editFolderApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(editFolderError);
    if (err && !action?.params?.isInFolder) {
      yield put(getFolderGroupsAction.request());
    }
  },
};

export const watchMoveFolder = {
  ...moveFolderAction,
  api: moveFolderApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(moveFolderError);
    if (err) {
      yield put(getFolderGroupsAction.request());
    }
  },
};

export const watchDuplicateFolder = {
  ...duplicateFolderAction,
  api: duplicateFolderApi,
  *load(fetch, action) {
    yield call(fetch, action);
    yield put(getFolderGroupsAction.request());
  },
};

export const watchReorderFolder = {
  ...reorderFoldersAction,
  api: reorderFoldersApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(reorderFolderError);
    if (err) {
      yield put(getFolderGroupsAction.request());
    }
  },
};

export const watchDeleteFolder = {
  ...deleteFolderAction,
  api: deleteFolderApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(deleteFolderError);
    if (err) {
      yield put(getFolderGroupsAction.request());
    }
  },
};

export const watchBulkDeleteFolder = {
  ...bulkDeleteFolderAction,
  api: bulkDeleteFoldersApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(bulkDeleteFolderError);
    if (err) {
      yield put(getFolderGroupsAction.request());
    }
  },
};

export const watchGetPackages = {
  ...getPackagesAction,
  api: getPackagesApi,
};

export const watchCreatePackage = {
  ...createPackageAction,
  api: createPackageApi,
  *load(fetch, action) {
    yield call(fetch, action);
    if (action.params.isInFolder) {
      yield put(getPackagesAction.request({ id: action.params.folderId }));
    } else {
      yield put(getFolderGroupsAction.request());
    }
  },
};

export const watchEditPackage = {
  ...editPackageAction,
  api: editPackageApi,
  *load(fetch, action) {
    yield call(fetch, action);
    yield put(getPackageAction.request({ id: action.params.id }));
  },
};

export const watchReorderPackages = {
  ...reorderPackagesAction,
  api: reorderPackagesApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(reorderPackagesError);
    if (err) {
      yield put(getPackagesAction.request({ id: action.params.folderId }));
    }
  },
};

export const watchDuplicatePackage = {
  ...duplicatePackageAction,
  api: duplicatePackageApi,
  *load(fetch, action) {
    yield call(fetch, action);
    if (action.params.folderId) {
      yield put(getPackagesAction.request({ id: action.params.folderId }));
    }
  },
};

export const watchMovePackage = {
  ...movePackageAction,
  api: movePackageApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(movePackageError);
    if (err) {
      yield put(getPackagesAction.request({ id: action.params.folderId }));
    }
  },
};

export const watchDeletePackageV2 = {
  ...deletePackageAction,
  api: deletePackageApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(deletePackageError);
    if (err) {
      yield put(getPackagesAction.request({ id: action.params.folderId }));
    }
  },
};

export const watchGetPackage = {
  ...getPackageAction,
  api: getPackageApi,
};

export const watchCreateLesson = {
  ...createLessonAction,
  api: createLessonApi,
  *load(fetch, action) {
    yield call(fetch, action);
    yield put(getPackageAction.request({ id: action.params.package_id }));
  },
};

export const watchCreateQuiz = {
  ...createQuizAction,
  api: createQuizApi,
  *load(fetch, action) {
    yield call(fetch, action);
    yield put(getPackageAction.request({ id: action.params.package_id }));
  },
};

export const watchEditLesson = {
  ...editLessonAction,
  api: editLessonApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(editLessonError);
    if (err) {
      yield put(getPackageAction.request({ id: action.params.package_id }));
    }
  },
};

export const watchEditQuiz = {
  ...editQuizAction,
  api: editQuizApi,
  *load(fetch, action) {
    yield call(fetch, action);
    yield put(getPackageAction.request({ id: action.params.package_id }));
  },
};

export const watchReorderLessons = {
  ...reorderLessonsAction,
  api: reorderLessonsApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(reorderLessonsError);
    if (err) {
      yield put(getPackageAction.request({ id: action.params.package_id }));
    }
  },
};

export const watchReorderQuizzes = {
  ...reorderQuizzesAction,
  api: reorderQuizzesApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(reorderQuizzesError);
    if (err) {
      yield put(getPackageAction.request({ id: action.params.package_id }));
    }
  },
};

export const watchDuplicateLesson = {
  ...duplicateLessonAction,
  api: duplicateLessonApi,
  *load(fetch, action) {
    yield call(fetch, action);
    yield put(getPackageAction.request({ id: action.params.package_id }));
  },
};

export const watchDuplicateQuiz = {
  ...duplicateQuizAction,
  api: duplicateQuizApi,
  *load(fetch, action) {
    yield call(fetch, action);
    yield put(getPackageAction.request({ id: action.params.package_id }));
  },
};

export const watchMoveLessonV2 = {
  ...moveLessonAction,
  api: moveLessonApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(moveLessonError);
    if (err) {
      yield put(getPackageAction.request({ id: action.params.rollbackId }));
    }
  },
};

export const watchMoveQuizV2 = {
  ...moveQuizAction,
  api: moveQuizApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(moveQuizError);
    if (err) {
      yield put(getPackageAction.request({ id: action.params.rollbackId }));
    }
  },
};

export const watchDeleteLessonV2 = {
  ...deleteLessonAction,
  api: deleteLessonApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(deleteLessonError);
    if (err) {
      yield put(getPackageAction.request({ id: action.params.packageId }));
    }
  },
};

export const watchDeleteQuizV2 = {
  ...deleteQuizAction,
  api: deleteQuizApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(deleteQuizError);
    if (err) {
      yield put(getPackageAction.request({ id: action.params.packageId }));
    }
  },
};
