import { createSelector } from 'reselect';
import {
  getFolderGroupsAction,
  editFolderGroupTitleAction,
  reorderFolderGroupsAction,
  deleteFolderGroupAction,
  editFolderAction,
  deleteFolderAction,
  moveFolderAction,
  reorderFoldersAction,
  getPackagesAction,
  movePackageAction,
  deletePackageAction,
  getPackageAction,
  moveLessonAction,
  moveQuizAction,
  deleteLessonAction,
  deleteQuizAction,
  bulkDeleteFolderAction,
  editLessonAction,
  editQuizAction,
  reorderLessonsAction,
  reorderQuizzesAction,
  reorderPackagesAction,
} from '../../../actions/V2/packages.actions';
import createErrorSelector from '../../create-error-selector';
import createLoadingSelector from '../../create-loading-selector';

export const getFolderGroupsLoading = createLoadingSelector(getFolderGroupsAction.actionName);
export const getPackagesLoading = createLoadingSelector(getPackagesAction.actionName);
export const getPackageLoading = createLoadingSelector(getPackageAction.actionName);
export const deletePackageLoading = createLoadingSelector(deletePackageAction.actionName);
export const editFolderGroupTitleError = createErrorSelector(editFolderGroupTitleAction.actionName);
export const reorderFolderGroupError = createErrorSelector(reorderFolderGroupsAction.actionName);
export const deleteFolderGroupError = createErrorSelector(deleteFolderGroupAction.actionName);
export const editFolderError = createErrorSelector(editFolderAction.actionName);
export const moveFolderError = createErrorSelector(moveFolderAction.actionName);
export const reorderFolderError = createErrorSelector(reorderFoldersAction.actionName);
export const deleteFolderError = createErrorSelector(deleteFolderAction.actionName);
export const bulkDeleteFolderError = createErrorSelector(bulkDeleteFolderAction.actionName);
export const movePackageError = createErrorSelector(movePackageAction.actionName);
export const reorderPackagesError = createErrorSelector(reorderPackagesAction.actionName);
export const deletePackageError = createErrorSelector(deletePackageAction.actionName);
export const editLessonError = createErrorSelector(editLessonAction.actionName);
export const editQuizError = createErrorSelector(editQuizAction.actionName);
export const reorderLessonsError = createErrorSelector(reorderLessonsAction.actionName);
export const reorderQuizzesError = createErrorSelector(reorderQuizzesAction.actionName);
export const moveLessonError = createErrorSelector(moveLessonAction.actionName);
export const moveQuizError = createErrorSelector(moveQuizAction.actionName);
export const deleteLessonError = createErrorSelector(deleteLessonAction.actionName);
export const deleteQuizError = createErrorSelector(deleteQuizAction.actionName);

export const getPackagesState = state => state.packagesState;
export const packagesSelector = createSelector(getPackagesState, state => state.packages);
export const folderGroupsSelector = createSelector(getPackagesState, state => state.folderGroups);
export const packageSelector = createSelector(getPackagesState, state => state.package);
