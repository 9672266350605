import cc from 'classcat';
import PropTypes from 'prop-types';
import { useRipple } from '../hooks';
import styles from './styles.module.css';

const Button = ({
  children,
  bg,
  color,
  disabled,
  className,
  outline,
  block,
  type,
  icon,
  onClick,
  size,
  inline,
  right,
  ...props
}) => {
  const { Ripple, events } = useRipple({ onClick });

  return (
    <button
      className={cc([
        styles.button,
        bg,
        color,
        className,
        {
          [styles.block]: block,
          [styles.default]: type === 'default' && !bg,
          [styles.outline]: type === 'outline',
          [styles.secondary]: type === 'secondary',
          [styles.dashed]: type === 'dashed',
          [styles.icon]: type === 'icon',
          [styles.text]: type === 'text',
          /* Sizes */
          [styles.sm]: size === 'sm',
          /* Inline */
          [styles.inline]: inline,
        },
      ])}
      disabled={disabled}
      {...props}
      {...events}>
      <span
        className={cc([
          styles.button__icon,
          {
            [styles.icon__sm]: size === 'sm',
          },
        ])}>
        {icon}
      </span>
      <span className={styles.flex}>{children}</span>
      {right && (
        <div
          className={cc({
            [styles.right]: right,
          })}>
          {right}
        </div>
      )}
      {Ripple}
    </button>
  );
};

Button.defaultProps = {
  type: 'default',
  children: '',
  inline: false,
};

Button.propTypes = {
  type: PropTypes.oneOf(['default', 'outline', 'secondary', 'dashed', 'icon', 'text', '']),
  color: PropTypes.string,
  bg: PropTypes.string,
  block: PropTypes.bool,
  icon: PropTypes.node,
  children: PropTypes.node,
  size: PropTypes.oneOf(['sm']),
  inline: PropTypes.bool,
  right: PropTypes.node,
};

export default Button;
