import produce from 'immer';
import {
  getContentFoldersAction,
  getContentFolderByIdAction,
  createContentFolderAction,
  updateContentFolderAction,
  getContentFoldersTreeAction,
} from '../../../actions/V2/content-bank.actions';

export const defaultState = {
  contentFolders: [],
  currentFolder: null,
  foldersTree: [],
};

const contentBankState = produce((state, action) => {
  switch (action.type) {
    case getContentFoldersAction.requestTypes.SUCCESS:
      state.contentFolders = action.data.folders;
      state.currentFolder = null;
      break;
    case getContentFolderByIdAction.requestTypes.SUCCESS:
      state.currentFolder = action.data.folder;
      break;
    case getContentFoldersTreeAction.requestTypes.SUCCESS:
      state.foldersTree = action.data.folders;
      break;
    case createContentFolderAction.requestTypes.SUCCESS:
      const createdFolder = {
        groups: [],
        files: [],
        child_folders: [],
        files_count: 0,
        child_folders_count: 0,
        ...action.data.createdFolder,
      };
      if (state.currentFolder && state.currentFolder.id === action.params.parent_folder_id) {
        state.currentFolder.child_folders = [createdFolder, ...state.currentFolder.child_folders];
      } else if (!action.params.parent_folder_id) {
        state.contentFolders = [createdFolder, ...state.contentFolders];
      }
      break;
    case updateContentFolderAction.requestTypes.SUCCESS:
      if (state.currentFolder) {
        if (action.params.parent_folder_id === state.currentFolder.id) {
          const folderIdx = state.currentFolder.child_folders.findIndex(
            f => f.id === action.params.id,
          );
          state.currentFolder.child_folders.splice(folderIdx, 1, {
            ...state.currentFolder.child_folders[folderIdx],
            ...action.params,
          });
        } else {
          state.currentFolder = {
            ...state.currentFolder,
            ...action.params,
          };
        }
      } else {
        const folderIdx = state.contentFolders.findIndex(f => f.id === action.params.id);
        state.contentFolders.splice(folderIdx, 1, {
          ...state.contentFolders[folderIdx],
          ...action.params,
        });
      }
      break;
    default:
      break;
  }
}, defaultState);

export default contentBankState;
