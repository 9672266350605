import {
  MIGRATION_STATUS_ERROR_MSG,
  REDIRECT_TO_ATHENA_ERROR_MSG,
} from '../constants/errorMessages';
import history from '../history';

export const responseChecker = err => {
  if (err?.status === 404 || err?.status === 403) {
    history.replace('/page-not-found');
  }
};

export const checkMigrationStatus = res => {
  if (res?.status === 403) {
    if (res?.data?.message === REDIRECT_TO_ATHENA_ERROR_MSG) {
      history.replace('/redirect-to-athena');
    } else if (res?.data?.message === MIGRATION_STATUS_ERROR_MSG) {
      history.replace('/migration-in-progress');
    }
  }
};

export function convertFileToImage(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (!file) {
      reject();
    }
    reader.onload = e => resolve(e.target.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}
