import { createSelector } from 'reselect';
import {
  addContentFileAction,
  addPdfPptContentAction,
  createContentFolderAction,
  deleteContentFilesAction,
  deleteContentFolderAction,
  getContentFolderByIdAction,
  getContentFoldersAction,
  getContentFoldersTreeAction,
  moveContentFilesAction,
  moveContentFolderAction,
  updateContentFileAction,
  updateContentFolderAction,
} from '../../../actions/V2/content-bank.actions';
import createErrorSelector from '../../create-error-selector';
import createLoadingSelector from '../../create-loading-selector';

export const getContentFoldersLoading = createLoadingSelector(getContentFoldersAction.actionName);
export const getContentFoldersError = createErrorSelector(getContentFoldersAction.actionName);
export const getContentFolderByIdLoading = createLoadingSelector(
  getContentFolderByIdAction.actionName,
);
export const createContentFolderLoading = createLoadingSelector(
  createContentFolderAction.actionName,
);
export const updateContentFolderLoading = createLoadingSelector(
  updateContentFolderAction.actionName,
);
export const updateContentFolderError = createErrorSelector(updateContentFolderAction.actionName);

export const contentBankIsLoading = createSelector(
  getContentFoldersLoading,
  getContentFolderByIdLoading,
  (a, b) => a || b,
);

export const addContentLoading = createLoadingSelector(addContentFileAction.actionName);
export const addPresentationLoading = createLoadingSelector(addPdfPptContentAction.actionName);

export const addContentError = createErrorSelector(addContentFileAction.actionName);
export const addPresentationError = createErrorSelector(addPdfPptContentAction.actionName);

export const getFoldersTreeLoading = createLoadingSelector(getContentFoldersTreeAction.actionName);
export const moveContentFolderLoading = createLoadingSelector(moveContentFolderAction.actionName);
export const deleteFolderLoading = createLoadingSelector(deleteContentFolderAction.actionName);
export const deleteFileLoading = createLoadingSelector(deleteContentFilesAction.actionName);
export const updateFileLoading = createLoadingSelector(updateContentFileAction.actionName);
export const moveFilesLoading = createLoadingSelector(moveContentFilesAction.actionName);

export const contentBankState = globalState => globalState.contentBankState;

export const contentFoldersTreeSelector = createSelector(
  contentBankState,
  state => state.foldersTree,
);

export const contentFoldersSelector = createSelector(
  contentBankState,
  state => state.contentFolders,
);

export const currentContentFolderSelector = createSelector(
  contentBankState,
  state => state.currentFolder,
);

export const currentFolderChildrenSelector = createSelector(
  currentContentFolderSelector,
  f => f?.child_folders,
);

export const currentScreenFoldersSelector = createSelector(
  currentFolderChildrenSelector,
  contentFoldersSelector,
  (cf, f) => cf || f,
);
