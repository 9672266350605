import produce from 'immer';
import {
  getAlbumsAction,
  getUserGroupsAction,
  getAllMediaAction,
} from '../../actions/contentBank.actions';

export const defaultState = {
  albums: [],
  collections: [],
  media: [],
  groups: [],
  message: '',
  currentCollection: null,
  currentAlbum: null,
};

const contentBank = produce((state, action) => {
  switch (action.type) {
    case getAlbumsAction.requestTypes.SUCCESS: {
      state.albums = action.folders || [];
      state.collections = action.categories || [];
      state.code = action.code;
      break;
    }
    case getUserGroupsAction.requestTypes.SUCCESS: {
      state.groups = action.groups || [];
      break;
    }
    case getAllMediaAction.requestTypes.SUCCESS: {
      state.media = action.media || [];
      state.category_id = action.cat_id || [];
      break;
    }
  }
}, defaultState);

export default contentBank;
