import { localStorageKeys, nonRemovableLocalStorageKeys } from '../constants/localStorageKeys';
import { isLoggedInVar } from '../api/graphql';

export const setAuthorizationData = params => {
  const { user } = params || {};

  localStorage.setItem(localStorageKeys.user, params?.access_token);
  localStorage.setItem(localStorageKeys.userId, user?.id);
  localStorage.setItem(localStorageKeys.userName, user?.name);
  localStorage.setItem(localStorageKeys.userEmail, user?.email);
  localStorage.setItem(localStorageKeys.schoolName, user?.school?.name);
  localStorage.setItem(localStorageKeys.isContentWriter, user?.type === 'admin');
  localStorage.setItem(localStorageKeys.contentWriterCoachId, user?.parent?.id);
  localStorage.setItem(localStorageKeys.teamAbbreviation, user?.school?.team_abbreviation);

  // Note: Update token refresh logic to be explicit
  window.location.reload();
};

// Note: This fetches the token
export const getAuthToken = () => localStorage.getItem(localStorageKeys.user);

export const clearLocalStorage = () => {
  const nonRemovableKeys = Object.values(nonRemovableLocalStorageKeys);
  Object.keys(localStorage).forEach(key => {
    if (!nonRemovableKeys.includes(key)) {
      localStorage.removeItem(key);
    }
  });

  isLoggedInVar(false);
};

export const trySetAccessToken = token => {
  if (!token) {
    return false;
  }

  try {
    localStorage.setItem(localStorageKeys.user, token);
    return true;
  } catch (e) {
    console.log('Error: Unable to set access token:', e.message);
    return false;
  }
};
