import { Spin, Icon } from 'antd';
import cc from 'classcat';
import './styles.less';

const indicator = <Icon spin type='loading' style={{ color: '#EA2139' }} />;
const Spinner = ({ children, isLocal, spinning, className = '', ...props }) => {
  return (
    <Spin
      spinning={!!spinning}
      wrapperClassName={cc(['l2w-spinner', { 'full-height': !isLocal }, className])}
      indicator={indicator}
      size='large'
      {...props}>
      {children}
    </Spin>
  );
};

export default Spinner;
