import produce from 'immer';

import { getSignedStorageURLAction } from '../../actions/utility.actions';

export const defaultState = {
  signedURL: '',
};

const utility = produce((state, action) => {
  switch (action.type) {
    case getSignedStorageURLAction.requestTypes.SUCCESS:
      state.signedURL = action.signedURL;
      break;
  }
}, defaultState);

export default utility;
