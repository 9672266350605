import { createClient } from 'react-fetching-library';
import axios from 'axios';

import { buildAxiosFetch } from './buildAxiosFetch';
import { getAuthScheme, url, token } from '../Environment';
import { responseChecker } from '../utils';
import { checkMigrationStatus } from '../utils/index';

export function getSourceByUrl() {
  const currentLocation = window.location.href;
  switch (true) {
    case currentLocation.includes('/lesson'):
    case currentLocation.includes('/quiz'):
      return 'WORKSPACE';
    case currentLocation.includes('/content-bank'):
      return 'CONTENT_BANK';
    case currentLocation.includes('/package'):
    case currentLocation.includes('/folder'):
      return 'TEACHING_PACKAGES';
    case currentLocation.includes('/profile'):
      return 'PROFILE';
    case currentLocation.includes('/user-groups'):
      return 'GROUP_MANAGEMENT';
    case currentLocation.includes('/users'):
    case currentLocation.includes('/edit-user'):
    case currentLocation.includes('/invite-users'):
      return 'USER_MANAGEMENT';
    default:
      return null;
  }
}

const requestHostInterceptor = host => () => async action => {
  return {
    ...action,
    endpoint: `${host}${action.endpoint}`,
    headers: {
      ...action.headers,
      Authorization: token() ? `Bearer ${token()}` : null,
      'Source-Flow': getSourceByUrl(),
      Accept: 'application/x.l2w.v1+json',
      'Cache-Control': 'no-store, no-cache, must-revalidate',
      Pragma: 'no-cache',
      'L2W-Client-Auth-Scheme': getAuthScheme(),
      'L2W-Client-User-Type': 'instructor',
    },
  };
};

const responseInterceptor = client => async (action, response) => {
  checkMigrationStatus(response);
  responseChecker(response);
  if (response.error) {
    return {
      ...response,
      payload: response.payload.message,
    };
  }
  return {
    ...response,
    payload: response.payload.data || response.payload,
  };
};

const axiosInstance = axios.create({
  timeout: 30000,
});

const createCache = (isCacheable, isValid) => {
  const convertActionToBase64 = action => {
    return JSON.stringify({ ...action, signal: {} });
  };
  let items = {};

  const add = (action, value) => {
    if (isCacheable(action)) {
      items[convertActionToBase64(action)] = { ...value, timestamp: Date.now() };
    }
  };

  const remove = action => {
    delete items[convertActionToBase64(action)];
  };

  const get = action => {
    const response = items[convertActionToBase64(action)];
    const valid = response && isValid(response);

    if (valid) {
      return response;
    }

    if (response && !valid) {
      remove(action);
    }
  };

  const setItems = value => {
    items = value;
  };

  const getItems = () => {
    return items;
  };

  return {
    add,
    get,
    getItems,
    remove,
    setItems,
  };
};

const cache = createCache(
  action => {
    return action.method === 'GET' && !action.disableCache;
  },
  response => {
    return true;
  },
);

export default createClient({
  requestInterceptors: [requestHostInterceptor(url)],
  responseInterceptors: [responseInterceptor],
  fetch: buildAxiosFetch(axiosInstance),
  cacheProvider: cache,
});
