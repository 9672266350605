import client from '../client';
import { url } from '../../Environment';

export const getProfileApi = ({ params }, cancelToken) =>
  client.get('/get-profile', { cancelToken });

export const initialLoginApi = ({ params }, cancelToken) => {
  const baseUrl = url?.replace('coach', 'shared') || ''; // This is a one-off to use shared endpoints. Refactor when cleaning up request logic.
  return client.post(`${baseUrl}/initial-login`, params, { cancelToken });
};

export const updateProfileApi = ({ params }, cancelToken) =>
  client.post('/update-profile', params, { cancelToken });

export const passwordCheckApi = ({ params }, cancelToken) =>
  client.post('/check-password', params, { cancelToken });
