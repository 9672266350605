import { produce } from 'immer';
import isEqual from 'lodash/isEqual';
import { getIncidentsListAction, getMaintenanceListAction } from '../../actions/app.actions';

export const initialState = {
  incidents: [],
  maintenance: [],
};

const app = produce((state, action) => {
  switch (action.type) {
    case getIncidentsListAction.requestTypes.SUCCESS: {
      if (!isEqual(action.incidents, state.incidents)) {
        state.incidents = action.incidents;
      }
      break;
    }
    case getMaintenanceListAction.requestTypes.SUCCESS: {
      if (!isEqual(action.maintenance, state.maintenance)) {
        state.maintenance = action.maintenance;
      }
      break;
    }
  }
}, initialState);

export default app;
