import { Auth0Provider } from '@auth0/auth0-react';

import {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_REDIRECT_URI,
  AUTH0_AUDIENCE,
  AUTH0_SCOPE,
} from '../Environment';
import history from '../history';

export const onRedirectCallback = appState => {
  history.push(appState?.returnTo || window.location.pathname);

  return appState?.returnTo || window.location.pathname;
};

const Auth0ProviderWithHistory = ({ children }) => {
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window?.location?.origin || AUTH0_REDIRECT_URI}
      audience={AUTH0_AUDIENCE}
      scope={AUTH0_SCOPE}
      onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
