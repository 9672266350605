import { makeRequestAction } from './action-generator';

export const getCourses = makeRequestAction('GET_COURSES', {
  onSuccess(params, response) {
    return {
      response: {
        entities: {
          courses:
            Object.prototype.hasOwnProperty.call(params, 'searchText') || params.offset === 0
              ? response.rows
              : [...response.rows, 'mergeWithOld'],
          coursesTotal: response.total,
        },
      },
    };
  },
});
