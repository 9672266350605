import { makeRequestAction } from './action-generator';

export const getSignedStorageURLAction = makeRequestAction('GET_SIGNED_STORAGE_URL', {
  onSuccess: (params, response) => {
    if (params.onFinish) {
      params.onFinish(response.data.result);
    }
    return {
      signedURLs: response.data.result,
    };
  },
});

export const getSignedStorageURLBulkAction = makeRequestAction('GET_SIGNED_STORAGE_URL_BULK', {
  onSuccess: (params, response) => {
    if (params.onFinish) {
      params.onFinish(response.data.result);
    }
    return {
      signedURLs: response.data.result,
    };
  },
});
