import cc from 'classcat';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Title = ({ level, weight, className, color, italic, children, ...props }) => {
  const Element = `h${level}`;

  return (
    <Element
      style={{ fontWeight: weight }}
      className={cc([
        styles.title,
        styles[`level${level}`],
        className,
        color === 'currentColor' ? styles.currentColor : color,
        {
          [styles.italic]: italic,
        },
      ])}
      {...props}>
      {children}
    </Element>
  );
};

Title.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
  color: PropTypes.oneOf([
    'blue',
    'currentColor',
    'dark-blue-1',
    'dark-blue-2',
    'dark-purple',
    'gray-1',
    'gray-2',
    'green',
    'light-blue-1',
    'light-blue-2',
    'light-green',
    'light-red',
    'light-yellow',
    'lime-green',
    'peach',
    'pink',
    'pure-yellow',
    'purple',
    'red',
    'teal',
    'white',
    'yellow',
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  italic: PropTypes.bool,
};

Title.defaultProps = {
  color: 'dark-blue-1',
  level: 1,
  italic: false,
};

export default Title;
