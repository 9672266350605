import moment from 'moment';

export const getAppliedFilter = (type, values) => {
  if (!values) {
    return null;
  }
  switch (type) {
    case 'list': {
      return values?.length || 0;
    }
    case 'percent': {
      if (values[0] === 0 && values[1] === 100) {
        return null;
      }

      if (values[0] === values[1]) {
        return `${values[0]}%`;
      }

      return `${values[0]} - ${values[1]} (%)`;
    }
    case 'date': {
      if (!values[0] && !values[1]) {
        return null;
      }

      if (values[0] === values[1]) {
        return `${values[0]}`;
      }

      return `${values[0]} - ${values[1]}`;
    }
    default: {
      return false;
    }
  }
};

export const getFiltersCount = (filtersList = [], appliedFilters = {}) => {
  if (!filtersList?.length || !appliedFilters || !Object.keys(appliedFilters).length) {
    return 0;
  }
  let count = 0;
  filtersList.forEach(item => {
    if (appliedFilters[item.key]) {
      const filter = getAppliedFilter(item.type, appliedFilters[item.key]);
      const listFilter = item.type === 'list' ? filter : 1;
      count += filter ? listFilter : 0;
    }
  });
  return count;
};

export const getDefaultFilter = type => {
  switch (type) {
    case 'list':
    case 'date':
      return [];
    case 'percent':
      return [0, 100];
    default:
      return [];
  }
};

export const getDateFilters = format => {
  const currentDate = moment().format(format);
  const prevWeek = moment().subtract(1, 'weeks');
  const prevMonth = moment().subtract(1, 'months');
  return [
    {
      value: JSON.stringify([
        moment()
          .startOf('week')
          .format(format),
        currentDate,
      ]),
      label: 'Current week',
    },
    {
      value: JSON.stringify([
        prevWeek.startOf('week').format(format),
        prevWeek.endOf('week').format(format),
      ]),
      label: 'Previous week',
    },
    {
      value: JSON.stringify([
        prevMonth.startOf('month').format(format),
        prevMonth.endOf('month').format(format),
      ]),
      label: 'Previous month',
    },
    {
      value: JSON.stringify([
        moment()
          .subtract(3, 'months')
          .format(format),
        currentDate,
      ]),
      label: 'Last 3 months',
    },
    {
      value: JSON.stringify([
        moment()
          .subtract(6, 'months')
          .format(format),
        currentDate,
      ]),
      label: 'Last 6 months',
    },
    {
      value: JSON.stringify([
        moment()
          .startOf('year')
          .format(format),
        currentDate,
      ]),
      label: 'Current year',
    },
  ];
};

export const buildArrayFilter = (ids, resource) => {
  if(!ids?.length || !resource){
    return '';
  }
  let stringBuilder = '?';
  ids.forEach((id, idx) => {
    stringBuilder += `${idx !== 0 ? '&' : ''}${resource}[]=${id}`;
  });
  return stringBuilder;
};
