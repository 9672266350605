import { useMemo, useState, useEffect, useCallback, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Modal, Row, Col } from 'antd';
import { InputAdornment, IconButton } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import PasswordExpired from '../PasswordExpired';

import { updateProfileAction } from '../../actions/profile.actions';
import Icon from '../Shared/Icon';
import { OutlinedInput } from '../Ui/StyledTextField';
import { Button } from '../Ui/Button';
import { Title, Text } from '../Ui/Typography';
import { isOnDoDNetwork } from '../../Environment';
import { isPasswordValid } from '../../utils/isPasswordValid';
import laptop from '../../assets/images/laptop.png';
import './styles.less';
import Spinner from '../Shared/Spinner/index';
import { useMigrationStatus } from '../../useMigrationStatus';

export const UpdateInitialInfo = ({ profile }) => {
  const [visible, toggleModal] = useState(false);
  const [showPassword, toggleShowPassword] = useState(false);

  const { handleSubmit, register, errors, getValues, formState, triggerValidation } = useForm({
    mode: 'onBlur',
  });
  const { isAuthenticated: usingSSO, isLoading } = useAuth0();
  useMigrationStatus();
  const dispatch = useDispatch();
  const ERROR_PASSWORD = 'confirm-password';
  const required = 'This field is required';
  const onSubmit = useCallback(
    data => {
      if (!formState.isValid) {
        triggerValidation().then();
      } else {
        dispatch(updateProfileAction.request({ ...data, isSSO: usingSSO }));
      }
    },
    [dispatch, formState.isValid, triggerValidation, usingSSO],
  );
  const clickToggleShowPassword = useCallback(() => {
    toggleShowPassword(!showPassword);
  }, [showPassword]);
  const preventDefaultEvent = useCallback(event => {
    event.preventDefault();
  });
  const tempOrExpPassword = useMemo(() => {
    return !!profile?.is_password_temp || !!profile?.is_password_exp;
  }, [profile?.is_password_temp, profile?.is_password_exp]);
  const noSsoAndNoName = useMemo(() => {
    return !usingSSO && !profile?.name;
  }, [profile?.name, usingSSO]);

  useEffect(() => {
    if (profile) {
      toggleModal(!isOnDoDNetwork && (tempOrExpPassword || !profile?.name));
    }
  }, [tempOrExpPassword, profile?.name]);

  return (
    <Modal
      footer={null}
      visible={visible}
      centered
      closable={false}
      width={784}
      className='update-default-password-modal'>
      {!profile?.is_password_exp && (
        <Spinner spinning={isLoading} isLocal>
          <div className='content'>
            <div className='update-message'>
              <img src={laptop} alt='Laptop' className='laptop-icon' />
              <div className='update-message-wrapper'>
                <Title level={2} className='title'>
                  Welcome to Learn To Win!
                </Title>
                <div className='subtitle'>
                  <Text color='grey-1'>
                    {noSsoAndNoName && profile?.is_password_temp
                      ? 'Please set your name & password to ensure your account is safe and sound.'
                      : "You're almost done with setup."}
                  </Text>
                </div>
              </div>
            </div>
            <form className='password-changing-form'>
              {!profile?.name && (
                <Fragment>
                  <Text level={5} className='input-group-label'>
                    What’s your name?
                  </Text>
                  <Row className='input-group' type='flex' gutter={32}>
                    <Col span={12}>
                      <OutlinedInput
                        error={Boolean(errors['fname'])}
                        className='password-input'
                        helperText={errors['fname']?.message}
                        type='text'
                        label='First Name'
                        id='fname'
                        name='fname'
                        inputRef={register({
                          required,
                        })}
                      />
                    </Col>
                    <Col span={12}>
                      <OutlinedInput
                        error={Boolean(errors['lname'])}
                        className='password-input'
                        helperText={errors['lname']?.message}
                        type='text'
                        label='Last Name'
                        id='lname'
                        name='lname'
                        inputRef={register({
                          required,
                        })}
                      />
                    </Col>
                  </Row>{' '}
                </Fragment>
              )}
              {!usingSSO && tempOrExpPassword && (
                <Fragment>
                  <Text level={5} className='input-group-label'>
                    Set your password
                  </Text>
                  <Row className='input-group' type='flex' gutter={32}>
                    <Col span={12}>
                      <OutlinedInput
                        error={Boolean(errors['password'])}
                        className='password-input'
                        helperText={errors['password']?.message}
                        type={showPassword ? 'text' : 'password'}
                        label='New Password'
                        id='password'
                        name='password'
                        inputRef={register({
                          required,
                          validate: value =>
                            isPasswordValid(value) ||
                            'Passwords must be at least 8 characters long and contain: one upper case character [A-Z], one lower case character [a-z], and one number [0-9]',
                        })}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={clickToggleShowPassword}
                              onMouseDown={preventDefaultEvent}>
                              {showPassword ? (
                                <Icon icon='visible' size={22} />
                              ) : (
                                <Icon icon='hidden' size={22} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{ autoComplete: 'new-password' }}
                      />
                    </Col>
                    <Col span={12}>
                      <OutlinedInput
                        error={Boolean(errors[ERROR_PASSWORD])}
                        className='password-input'
                        helperText={errors[ERROR_PASSWORD]?.message}
                        type='password'
                        label='Confirm New Password'
                        id='confirm-password'
                        name='confirm-password'
                        inputRef={register({
                          required,
                          validate: value =>
                            value === getValues()['password'] || 'Passwords did not match',
                        })}
                        inputProps={{ autoComplete: 'new-password' }}
                      />
                    </Col>
                  </Row>
                </Fragment>
              )}
            </form>
          </div>
          <div className='submit-container'>
            <Button
              className='submit-btn'
              type='submit'
              onClick={handleSubmit(onSubmit)}
              variant='contained'
              color='primary'
              disabled={!formState.isValid}>
              <Text level={4} color='white'>
                Save
              </Text>
            </Button>
          </div>
        </Spinner>
      )}
      {profile?.is_password_exp && <PasswordExpired isSSO={usingSSO} />}
    </Modal>
  );
};

export default UpdateInitialInfo;
