import produce from 'immer';

import { signInAction, getResetPasswordTokenAction } from '../../actions/auth.actions';

export const defaultState = {
  authenticated: false,
  schools: [],
  token: '',
  isCoach: false,
};

const auth = produce((state, action) => {
  switch (action.type) {
    case signInAction.requestTypes.SUCCESS:
      state.authenticated = true;
      break;
    case getResetPasswordTokenAction.requestTypes.SUCCESS:
      state.token = action.token;
      state.isCoach = true;
      break;
  }
}, defaultState);

export default auth;
