import { makeRequestAction, makeAction } from './action-generator';

export const getUsersAction = makeRequestAction('GET_USERS', {
  onSuccess(params, response) {
    params.onSuccess && params.onSuccess();
    return {
      users: response.data?.users,
      dataCount: response.data?.counts,
      code: response.code,
      message: response.message,
    };
  },
});
export const resendPasswordAction = makeRequestAction('RESEND_PASSWORD', {
  onSuccess(params) {
    params.onSuccess && params.onSuccess();
  },
  onFailure(params) {
    params.onFailure && params.onFailure();
  },
});

export const getSingleUser = makeRequestAction('GET_SINGLE_USER', {
  onSuccess(params, response) {
    return {
      user: response.data,
      code: response.code,
      message: response.message,
    };
  },
});

export const inviteUsersAction = makeRequestAction('USER_INVITED', {
  onSuccess(params, response) {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure(params, error) {
    params.onFailure && params.onFailure(error.error?.message);
    return error;
  },
});

export const editUserAction = makeRequestAction('EDIT_USER', {
  onSuccess(params, response) {
    params.onSuccess && params.onSuccess();
    return {
      users: response.data?.users,
      code: response.code,
      message: response.message,
    };
  },
  onFailure(params, error) {
    params.onFailure && params.onFailure();
    return {
      message: error.error?.message,
      error: error.error,
    };
  },
});

export const removeUsersAction = makeRequestAction('DELETE_USERS', {
  onSuccess(params, response) {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure(params) {
    params.onFailure && params.onFailure();
  },
});

export const removeCoachesAction = makeRequestAction('DELETE_COACHES', {
  onSuccess(params, response) {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure(params) {
    params.onFailure && params.onFailure();
  },
});

export const getGeneratedPassword = makeRequestAction('GET_GENERATED_PASSWORD', {
  onSuccess(params, response) {
    return {
      generatedPassword: response.data?.password,
    };
  },
});

export const resetPasswordAction = makeRequestAction('RESET_PASSWORD', {
  onSuccess(params) {
    params.onSuccess && params.onSuccess();
  },
});

export const deleteGeneratedPassword = makeAction('DELETE_GENERATED_PASSWORD');

export const terminateTokenAction = makeRequestAction('TERMINATE_TOKEN');
