import { makeRequestAction } from './action-generator';

export const getPositionGroupsAction = makeRequestAction('GET_POSITION_GROUPS', {
  onSuccess(params, response) {
    return {
      groups: response?.data,
    };
  },
});

export const getGroupUsersAction = makeRequestAction('GET_GROUP_USERS', {
  onSuccess(params, response) {
    params.onSuccess && params.onSuccess();
    return {
      groupUsers: response.data,
    };
  },
});

export const createPositionGroupsAction = makeRequestAction('CREATE_POSITION_GROUPS', {
  onSuccess(params, response) {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure(params, error) {
    params.onFailure && params.onFailure();
    return error;
  },
});

export const editPositionGroupsAction = makeRequestAction('EDIT_POSITION_GROUPS', {
  onSuccess(params, response) {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure(params) {
    params.onFailure && params.onFailure();
  },
});

export const deletePositionGroupAction = makeRequestAction('DELETE_POSITION_GROUP', {
  onSuccess(params, response) {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure(params) {
    params.onFailure && params.onFailure();
  },
});
