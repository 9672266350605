import { createStore, applyMiddleware } from 'redux';
import { reset } from 'redux-form';
import createSagaMiddleware, { END } from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import * as Sentry from '@sentry/react';

import rootSaga from './sagas';
import rootReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store = createStore(
  rootReducer,
  {},
  composeWithDevTools(applyMiddleware(...middlewares), sentryReduxEnhancer),
);

sagaMiddleware.run(rootSaga);
store.close = () => store.dispatch(END);
store.dispatch(reset());

export default store;
