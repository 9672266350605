import client from '../client';

export const getSignedStorageURLApi = ({ params }, cancelToken) => {
  return client.get(`/get-signed-s3-url/${params?.method}?${params?.keys}`, {
    cancelToken,
  });
};

export const getSignedStorageURLBulkApi = ({ params }, cancelToken) => {
  return client.post(`/get-signed-s3-url-bulk/${params?.method}`, {
    cancelToken,
    keys: params?.keys,
  });
};
