import { clientV2 } from '../../client';

export const foldersBasePath = 'content-bank-folders';
export const filesBasePath = 'content-bank-files';

export const getContentFoldersApi = (_, cancelToken) => {
  return clientV2.get(foldersBasePath, { cancelToken });
};

export const getContentFoldersTreeApi = (_, cancelToken) => {
  return clientV2.get(`${foldersBasePath}/tree`, { cancelToken });
};

export const getContentFolderByIdApi = ({ params }, cancelToken) => {
  return clientV2.get(`${foldersBasePath}/${params.folderId}`, { cancelToken });
};

export const createContentFolderApi = ({ params }, cancelToken) => {
  return clientV2.post(
    foldersBasePath,
    {
      parent_folder_id: params.parent_folder_id,
      title: params.title,
      color: params.color,
      user_groups: params.user_groups,
    },
    {
      cancelToken,
    },
  );
};

export const updateContentFolderApi = ({ params }, cancelToken) => {
  return clientV2.put(
    `${foldersBasePath}/${params.id}`,
    {
      title: params.title,
      color: params.color,
      user_groups: params.user_groups,
    },
    {
      cancelToken,
    },
  );
};

export const moveContentFolderApi = ({ params }, cancelToken) => {
  return clientV2.post(
    `${foldersBasePath}/${params.folderId}/move`,
    {
      parent_folder_id: params.parent_folder_id,
    },
    {
      cancelToken,
    },
  );
};

export const reorderContentFoldersApi = ({ params }, cancelToken) => {
  return clientV2.post(
    `${foldersBasePath}/reorder`,
    {
      folders: params.folders,
    },
    {
      cancelToken,
    },
  );
};

export const deleteContentFolderApi = ({ params }, cancelToken) => {
  return clientV2.delete(`${foldersBasePath}/${params.folderId}`, { cancelToken });
};

export const addContentFileApi = ({ params }, cancelToken) => {
  return clientV2.post(
    filesBasePath,
    {
      media_type: params.media_type,
      content_bank_folder_id: params.content_bank_folder_id,
      title: params.title,
      file_link: params.file_link,
      file_id: params.file_id,
      from_desktop: false,
    },
    { cancelToken },
  );
};

export const addPdfPptContentApi = ({ params }, cancelToken) => {
  const body = new FormData();
  body.append('content_bank_folder_id', params.content_bank_folder_id);
  if (params.files?.length) {
    for (const f of params.files) {
      body.append('files[]', f.file);
    }
  }
  return clientV2.post(`${filesBasePath}/add-convertable`, body, {
    cancelToken,
    onUploadProgress: params.onUploadProgress,
  });
};

export const updateContentFileApi = ({ params }, cancelToken) => {
  return clientV2.put(
    `${filesBasePath}/${params.fileId}`,
    { title: params.title },
    { cancelToken },
  );
};

export const deleteContentFilesApi = ({ params }, cancelToken) => {
  return clientV2.post(`${filesBasePath}/delete`, { file_ids: params.file_ids }, { cancelToken });
};

export const moveContentFilesApi = ({ params }, cancelToken) => {
  return clientV2.post(
    `${filesBasePath}/move`,
    {
      file_ids: params.file_ids,
      folder_id: params.folder_id,
    },
    {
      cancelToken,
    },
  );
};

export const reorderContentFilesApi = ({ params }, cancelToken) => {
  return clientV2.post(
    `${filesBasePath}/reorder`,
    {
      file_ids: params.file_ids,
    },
    {
      cancelToken,
    },
  );
};
