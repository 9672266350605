import produce from 'immer';

import { getProfileAction, updateProfileAction } from '../../actions/profile.actions';

export const defaultState = {
  coachDetail: {
    school_infos: {},
    wording: {
      playersSub: {
        members: {
          userTypes: [],
        },
      },
    },
  },
};

const profile = produce((state, action) => {
  switch (action.type) {
    case getProfileAction.requestTypes.SUCCESS:
      state.coachDetail = action.coachDetail;
      state.code = action.code;
      break;
    case updateProfileAction.requestTypes.SUCCESS:
      state.code = action.code;
      break;
    case getProfileAction.requestTypes.FAILURE:
    default:
      state.code = action.code;
      state.message = action.message;
      break;
  }
}, defaultState);

export default profile;
