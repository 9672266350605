import { useState, useLayoutEffect } from 'react';

export const useSize = target => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect());

    const sizeObserver = new ResizeObserver(entries => {
      setSize(entries[0].contentRect);
    });

    sizeObserver.observe(target.current);

    return () => {
      sizeObserver.disconnect();
    };
  }, [target]);

  return size;
};
