import { getIncidentsListAction, getMaintenanceListAction } from '../actions/app.actions';
import { getIncidentsListApi, getMaintenanceListApi } from '../services/app.service';

export const watchGetIncidents = {
  ...getIncidentsListAction,
  api: getIncidentsListApi,
};

export const watchGetMaintenance = {
  ...getMaintenanceListAction,
  api: getMaintenanceListApi,
};
