import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { passwordCheckAction } from '../../actions/profile.actions';

import { Button } from '../Ui/Button';
import { OutlinedInput } from '../Ui/StyledTextField';

import {
  FIELD_REQUIRED_MSG,
  INVALID_PASSWORD_MSG,
  PASSWORD_DID_NOT_MATCH_MSG,
} from '../../constants/userMessages';
import { isPasswordValid } from '../../utils/isPasswordValid';
import { BAD_REQUEST } from '../../constants/httpCodes';
import '../SignIn/styles.less';

const ResetForm = ({ handleResetRequest, token }) => {
  const { getValues, handleSubmit, register, setError, errors } = useForm({
    mode: 'onBlur',
  });
  const [checkPassword, setCheckPassword] = useState('');
  const [passwordFlag, setPasswordFlag] = useState(true);
  const dispatch = useDispatch();

  const onSuccess = useCallback(res => {
    setPasswordFlag(false);
    if (parseInt(res?.code) >= BAD_REQUEST) {
      setError('password', '', res?.message);
    }
  }, []);

  const handleCheckPassword = useCallback(() => {
    if (checkPassword.length >= 8) {
      dispatch(
        passwordCheckAction.request({
          password: checkPassword,
          token,
          onSuccess,
        }),
      );
    }
  }, [checkPassword, token]);

  const handleChange = useCallback(e => {
    setCheckPassword(e.target.value);
  }, []);

  const onSubmit = useCallback(
    data => {
      handleResetRequest(data, token);
    },
    [handleResetRequest, token],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate data-testid='reset-form'>
      <OutlinedInput
        inputRef={register({
          required: FIELD_REQUIRED_MSG,
          validate: value => isPasswordValid(value) || INVALID_PASSWORD_MSG,
        })}
        inputProps={{
          'data-testid': 'reset-password',
        }}
        error={'password' in errors}
        className='login-input'
        required
        helperText={errors['password']?.message}
        type='password'
        label='New Password'
        id='password'
        name='password'
        margin='normal'
        value={checkPassword}
        onBlur={handleCheckPassword}
        onChange={handleChange}
      />
      <OutlinedInput
        inputRef={register({
          required: FIELD_REQUIRED_MSG,
          validate: value => value === getValues()['password'] || PASSWORD_DID_NOT_MATCH_MSG,
        })}
        inputProps={{
          'data-testid': 'confirm-reset-password',
        }}
        error={'confirm-password' in errors}
        className='login-input'
        helperText={errors['confirm-password']?.message}
        type='password'
        label='Confirm Password'
        id='confirm-password'
        name='confirm-password'
        margin='normal'
      />
      <Button
        size='large'
        disabled={passwordFlag}
        variant='outlined'
        color='secondary'
        type='submit'
        className='login-btn'>
        Reset
      </Button>
    </form>
  );
};

export default ResetForm;
