import { createSelector } from 'reselect';
import {
  addAlbumAction,
  updateAlbumAction,
  deleteAlbumAction,
  changeAlbumOrderAction,
  getAlbumsAction,
  getAllMediaAction,
  addMediaAction,
  updateMediaAction,
  deleteMediaAction,
  changeMediaOrderAction,
  addPresentationAction,
} from 'actions/contentBank.actions';
import createLoadingSelector from '../create-loading-selector';
import createErrorSelector from '../create-error-selector';

export const getAlbumsLoading = createLoadingSelector(getAlbumsAction.actionName);

export const addAlbumError = createErrorSelector(addAlbumAction.actionName);
export const addAlbumLoading = createLoadingSelector(addAlbumAction.actionName);

export const updateAlbumError = createErrorSelector(updateAlbumAction.actionName);
export const updateAlbumLoading = createLoadingSelector(updateAlbumAction.actionName);

export const deleteAlbumError = createErrorSelector(deleteAlbumAction.actionName);
export const deleteAlbumLoading = createLoadingSelector(deleteAlbumAction.actionName);

export const changeAlbumOrderError = createErrorSelector(changeAlbumOrderAction.actionName);
export const changeAlbumOrderLoading = createLoadingSelector(changeAlbumOrderAction.actionName);

export const getAllMediaError = createErrorSelector(getAllMediaAction.actionName);
export const getAllMediaLoading = createLoadingSelector(getAllMediaAction.actionName);

export const addMediaError = createErrorSelector(addMediaAction.actionName);
export const addMediaLoading = createLoadingSelector(addMediaAction.actionName);

export const addPresentationError = createErrorSelector(addPresentationAction.actionName);
export const addPresentationLoading = createLoadingSelector(addPresentationAction.actionName);

export const deleteMediaError = createErrorSelector(deleteMediaAction.actionName);
export const deleteMediaLoading = createLoadingSelector(deleteMediaAction.actionName);

export const changeMediaOrderLoading = createLoadingSelector(changeMediaOrderAction.actionName);
export const changeMediaOrderError = createErrorSelector(changeMediaOrderAction.actionName);

export const updateMediaError = createErrorSelector(updateMediaAction.actionName);
export const updateMediaLoading = createLoadingSelector(updateMediaAction.actionName);

export const contentBank = state => state.contentBank;

export const getCollections = createSelector(contentBank, bank => bank.collections);

export const getAlbums = createSelector(contentBank, bank => bank.albums);

export const getUserGroups = createSelector(contentBank, bank => bank.groups);

export const contentBankIsLoading = createSelector(
  getAlbumsLoading,
  updateAlbumLoading,
  deleteAlbumLoading,
  addAlbumLoading,
  (gal, ual, dal, aal) => !!(gal || ual || dal || aal),
);

export const mediaIsLoading = createSelector(
  getAllMediaLoading,
  addMediaLoading,
  addPresentationLoading,
  deleteMediaLoading,
  deleteAlbumLoading,
  updateAlbumLoading,
  (gm, am, ap, dm, da, ua) => !!(gm || am || ap || dm || da || ua),
);

export const mediaContent = createSelector(contentBank, bank => bank.media);

export const slidesContent = createSelector(mediaContent, media =>
  media.filter(curr => curr.media_type !== 'pdf'),
);
