import { clientV2 } from '../../client';

export const getFolderGroupsApi = ({ params }, cancelToken) => {
  return clientV2.get('/package-folder-groups', {
    cancelToken,
  });
};

export const createFolderGroupApi = ({ params }, cancelToken) => {
  return clientV2.post('/package-folder-groups', params, {
    cancelToken,
  });
};

export const editFolderGroupTitleApi = ({ params }, cancelToken) => {
  return clientV2.put(`/package-folder-groups/${params.id}`, params, {
    cancelToken,
  });
};

export const reorderFolderGroupsApi = ({ params }, cancelToken) => {
  return clientV2.post('/package-folder-groups/reorder', params, {
    cancelToken,
  });
};

export const deleteFolderGroupApi = ({ params }, cancelToken) => {
  return clientV2.delete(`/package-folder-groups/${params.id}`, {
    cancelToken,
  });
};

export const createFolderApi = ({ params }, cancelToken) => {
  const formData = new FormData();
  formData.append('package_folder_group_id', params.package_folder_group_id);
  if (params?.img) {
    formData.append('img', params?.img);
  }
  formData.append('title', params.title);
  formData.append('color', params.color);
  return clientV2.post('/package-folders', formData, {
    cancelToken,
  });
};

export const editFolderApi = ({ params }, cancelToken) => {
  const formData = new FormData();
  formData.append('package_folder_group_id', params?.package_folder_group_id);
  if (params?.img) {
    formData.append('img', params?.img);
  }
  formData.append('title', params?.title);
  formData.append('color', params?.color);
  return clientV2.post(`/package-folders/${params.id}`, formData, {
    cancelToken,
  });
};

export const moveFolderApi = ({ params }, cancelToken) => {
  return clientV2.post('/package-folders/move', params, {
    cancelToken,
  });
};

export const duplicateFolderApi = ({ params }, cancelToken) => {
  return clientV2.post('/package-folders/duplicate', params, {
    cancelToken,
  });
};

export const reorderFoldersApi = ({ params }, cancelToken) => {
  return clientV2.post('/package-folders/reorder', params, {
    cancelToken,
  });
};

export const deleteFolderApi = ({ params }, cancelToken) => {
  return clientV2.delete(`/package-folders/${params.id}`, {
    cancelToken,
  });
};

export const bulkDeleteFoldersApi = ({ params }, cancelToken) => {
  return clientV2.post('/package-folders/delete', params, {
    cancelToken,
  });
};

export const getPackagesApi = ({ params }, cancelToken) => {
  return clientV2.get(`/get-packages/${params.id}`, {
    cancelToken,
  });
};

export const createPackageApi = ({ params }, cancelToken) => {
  const formData = new FormData();
  formData.append('package_folder_id', params?.package_folder_id);
  if (params?.img) {
    formData.append('img', params?.img);
  }
  formData.append('title', params?.title);
  formData.append('description', params?.description);
  return clientV2.post('/packages', formData, {
    cancelToken,
  });
};

export const editPackageApi = ({ params }, cancelToken) => {
  const formData = new FormData();
  if (params?.img) {
    formData.append('img', params?.img);
  }
  formData.append('title', params?.title);
  formData.append('description', params?.description);
  formData.append('is_published', Number(params?.is_published));
  return clientV2.post(`/packages/${params.id}`, formData, {
    cancelToken,
  });
};

export const reorderPackagesApi = ({ params }, cancelToken) => {
  return clientV2.post('/packages/reorder', params, {
    cancelToken,
  });
};

export const duplicatePackageApi = ({ params }, cancelToken) => {
  return clientV2.post(`/packages/${params.id}/duplicate`, params, {
    cancelToken,
  });
};

export const movePackageApi = ({ params }, cancelToken) => {
  return clientV2.post(`/packages/${params.id}/move`, params, {
    cancelToken,
  });
};

export const deletePackageApi = ({ params }, cancelToken) => {
  return clientV2.delete(`/packages/${params.id}`, {
    cancelToken,
  });
};

export const getPackageApi = ({ params }, cancelToken) => {
  return clientV2.get(`/packages/${params.id}`, {
    cancelToken,
  });
};

export const createLessonApi = ({ params }, cancelToken) => {
  const formData = new FormData();
  if (params?.img) {
    formData.append('img', params?.img);
  }
  if (params?.file) {
    formData.append('file', params?.file);
  }
  formData.append('title', params?.title);
  formData.append('description', params?.description);
  formData.append('package_id', params?.package_id);
  return clientV2.post('/lessons', formData, {
    cancelToken,
  });
};

export const createQuizApi = ({ params }, cancelToken) => {
  const formData = new FormData();
  if (params?.img) {
    formData.append('img', params?.img);
  }
  formData.append('title', params?.title);
  formData.append('description', params?.description);
  formData.append('package_id', params?.package_id);
  return clientV2.post('/quizzes', formData, {
    cancelToken,
  });
};

export const editLessonApi = ({ params }, cancelToken) => {
  const formData = new FormData();
  formData.append('permission_groups', params.permission_groups);
  formData.append('title', params.title);
  formData.append('is_published', params.is_published);
  return clientV2.post(`/lessons/${params.id}`, formData, {
    cancelToken,
  });
};

export const editQuizApi = ({ params }, cancelToken) => {
  const formData = new FormData();
  formData.append('permission_groups', params.permission_groups);
  formData.append('title', params.title);
  formData.append('is_published', params.is_published);
  return clientV2.post(`/quizzes/${params.id}`, formData, {
    cancelToken,
  });
};

export const reorderLessonsApi = ({ params }, cancelToken) => {
  return clientV2.post('/lessons/reorder', params, {
    cancelToken,
  });
};

export const reorderQuizzesApi = ({ params }, cancelToken) => {
  return clientV2.post('/quizzes/reorder', params, {
    cancelToken,
  });
};

export const duplicateLessonApi = ({ params }, cancelToken) => {
  return clientV2.post(`/lessons/${params.id}/duplicate`, params, {
    cancelToken,
  });
};

export const duplicateQuizApi = ({ params }, cancelToken) => {
  return clientV2.post(`/quizzes/${params.id}/duplicate`, params, {
    cancelToken,
  });
};

export const moveLessonApi = ({ params }, cancelToken) => {
  return clientV2.post(`/lessons/${params.id}/move`, params, {
    cancelToken,
  });
};

export const moveQuizApi = ({ params }, cancelToken) => {
  return clientV2.post(`/quizzes/${params.id}/move`, params, {
    cancelToken,
  });
};

export const deleteLessonApi = ({ params }, cancelToken) => {
  return clientV2.delete(`/lessons/${params.id}`, {
    cancelToken,
  });
};

export const deleteQuizApi = ({ params }, cancelToken) => {
  return clientV2.delete(`/quizzes/${params.id}`, {
    cancelToken,
  });
};
