import client from '../client';

export const sendSupportMessageApi = ({ params }, cancelToken) => {
  const data = new FormData();
  data.append('description', params.description);
  data.append('contact_detail', params.contactDetail);
  params.supportFiles.forEach(f => {
    data.append('support_files[]', f);
  });

  return client.post('/add-support-request', data, { cancelToken });
};
