import client from '../client';

export const getAlbumsApi = ({ params }, cancelToken) => {
  return client.get('/get-playbook-folders', {
    params: {
      search: params.search,
    },
    cancelToken,
  });
};

export const getCollectionsApi = ({ params }, cancelToken) => {
  return client.get(`/get-category/${params?.folderId}`, {
    cancelToken,
  });
};

export const addCollectionApi = ({ params }, cancelToken) => {
  const formData = new FormData();
  formData.append('cat_name', params.cat_name);
  formData.append('cat_image', params.cat_image);
  formData.append('playbook_folder_id', params.playbook_folder_id);
  formData.append('user_grps', params.user_grps);
  return client.post('/add-category', formData, {
    cancelToken,
  });
};

export const updateCollectionApi = ({ params }, cancelToken) => {
  const formData = new FormData();
  formData.append('id', params.id);
  formData.append('name', params.name);
  if (params.playbook_folder_id) {
    formData.append('playbook_folder_id', params.playbook_folder_id);
  }
  if (params.user_grps) {
    params.user_grps.forEach(item => formData.append('user_grps', item));
  }
  if (params.del_group_ids) {
    params.del_group_ids.forEach(item => formData.append('del_group_ids', JSON.stringify(item)));
  }
  if (params.cat_image) {
    formData.append('cat_image', params.cat_image);
  }
  return client.post(`/update-category/${params.id}`, formData, {
    cancelToken,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteCollectionApi = ({ params }, cancelToken) => {
  return client.delete(`/delete-category/${params.id}`, {
    cancelToken,
  });
};

export const changeCollectionOrderApi = ({ params }, cancelToken) => {
  return client.post('/update-category-order', params, {
    cancelToken,
  });
};

export const getUserGroupsApi = ({ params }, cancelToken) => {
  return client.get('/user-group-data', {
    cancelToken,
  });
};

export const addAlbumApi = ({ params }, cancelToken) => {
  return client.post('/add-playbook-folder', params, { cancelToken });
};

export const updateAlbumApi = ({ params }, cancelToken) => {
  return client.post(`/update-playbook-folder/${params.id}`, params, { cancelToken });
};

export const deleteAlbumApi = ({ params }, cancelToken) => {
  return client.delete(`/delete-playbook-folder/${params.id}`, {
    cancelToken,
  });
};

export const moveCollectionIntoAlbumApi = ({ params }, cancelToken) => {
  return client.post('/move-category-to-folder', params, { cancelToken });
};

export const changeAlbumOrderApi = ({ params }, cancelToken) => {
  return client.post('/update-playbook-folder-order', params, {
    cancelToken,
  });
};

export const getAllMediaApi = ({ params }, cancelToken) => {
  return client.get(`/content-list/${params.content_bank_folder_id}`, {
    cancelToken,
    params: {
      search: params.search,
    },
  });
};

export const updateMediaApi = ({ params }, cancelToken) => {
  return client.post(`/update-content/${params.id}`, params, { cancelToken });
};

export const addMediaApi = ({ params }, cancelToken) => {
  const data = new FormData();
  data.append('title', params.title);
  data.append('content_bank_folder_id', params.content_bank_folder_id);
  data.append('file_id', params.file_id);
  data.append('file_link', params.file_link);
  data.append('media_type', params.media_type);

  return client.post('/add-content', data, { cancelToken });
};

export const addPresentationApi = ({ params }, cancelToken) => {
  const data = new FormData();
  data.append('content_bank_folder_id', params.content_bank_folder_id);
  params.files.forEach(f => {
    data.append('files[]', f.file);
  });
  return client.post('/add-ppt-content', data, {
    cancelToken,
    onUploadProgress: params.onUploadProgress,
  });
};

export const deleteMediaApi = ({ params }, cancelToken) => {
  return client.delete(`/delete-content/${params.bankId}`, { cancelToken });
};

export const changeMediaOrderApi = ({ params }, cancelToken) => {
  return client.post(`/update-content-order/${params.content_bank_folder_id}`, params, {
    cancelToken,
  });
};
