import { useEffect, useRef } from 'react';
import styles from '../styles/ripple.module.css';

const useRipple = ({ onClick }) => {
  const element = useRef();
  const timer = useRef();
  const pressed = useRef();

  useEffect(() => {
    return () => clearTimeout(timer.current);
  }, []);

  const ripple = (target, x, y, keyframe) => {
    const button = target;

    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    const { current: getRipple } = element;

    getRipple.removeAttribute('style');

    getRipple.classList.remove(keyframe);

    const rect = button.getBoundingClientRect();

    const getButtonXCenter = rect.x + rect.width / 2;
    const getButtonYCenter = rect.y + rect.height / 2;

    const fallbackX = x || getButtonXCenter;
    const fallbackY = y || getButtonYCenter;

    getRipple.style.width = `${diameter}px`;
    getRipple.style.height = `${diameter}px`;
    getRipple.style.left = `${fallbackX - rect.x - radius}px`;
    getRipple.style.top = `${fallbackY - rect.y - radius}px`;

    getRipple.classList.add(keyframe);
  };

  const handlePress = (target, x, y) => {
    pressed.current = true;
    ripple(target, x, y, styles.animate__forwards);
  };

  const onPointerDown = e => {
    pressed.current = false;
    const target = e.currentTarget;
    const x = e.clientX;
    const y = e.clientY;
    timer.current = setTimeout(() => {
      handlePress(target, x, y);
    }, 200);
  };

  const handleClick = e => {
    if (pressed.current) {
      element.current.classList.remove(styles.animate__forwards);
      element.current.classList.remove(styles.animate);
    } else {
      const target = e.currentTarget;
      ripple(target, e.clientX, e.clientY, styles.animate);
    }

    if (onClick) {
      onClick(e);
    }
  };

  const onPointerUp = () => {
    clearTimeout(timer.current);
  };

  const onPointerLeave = () => {
    if (pressed.current) {
      clearTimeout(timer.current);
      element.current.classList.remove(styles.animate__forwards);
      element.current.classList.remove(styles.animate);
    }
  };

  return {
    events: { onPointerDown, onPointerUp, onPointerLeave, onClick: handleClick },
    Ripple: <span ref={element} className={styles.ripple} data-testid='button-ripple' />,
  };
};

export { useRipple };
