import { lazy, Fragment, useEffect, useState, Suspense } from 'react';
import { Router, Route } from 'react-router-dom';
import { Modal } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';

import { localStorageKeys } from './constants/localStorageKeys';
import {
  UNAUTHORIZED_DEFAULT_ROUTE,
  UNAUTHORIZED_NO_ACCESS,
  DEFAULT_PACKAGES_ROUTE,
} from './constants/routes';
import { clearLocalStorage, trySetAccessToken } from './utils/localStorage';
import initializePendo from './utils/initializePendo';
import initializeHeap from './utils/initializeHeap';
import useSystemStatus from './hooks/useSystemStatusHook';
import {
  isOnProduction,
  isOnDoDNetwork,
  isOnNewLayout,
  isHeapEnabled,
  isPendoEnabled,
  trySetClientAuthScheme,
} from './Environment';
import history from './history';

import { GET_PROFILE_STATUS } from './operations/queries/profile';
import { isAuthError } from './operations/helpers';

import NewLayoutWelcome from './components/NewLayoutWelcome';
import UpdateInitialInfo from './components/UpdateInitialInfo';
import Spinner from './components/Shared/Spinner';
import { Text } from './components/Ui/Typography';
import { errorToast } from './components/Ui/Notification';

import './components/main.less';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/font.css';
import './assets/fonts/icomoon/style.less';

const AppMainNavigation = lazy(() => import('./AppNavigation'));

const serverSideMaintenanceGateEnabled = true;

const systemOutage = '/system-outage';
const maintenanceStr = '/maintenance';
const publicRoutePathRegex = /^(\/sign-in|\/forgot-password|\/coach\/reset-password)/;

export const DefaultSpinner = () => (
  <Spinner tip='Loading...' spinning>
    <div style={{ width: '100vw', height: '100vh' }} />
  </Spinner>
);

const App = () => {
  const [userStatusLoading, setUserStatusLoading] = useState(true);
  const { getAccessTokenSilently, isAuthenticated, isLoading: ssoAuthLoading } = useAuth0();
  const [incidents, maintenance] = useSystemStatus();

  const [getProfileStatus, { data, loading, error }] = useLazyQuery(GET_PROFILE_STATUS, {
    fetchPolicy: 'network-only',
  });



  const { instructorProfile, isLoggedIn } = data || {};
  const { pathname } = window.location;

  const handleAuthError = () => {
    if (isAuthenticated) {
      // show no access page when logged in with auth0 but user not valid
      history.push(UNAUTHORIZED_NO_ACCESS);
    } else {
      // clear storage and redirect to sign in if not authenticated in any way and not on public route
      if (!publicRoutePathRegex.test(pathname)) {
        clearStorage();
        history.push(UNAUTHORIZED_DEFAULT_ROUTE);
      }
    }
  };

  useEffect(() => {
    if (!isOnDoDNetwork && isOnProduction) {
      if (serverSideMaintenanceGateEnabled && incidents?.length && pathname !== systemOutage) {
        history.push(systemOutage);
      } else if (
        serverSideMaintenanceGateEnabled &&
        maintenance?.length &&
        pathname !== maintenanceStr
      ) {
        history.push(maintenanceStr);
      } else if (
        !serverSideMaintenanceGateEnabled ||
        (pathname === systemOutage && !incidents.length) ||
        (pathname === maintenanceStr && !maintenance.length)
      ) {
        history.push(DEFAULT_PACKAGES_ROUTE);
      }
    }
  }, [maintenance, incidents, pathname]);

  const clearStorage = () => {
    clearLocalStorage();
  };

  useEffect(() => {
    if (!loading && !ssoAuthLoading && error) {
      const { graphQLErrors, networkError } = error;
      // If the request was successful but the JWT is invalid
      const authError = isAuthError(graphQLErrors);
      if (authError) {
        handleAuthError();
      } else if (networkError) {
        // Show generic error meesage
        errorToast('Something went wrong!');
      }
    }

    if (!loading && !error) {
      if (isOnDoDNetwork) {
        // Should update with email
        localStorage.setItem(localStorageKeys.user, instructorProfile?.email);
      }
      if (isPendoEnabled) {
        initializePendo(instructorProfile);
      }
      if (isHeapEnabled) {
        initializeHeap(instructorProfile);
      }
    }
  }, [
    loading,
    error,
    instructorProfile,
    isLoggedIn,
    ssoAuthLoading,
    isAuthenticated,
    pathname,
    handleAuthError,
  ]);

  useEffect(() => {
    const setAuth0AccessToken = async () => {
      const accessToken = await getAccessTokenSilently();

      // If unable to set the access token then redirect to the login page
      if (!trySetAccessToken(accessToken)) {
        history.push(UNAUTHORIZED_DEFAULT_ROUTE);
      }

      // Note: Handle unable to set auth scheme. Defaults to not being able to log in
      trySetClientAuthScheme('sso');

      getProfileStatus();
      setUserStatusLoading(false);
    };

    // Set token
    if (!ssoAuthLoading) {
      if (isAuthenticated) {
        setAuth0AccessToken();
      } else {
        getProfileStatus();
        setUserStatusLoading(false);
      }
    }
  }, [isAuthenticated, getAccessTokenSilently, ssoAuthLoading, getProfileStatus]);

  return (
    <Fragment>
      {userStatusLoading || loading ? (
        <DefaultSpinner />
      ) : (
        <>
          <UpdateInitialInfo profile={instructorProfile} />
          {isOnNewLayout && <NewLayoutWelcome profile={instructorProfile} />}
          <Modal
            onCancel={() => {
              clearStorage();
            }}
            visible={instructorProfile?.school?.is_blocked}
            header={false}
            footer={false}
            width={900}
            destroyOnClose>
            <Text level={4} bold>
              Your account has been disabled. For assistance, please contact&nbsp;
              <a href='mailto:support@learntowin.zendesk.com'>support@learntowin.zendesk.com</a>
            </Text>
          </Modal>
          <Router history={history}>
            <Route
              path='/*'
              component={props => (
                <Suspense fallback={<DefaultSpinner />}>
                  <AppMainNavigation profile={instructorProfile} {...props} />
                </Suspense>
              )}
            />
          </Router>
        </>
      )}
    </Fragment>
  );
};

export default App;
