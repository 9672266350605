import Btn from '@material-ui/core/Button';
import BtnGroup from '@material-ui/core/ButtonGroup';
import MuiThemeProvider from '../MuiThemProvider';

export const Button = ({ whitProvider = true, ...props }) =>
  whitProvider ? (
    <MuiThemeProvider>
      <Btn {...props}>{props.children}</Btn>
    </MuiThemeProvider>
  ) : (
    <Btn {...props}>{props.children}</Btn>
  );

export const ButtonGroup = props => (
  <MuiThemeProvider>
    <BtnGroup {...props}>{props.children}</BtnGroup>
  </MuiThemeProvider>
);
