import { useCallback } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { debounce } from 'lodash';

const useStyles = makeStyles({
  root: {
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Muli, Roboto, sans-serif',
    },
    '& .MuiFormLabel-root': {
      fontFamily: 'Muli, sans-serif',
      color: '#707280',
      transform: 'translate(20px, 18px) scale(1)',
      wordBreak: 'keep-all',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: '#707280',
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#707280',
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#EA2139',
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
      marginRight: 0,
    },
    '& .Mui-disabled.MuiInputBase-root': {
      backgroundColor: '#E3E3E3',
      cursor: 'not-allowed',
    },
  },
  input: {
    padding: '16px 19px',
    fontFamily: 'Muli, sans-serif',
    color: '#11142C',
    background: '#fff',
    '&.Mui-disabled': {
      backgroundColor: '#E3E3E3',
      cursor: 'not-allowed',
    },
  },
  inputRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #E0E0E0',
    },
    '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      border: '1px solid #11142C',
    },
    '&.Mui-focused.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
      border: '1px solid #EA2139',
    },
  },
});

export const OutlinedInput = ({
  InputProps = {},
  onChange = () => ({}),
  testId = '',
  ...props
}) => {
  const classes = useStyles();

  // to fix performance of the component
  const handleChange = useCallback(debounce(onChange, 50), [onChange]);

  const inputChangeCallback = useCallback(
    e => {
      if (typeof props.value !== 'undefined') {
        onChange(e);
      } else {
        e.persist();
        handleChange(e);
      }
    },
    [handleChange, props.value],
  );

  return (
    <TextField
      InputProps={{
        classes: {
          root: classes.inputRoot,
          input: classes.input,
          focused: classes.focused,
          notchedOutline: classes.notchedOutline,
        },
        endAdornment: props.endAdornment,
        startAdornment: props.startAdornment,
        ...InputProps,
      }}
      inputProps={{
        'data-testid': testId,
      }}
      classes={{
        root: classes.root,
      }}
      margin='none'
      variant='outlined'
      type='text'
      fullWidth
      onChange={inputChangeCallback}
      {...props}
    />
  );
};

OutlinedInput.muiName = TextField.muiName;

const useUnderlinedStyles = makeStyles({
  root: {
    '& MuiFormControl-root .Mui-InputBase-input': {
      padding: '13px 12px',
    },
  },
  input: {
    padding: '12px 12px',
    color: '#11142C',
    fontFamily: 'Muli, Roboto, sans-serif',
  },
  inputRoot: {
    height: '32px',
    '&:before': {
      borderColor: '#707280',
    },
    '&:after': {
      borderColor: '#11142C',
      borderBottomWidth: '1px',
    },
    '& .MuiInputAdornment-root': {
      margin: 0,
    },
  },
});

export const UnderlinedInput = ({ endAdornment, startAdornment, testId = '', ...props }) => {
  const classes = useUnderlinedStyles();
  return (
    <TextField
      InputProps={{
        classes: {
          root: classes.inputRoot,
          input: classes.input,
        },
        endAdornment: endAdornment,
        startAdornment: startAdornment,
      }}
      inputProps={{
        'data-testid': testId,
      }}
      classes={{
        root: classes.root,
      }}
      margin='none'
      variant='standard'
      type='text'
      fullWidth
      {...props}
    />
  );
};
