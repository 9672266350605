import { createBrowserHistory } from 'history';
import { isOnDoDNetwork, ROUTE_BASE_NAME } from './Environment';

const historyConfig = {};

if (isOnDoDNetwork) {
  historyConfig.basename = ROUTE_BASE_NAME;
}

const history = createBrowserHistory(historyConfig);

// https://stackoverflow.com/questions/44153517/how-to-access-history-object-outside-of-a-react-component
export default history;
