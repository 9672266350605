import { createSelector } from 'reselect';
import createLoadingSelector from '../create-loading-selector';
import createErrorSelector from '../create-error-selector';
import {
  getUsersAction,
  resendPasswordAction,
  terminateTokenAction,
  inviteUsersAction,
  editUserAction,
  removeUsersAction,
  removeCoachesAction,
  getSingleUser,
  getGeneratedPassword,
} from '../../actions/users.actions';

const usersState = state => state.users;

export const UsersSelector = createSelector(usersState, state => state.users);
export const singleUser = createSelector(usersState, state => state.user);
export const countSelector = createSelector(usersState, state => state.dataCount);
export const generatedPasswordSelector = createSelector(
  usersState,
  state => state.generatedPassword,
);

export const getUsersLoading = createLoadingSelector(getUsersAction.actionName);
export const resendPasswordLoading = createLoadingSelector(resendPasswordAction.actionName);
export const getSingleUserLoading = createLoadingSelector(getSingleUser.actionName);
export const terminateTokenLoading = createLoadingSelector(terminateTokenAction?.actionName);

export const inviteUsersLoading = createLoadingSelector(inviteUsersAction.actionName);
export const inviteUsersError = createErrorSelector(inviteUsersAction.actionName);

export const EditUserLoading = createLoadingSelector(editUserAction.actionName);
export const editUserError = createErrorSelector(editUserAction.actionName);

export const removeUsersLoading = createLoadingSelector(removeUsersAction.actionName);
export const removeUsersError = createErrorSelector(removeUsersAction.actionName);

export const removeCoachesLoading = createLoadingSelector(removeCoachesAction.actionName);
export const removeCoachesError = createErrorSelector(removeCoachesAction.actionName);

export const generatedPasswordLoading = createLoadingSelector(getGeneratedPassword.actionName);
