import { useState, useEffect } from 'react';

import video from '../../assets/videos/newlayout.mp4';
import { nonRemovableLocalStorageKeys } from '../../constants/localStorageKeys';
import Modal from '../NewUi/Modal';
import './styles.less';

const title = 'Welcome to Learn to Win';
const subTitle =
  'Learn to Win unlocks untapped expertise in every organization. Watch this video to get a tour of our platform and the features we offer.';
export const NewLayoutWelcome = ({ profile = {} }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (profile?.id && !profile?.is_password_temp) {
      const isNewLayoutSeen =
        localStorage.getItem(nonRemovableLocalStorageKeys.isNewLayoutSeen) === 'yes';
      !isNewLayoutSeen && setShowModal(true);
    }
  }, [profile?.id, profile?.is_password_temp]);

  const onClose = () => {
    setShowModal(false);
    localStorage.setItem(nonRemovableLocalStorageKeys.isNewLayoutSeen, 'yes');
  };

  return (
    <Modal
      centered
      data-testid='welcome-modal'
      okText={'Close'}
      onOk={onClose}
      onCancel={onClose}
      size='lg'
      subTitle={subTitle}
      title={title}
      visible={showModal}>
      <video src={video} className='welcome-video' controls controlsList='nodownload' />
    </Modal>
  );
};

export default NewLayoutWelcome;
