import produce from 'immer';

import { setRequestStateAction } from '../../actions/httpState.actions';

export const defaultState = {
  data: {},
};

const httpState = produce((state, action) => {
  switch (action.type) {
    case setRequestStateAction.actionName:
      state.data = action.params.data;
      break;
    default:
      break;
  }
}, defaultState);

export default httpState;
