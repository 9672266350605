import {
  signInAction,
  getResetPasswordTokenAction,
  resetCoachPasswordAction,
} from '../actions/auth.actions';
import {
  signInApi,
  getResetPasswordTokenApi,
  resetCoachPasswordApi,
} from '../services/auth.service';

export const watchSignIn = {
  ...signInAction,
  api: signInApi,
};

export const watchGetResetToken = {
  ...getResetPasswordTokenAction,
  api: getResetPasswordTokenApi,
};

export const watchResetCoachPassword = {
  ...resetCoachPasswordAction,
  api: resetCoachPasswordApi,
};
