import cc from 'classcat';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Text = ({
  level,
  weight,
  italic,
  color,
  className,
  children,
  uppercase,
  truncate,
  ...props
}) => {
  return (
    <span
      style={{ fontWeight: weight }}
      className={cc([
        styles.text,
        styles[`text--size-${level}`],
        className,
        color !== 'currentColor' && color,
        {
          [styles.uppercase]: uppercase,
          [styles.truncate]: truncate,
          [styles.italic]: italic,
        },
      ])}
      {...props}>
      {children}
    </span>
  );
};

export const TextLato = ({ className, ...props }) => (
  <Text className={cc([styles.font_lato, className])} {...props} />
);

export const TextMerriWeather = ({ className, ...props }) => (
  <Text className={cc([styles.font_merriweather, className])} {...props} />
);

Text.Lato = TextLato;
Text.MerriWeather = TextMerriWeather;

Text.defaultProps = {
  color: 'dark-blue-1',
  weight: 'normal',
  italic: false,
  level: 1,
  uppercase: false,
};

Text.propTypes = {
  level: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]).isRequired,
  italic: PropTypes.bool,
  uppercase: PropTypes.bool,
  weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.oneOf([
    'blue',
    'currentColor',
    'dark-blue-1',
    'dark-blue-2',
    'dark-purple',
    'gray-1',
    'gray-2',
    'green',
    'light-blue-1',
    'light-blue-2',
    'light-green',
    'light-red',
    'light-yellow',
    'lime-green',
    'peach',
    'pink',
    'pure-yellow',
    'purple',
    'red',
    'teal',
    'white',
    'yellow',
    'black',
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Text;
