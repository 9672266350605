import { makeRequestAction } from './action-generator';

export const getIncidentsListAction = makeRequestAction('GET_INCIDENTS_LIST', {
  onSuccess(params, response) {
    return {
      incidents: response,
    };
  },
});

export const getMaintenanceListAction = makeRequestAction('GET_MAINTENANCE_LIST', {
  onSuccess(params, response) {
    return {
      maintenance: response,
    };
  },
});
