import { trySetAccessToken } from '../../utils/localStorage';

export const pullContext = (forward, operation) => {
  return forward(operation).map(response => {
    const {
      response: { headers = {} },
    } = operation?.getContext();
    trySetAccessToken(headers?.get('Authorization'));
    return response;
  });
};
