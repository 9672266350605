import cc from 'classcat';
import PropTypes from 'prop-types';
import { Modal as AntModal } from 'antd';
import Spinner from '../../Shared/Spinner';
import Title from '../Title';
import Text from '../Text';
import { Close } from '../Icons';
import Button from '../Button';
import styles from './styles.module.css';

const Modal = ({
  visible,
  onOk,
  onCancel,
  size,
  footer,
  title,
  subTitle,
  children,
  okText,
  cancelText,
  cancelDisabled,
  okDisabled,
  isNode,
  customHeader,
  isLoading,
  ...props
}) => (
  <AntModal
    wrapProps={{
      'data-testid': props['data-testid'],
    }}
    closable={false}
    width='none'
    footer={false}
    bodyStyle={{ padding: 0 }}
    centered
    destroyOnClose={true}
    onCancel={onCancel}
    visible={visible}
    {...props}>
    <Spinner spinning={isLoading} isLocal>
      <div
        className={cc([
          styles.modal,
          {
            [styles.sm]: size === 'sm',
            [styles.md]: size === 'md',
            [styles.lg]: size === 'lg',
          },
        ])}>
        <div className={styles.header}>
          <Close data-testid='modal-close-button' className={styles.close} onClick={onCancel} />
          {isNode ? (
            customHeader
          ) : (
            <Title className={styles.title} level={5} weight='bold'>
              {title}
            </Title>
          )}

          {subTitle && (
            <Text.Lato level={3} color='gray-1' className={styles.description}>
              {subTitle}
            </Text.Lato>
          )}
        </div>

        <div className={styles.body}>{children}</div>

        {footer && (
          <div className={styles.footer}>
            {cancelText && (
              <Button
                disabled={cancelDisabled}
                type='outline'
                className={styles.cancel}
                onClick={onCancel}>
                {cancelText}
              </Button>
            )}
            {okText && (
              <Button disabled={okDisabled} onClick={onOk}>
                {okText}
              </Button>
            )}
          </div>
        )}
      </div>
    </Spinner>
  </AntModal>
);

Modal.propTypes = {
  visible: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  footer: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  okDisabled: PropTypes.bool,
  isNode: PropTypes.bool,
  customHeader: PropTypes.node,
  cancelDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

Modal.defaultProps = {
  subTitle: '',
  visible: false,
  size: 'sm',
  title: '',
  footer: true,
  onCancel: () => ({}), // Default no-op handler
  onOk: () => ({}), // Default no-op handler
  okText: '',
  cancelText: '',
  okDisabled: false,
  isNode: false,
  customHeader: null,
  cancelDisabled: false,
  isLoading: false,
};

export default Modal;
