import axios from 'axios';
import { getAuthScheme, url, token, isOnDoDNetwork } from '../../Environment';
import { getSourceByUrl } from '../../api/client';
import { responseChecker } from '../../utils';
import store from '../../store';
import { trySetAccessToken } from '../../utils/localStorage';

import { setRequestStateAction } from '../../actions/httpState.actions';
import { checkMigrationStatus } from '../../utils/index';

const headers = {
  Accept: 'application/x.l2w.v1+json',
  'Cache-Control': 'no-store, no-cache, must-revalidate',
  Pragma: 'no-cache',
};

export const getClient = (isV2 = false) => {
  const baseURL = isV2 ? url?.replace('coach', 'instructor') : url;
  const client = axios.create({
    baseURL,
    headers,
    validateStatus: () => true,
  });

  client.interceptors.request.use(config => {
    const authToken = !isOnDoDNetwork && token() ? 'Bearer ' + token() : null;
    config.headers['Authorization'] = authToken;
    config.headers['Source-Flow'] = getSourceByUrl();
    config.headers['L2W-Client-Auth-Scheme'] = getAuthScheme();
    config.headers['L2W-Client-User-Type'] = 'instructor';
    return config;
  });

  client.interceptors.response.use(
    response => {
      checkMigrationStatus(response);
      trySetAccessToken(response?.config?.Authorization);
      store.dispatch(
        setRequestStateAction.request({
          data: response.data,
        }),
      );

      return response;
    },
    error => {
      checkMigrationStatus(error.response);
      responseChecker(error.response);
      store.dispatch(
        setRequestStateAction.request({
          data: error.response.data,
        }),
      );
      return Promise.reject(error);
    },
  );

  return client;
};

export const clientV2 = getClient(true);

export default getClient();
