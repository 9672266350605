export const localStorageKeys = {
  contentWriterCoachId: 'content_writer_coach_id',
  isContentWriter: 'is_content_writer',
  isSideBarOpen: 'IS_SIDEBAR_OPEN',
  isSysOutageNoteSeen: 'IS_SYS_OUTAGE_NOTE_SEEN',
  leaderBoardFilters: 'leader-board-filters',
  lessonsFilters: 'lessons-filters',
  quizFilters: 'quiz-filters',
  s3BucketName: 's3_bucket_name',
  s3Region: 's3_region',
  schoolName: 'school_name',
  athenaMigrationInProgress: 'athena_migration_in_progress',
  teamAbbreviation: 'team_abbreviation',
  user: 'user',
  userEmail: 'user_email',
  userId: 'user_id',
  userName: 'user_name',
  authScheme: 'auth_scheme',
};

// Local storage keys that we don't want to remove when user logs out
export const nonRemovableLocalStorageKeys = {
  isConfidenceCheckSeen: 'IS_CONFIDENCE_CHECK_SEEN',
  isCustomFeedbackNoteSeen: 'IS_CUSTOM_FEEDBACK_NOTE_SEEN',
  isDrawingResponseSeen: 'IS_DRAWING_RESPONSE_SEEN',
  isFreeResponseUploadSeen: 'IS_FREE_RESPONSE_UPLOAD_SEEN',
  isMainNewFeatureNoteSeen: 'IS_MAIN_NEW_FEATURE_NOTE_SEEN',
  isNewLayoutSeen: 'IS_NEW_LAYOUT_SEEN',
  isRapidGradingNoteSeen: 'IS_RAPID_GRADING_NOTE_SEEN',
  isSetDateModalNoteSeen: 'IS_SET_DATE_MODAL_NOTE_SEEN',
  onboarding: 'onboarding',
};
