import { buildArrayFilter } from '../../utils/filter.helper';
import client from '../client';

export const getLeaderBoardApi = ({ params }, cancelToken) => {
  const { page, ...otherParams } = params;
  let url = `/analytics-leaderboard`;
  if (page) {
    url = `${url}/${page}`;
  }
  return client.get(url, { params: otherParams, cancelToken });
};

export const getLeaderBoardFiltersApi = ({ params }, cancelToken) =>
  client.get('/analytics-leaderboard/filters', { params, cancelToken });

export const getQuizzesApi = ({ params }, cancelToken) => {
  const { page } = params;
  delete params.page;
  return client.get(`/analytics-quizzes/${page}`, { params, cancelToken });
};

export const getQuizFiltersApi = ({ params }, cancelToken) =>
  client.get('/analytics-quizzes/filters', { params, cancelToken });

export const getQuizDetailsApi = ({ params }, cancelToken) => {
  const { id, page, attempt, groupIds = [] } = params;
  let queryStr = `/quiz-slide-analytics/${id}/${page}`;
  if (groupIds?.length) {
    queryStr += buildArrayFilter(groupIds, 'userGroups');
  }
  return client.get(queryStr, {
    params: {
      attempt: attempt,
    },
    cancelToken,
  });
};

export const gradeUserAnswerApi = ({ params }, cancelToken) =>
  client.post(`/grade-free-response`, params, { cancelToken });

export const getQuizAllAnswersApi = ({ params }, cancelToken) => {
  const { id, page, attempt, groupIds = [] } = params;

  let queryStr = `/analytics-quizzes/all-answers/${id}/${page}`;
  if (groupIds?.length) {
    queryStr += buildArrayFilter(groupIds, 'userGroups');
  }

  return client.get(queryStr, {
    params: {
      attempt,
    },
    cancelToken,
  });
};

export const getQuizSlidesInfoApi = ({ params }, cancelToken) => {
  const { id, attempt, groupIds = [] } = params;

  let queryStr = `/quiz-slides-info/${id}`;
  if (groupIds?.length) {
    queryStr += buildArrayFilter(groupIds, 'userGroups');
  }

  return client.get(queryStr, {
    params: {
      attempt,
    },
    cancelToken,
  });
};

export const getLessonsApi = ({ params }, cancelToken) => {
  const { page, ...otherParams } = params;
  return client.get(`/analytics-lessons/${page}`, { params: otherParams, cancelToken });
};

export const getLessonFiltersApi = ({ params }, cancelToken) =>
  client.get('/analytics-lessons/filters', { params, cancelToken });

export const getLessonUsersApi = ({ params }, cancelToken) => {
  const { id, ...otherParams } = params;
  return client.get(`/analytics-lesson-users/${id}`, { params: otherParams, cancelToken });
};

export const getQuizUsersApi = ({ params }, cancelToken) => {
  const { id, ...otherParams } = params;
  return client.get(`/analytics-quiz-users/${id}`, { params: otherParams, cancelToken });
};

export const notifyLessonUsersApi = ({ params }, cancelToken) => {
  const { id } = params;
  return client.post(`/notify-lesson-users/${id}`, params, { cancelToken });
};

export const notifyQuizUsersApi = ({ params }, cancelToken) => {
  const { id } = params;
  return client.post(`/notify-quiz-users/${id}`, params, { cancelToken });
};
