import client from '../client';

export const getUsersApi = ({ params }, cancelToken) => {
  return client.get('/users', {
    params: {
      search: params.search,
      type: params.type,
      page: params.page,
      order_by: params.order_by,
      order_direction: params.order_direction,
    },
    cancelToken,
  });
};

export const resendPasswordApi = ({ params }, cancelToken) => {
  return client.post(
    '/resend-password',
    {
      email: params.email,
      phone: params.phone,
      userType: params.userType,
    },
    { cancelToken },
  );
};

export const terminateTokenApi = ({ params }, cancelToken) => {
  return client.get('/terminate-token', { cancelToken });
};

export const getSingleUserApi = ({ params }, cancelToken) => {
  return client.get(`/get-user`, {
    params: {
      id: params.id,
      type: params.type,
    },
    cancelToken,
  });
};

export const inviteUserApi = ({ params }, cancelToken) =>
  client.post('/add-users', params, { cancelToken });

export const editUserApi = ({ params }, cancelToken) =>
  client.post(`/update-user/${params.id}`, params, { cancelToken });

export const removeUsersApi = ({ params }, cancelToken) =>
  client.delete(`/delete-user/${params.id}`, { cancelToken });

export const removeCoachesApi = ({ params }, cancelToken) =>
  client.delete(`/delete-coach/${params.id}`, { cancelToken });

export const getGeneratedPasswordApi = ({ params }, cancelToken) =>
  client.get('/generate-password', { cancelToken });

export const resetPasswordAPi = ({ params }, cancelToken) => {
  return client.post(
    '/set-new-password',
    {
      password: params.password,
      email: params.email,
    },
    { cancelToken },
  );
};
