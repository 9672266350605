import { all, fork } from 'redux-saga/effects';
import { makeSaga } from './makeSaga';
import * as courseSagas from './course.saga';
import * as contentBank from './contentBank.sagas';
import * as users from './users.sagas';
import * as positionGroups from './userGroups.sagas';
import * as packages from './packages.sagas';
import * as app from './app.sagas';
import * as analytics from './analytics.sagas';
import * as utility from './utility.sagas';
import * as forgotPassword from './forgotPassword.sagas';
import * as support from './support.sagas';
import * as profile from './profile.sagas';
import * as auth from './auth.sagas';
import * as packagesV2 from './V2/packages.sagas';
import * as contentBankSagas from './V2/content-bank.sagas';

const combinedSagas = {
  // put all sagas here
  ...courseSagas,
  ...contentBank,
  ...users,
  ...positionGroups,
  ...packages,
  ...app,
  ...analytics,
  ...utility,
  ...forgotPassword,
  ...support,
  ...profile,
  ...auth,
  ...packagesV2,
  ...contentBankSagas,
};

export default function* rootSaga() {
  yield all(Object.values(combinedSagas).map(i => fork(makeSaga(i))));
}
