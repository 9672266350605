import history from '../history';
import { makeRequestAction } from './action-generator';
import graph from '../api/graphql';
import { errorToast, successToast } from '../components/Ui/Notification';
import { isOnDoDNetwork } from '../Environment';
import { localStorageKeys } from '../constants/localStorageKeys';
import { clearLocalStorage } from '../utils/localStorage';

export const getProfileAction = makeRequestAction('GET_PROFILE', {
  onSuccess(params, response) {
    params.onSuccess?.();

    localStorage.setItem(localStorageKeys.userName, response?.data?.result.name);
    sessionStorage.setItem(localStorageKeys.s3Region, response?.data?.result.s3_region);
    sessionStorage.setItem(localStorageKeys.s3BucketName, response?.data?.result.s3_bucket_name);
    // On DoD networks a successful profile fetch indicates the user has been authenticated
    if (isOnDoDNetwork) {
      localStorage.setItem(localStorageKeys.user, response?.data?.result?.name);
    }

    return {
      coachDetail: response?.data?.result,
      code: response?.code,
    };
  },
  onFailure(params, response) {
    params.onFailure?.();
    if (Boolean(localStorage.getItem('user'))) {
      errorToast('Auth Failed!', 'Please, sign in again!');
      clearLocalStorage();
    }
    history.push('/sign-in');
    return {
      message: 'Something went wrong!',
      code: response?.data?.code,
    };
  },
});

export const updateProfileAction = makeRequestAction('UPDATE_PROFILE', {
  onSuccess(params, response) {
    if (params.onSuccess) {
      params.onSuccess();
    }
    // refetching to get new result for profile
    // needed until this update is done through GQL
    graph.refetchQueries({
      include: 'active',
    });
    successToast(response.message);
    return {
      code: response?.code,
    };
  },
  onFailure(params, response) {
    if (params.onFailure) {
      params.onFailure();
    }
    return {
      message: response?.data?.message,
      code: response?.data?.code,
    };
  },
});

export const initialLoginAction = makeRequestAction('INITIAL_LOGIN', {
  onSuccess(params, response) {
    if (params.onSuccess) {
      params.onSuccess();
    }
    return {
      code: response?.code,
    };
  },
  onFailure({ onFailure }, response) {
    if (onFailure) {
      onFailure();
    }
    return {
      message: response?.data?.message,
      code: response?.data?.code,
    };
  },
});

export const passwordCheckAction = makeRequestAction('PASSWORD_CHECK', {
  onSuccess(params, response) {
    if (params.onSuccess) {
      params.onSuccess(response);
    }
    return {
      code: response?.code,
    };
  },
  onFailure({ onFailure }, response) {
    if (onFailure) {
      onFailure(response?.error?.message);
    }
    return {
      message: response?.data?.message,
      code: response?.data?.code,
    };
  },
});
