import produce from 'immer';
import {
  getPackagesInfoAction,
  updatePackageAction,
  movePackageIntoFolderAction,
  addFolderAction,
  addPackageAction,
  resetCurrentPackageInfo,
  getPackagesFoldersAction,
  getFolderInfoAction,
  updateLessonTypeAction,
  updateQuizTypeAction,
  getPermissionGroupsAction,
  updateQuizAction,
  updateLessonAction,
} from '../../actions/packages.actions';

export const defaultState = {
  foldersList: [],
  currentPackage: null,
  packagesWithoutFolders: [],
  folders: [],
  packages: [],
  folderName: 'Unnamed Folder',
  permissionGroups: [],
};

const packages = produce((state, action) => {
  switch (action.type) {
    case getPackagesInfoAction.requestTypes.SUCCESS: {
      state.foldersList = action.folders || [];
      state.currentPackage = action.currentPackage || null;
      state.packagesWithoutFolders = action.packagesWithoutFolders || [];
      break;
    }
    case getPackagesFoldersAction.requestTypes.SUCCESS: {
      state.folders = action.folders || [];
      state.packages = action.pcks || [];
      break;
    }
    case updateQuizTypeAction.requestTypes.SUCCESS:
    case updateLessonTypeAction.requestTypes.SUCCESS: {
      state.currentPackage = {
        ...state.currentPackage,
        ...action.data,
      };
      break;
    }
    case updateQuizAction.requestTypes.SUCCESS:
    case updateLessonAction.requestTypes.SUCCESS: {
      state.currentPackage = {
        ...state.currentPackage,
        ...action.package,
      };
      break;
    }
    case getFolderInfoAction.requestTypes.SUCCESS: {
      state.folders = action.folders || [];
      state.packages = action.pcks || [];
      state.folderName = action.folderName;
      break;
    }
    case getPermissionGroupsAction.requestTypes.SUCCESS: {
      const groups = action.groups.map(gr => ({
        id: gr.id,
        name: gr.name,
        type: 'group',
      }));
      const users = action.users.map(u => ({
        id: u.id,
        name: u.name || u.email,
        type: 'player',
      }));
      state.permissionGroups = [...groups, ...users];
      break;
    }
    case updatePackageAction.requestTypes.SUCCESS: {
      state.currentPackage = action.data.course;
      state.foldersList = action.data.allCourses;
      state.packagesWithoutFolders = action.data.courses_without_folders;
      break;
    }
    case movePackageIntoFolderAction.actionName: {
      state.currentPackage.course_folder_id = action.params.folderId;
      break;
    }
    case addFolderAction.requestTypes.SUCCESS: {
      state.foldersList.unshift({
        id: action.data.id,
        title: action.data.title,
        courses: [],
      });
      break;
    }
    case resetCurrentPackageInfo.actionName: {
      state.foldersList = [];
      state.currentPackage = null;
      state.packagesWithoutFolders = [];
      break;
    }
  }
}, defaultState);

export default packages;
