import { useState } from 'react';
import { usePopper } from 'react-popper';

const usePopover = ({ placement, offsetY, ...props }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: ['top', 'bottom'], // by default, all the placements are allowed
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, offsetY],
        },
      },
    ],
    ...props,
  });

  return {
    target: {
      ref: setReferenceElement,
    },
    body: {
      ref: setPopperElement,
      style: styles.popper,
      ...attributes.popper,
    },
    getBodyElement: { current: popperElement },
    refreshPopover: update,
  };
};

export { usePopover };
