import produce from 'immer';

import {
  getPositionGroupsAction,
  getGroupUsersAction,
  createPositionGroupsAction,
  editPositionGroupsAction,
  deletePositionGroupAction,
} from '../../actions/userGroups.actions';

export const defaultState = {
  groups: [],
  groupUsers: [],
  error: null,
};

const groups = produce((state, action) => {
  switch (action.type) {
    case editPositionGroupsAction.requestTypes.SUCCESS:
    case createPositionGroupsAction.requestTypes.SUCCESS:
    case deletePositionGroupAction.requestTypes.SUCCESS:
      state.groups = action.groups || [];
      break;
    case getPositionGroupsAction.requestTypes.SUCCESS:
      state.groups = Array.isArray(action.groups)
        ? {
            groups: [],
            total_count: 0,
          }
        : action.groups;
      break;
    case getGroupUsersAction.requestTypes.SUCCESS:
      state.groupUsers = action.groupUsers || [];
      break;
    case createPositionGroupsAction.requestTypes.FAILURE:
      state.error = action.error;
      break;
  }
}, defaultState);

export default groups;
