import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

export default ({ children }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#11142c',
      },
      secondary: {
        main: '#EA2139',
      },
    },
  });

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};
