import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  addContentFileAction,
  addPdfPptContentAction,
  createContentFolderAction,
  deleteContentFilesAction,
  deleteContentFolderAction,
  getContentFolderByIdAction,
  getContentFoldersAction,
  getContentFoldersTreeAction,
  moveContentFilesAction,
  moveContentFolderAction,
  reorderContentFilesAction,
  reorderContentFoldersAction,
  updateContentFileAction,
  updateContentFolderAction,
} from '../../../actions/V2/content-bank.actions';
import { errorToast } from '../../../components/Ui/Notification';
import {
  addContentError,
  addPresentationError,
  currentContentFolderSelector,
  getContentFoldersError,
} from '../../../selectors/V2/content-bank.selectors';
import {
  addContentFileApi,
  addPdfPptContentApi,
  createContentFolderApi,
  deleteContentFilesApi,
  deleteContentFolderApi,
  getContentFolderByIdApi,
  getContentFoldersApi,
  getContentFoldersTreeApi,
  moveContentFilesApi,
  moveContentFolderApi,
  reorderContentFilesApi,
  reorderContentFoldersApi,
  updateContentFileApi,
  updateContentFolderApi,
} from '../../../services/V2/content-bank.service';

export const watchGetContentFolders = {
  ...getContentFoldersAction,
  api: getContentFoldersApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(getContentFoldersError);
    if (err) {
      errorToast('Error', 'Failed to load Content Folders!');
    }
  },
};

export const watchGetContentFoldersTree = {
  ...getContentFoldersTreeAction,
  api: getContentFoldersTreeApi,
};

export const watchGetContentFolderById = {
  ...getContentFolderByIdAction,
  api: getContentFolderByIdApi,
};

export const watchCreateContentFolder = {
  ...createContentFolderAction,
  api: createContentFolderApi,
};

export const watchUpdateContentFolder = {
  ...updateContentFolderAction,
  api: updateContentFolderApi,
  toast: {
    success: {
      title: 'Folder Updated Successfully!',
    },
  },
};

export const watchMoveContentFolder = {
  ...moveContentFolderAction,
  api: moveContentFolderApi,
};

export const watchReorderContentFolders = {
  ...reorderContentFoldersAction,
  api: reorderContentFoldersApi,
};

export const watchDeleteContentFolder = {
  ...deleteContentFolderAction,
  api: deleteContentFolderApi,
};

export const watchAddContentFile = {
  ...addContentFileAction,
  api: addContentFileApi,
  take: takeEvery,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(addContentError);
    if (!err) {
      const currentFolder = yield select(currentContentFolderSelector);
      if (currentFolder?.id) {
        yield put(getContentFolderByIdAction.request({ folderId: currentFolder.id }));
      } else {
        yield put(getContentFoldersAction.request());
      }
    }
  },
};

export const watchAddPdfPptContent = {
  ...addPdfPptContentAction,
  api: addPdfPptContentApi,
  take: takeEvery,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(addPresentationError);
    if (!err) {
      const currentFolder = yield select(currentContentFolderSelector);
      if (currentFolder?.id) {
        yield put(getContentFolderByIdAction.request({ folderId: currentFolder.id }));
      } else {
        yield put(getContentFoldersAction.request());
      }
    }
  },
};

export const watchUpdateContentFile = {
  ...updateContentFileAction,
  api: updateContentFileApi,
};

export const watchMoveContentFiles = {
  ...moveContentFilesAction,
  api: moveContentFilesApi,
};

export const watchReorderContentFiles = {
  ...reorderContentFilesAction,
  api: reorderContentFilesApi,
};

export const watchDeleteContentFile = {
  ...deleteContentFilesAction,
  api: deleteContentFilesApi,
};
