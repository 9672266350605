import client from '../client';

export const addPackageApi = ({ params }, cancelToken) => {
  return client.post('/add-course', params, { cancelToken });
};

export const getPackagesInfoApi = ({ params }, cancelToken) => {
  return client.get(`/edit-course/${params.packageId}`, {
    cancelToken,
  });
};

export const updatePackageApi = ({ params }, cancelToken) => {
  const data = new FormData();
  data.append('id', params.id);
  data.append('type', params.type);
  data.append('title', params.title);
  data.append('description', params.description);
  data.append('img', params.img);
  if (params.course_folder_id) {
    data.append('course_folder_id', params.course_folder_id);
  }

  return client.post(`/update-course/${params.id}`, data, { cancelToken });
};

export const movePackageIntoFolderApi = ({ params }, cancelToken) => {
  return client.post(
    `/move-package/${params.packageId}`,
    { move_to: params.folderId },
    { cancelToken },
  );
};

export const updateLessonTypeApi = ({ params }, cancelToken) => {
  return client.post(`/update-lesson-type/${params.id}`, params, { cancelToken });
};

export const addLessonApi = ({ params }, cancelToken) => {
  const data = new FormData();
  if (params.file) {
    data.append('file', params.file);
  }
  data.append('title', params.title);
  data.append('description', params.description);
  data.append('course_id', params.packageId);
  return client.post('/create-lesson', data, {
    cancelToken,
    onUploadProgress: params.onUploadProgress,
  });
};

export const addFolderApi = ({ params }, cancelToken) => {
  return client.post('/add-folder', { title: params.title }, { cancelToken });
};

export const deleteLessonApi = ({ params }, cancelToken) => {
  return client.delete(`/delete-lesson/${params.lessonId}`, { cancelToken });
};

export const updateLessonOrderApi = ({ params }, cancelToken) => {
  return client.post(`/update-lesson-order/${params.course_id}`, params, { cancelToken });
};

export const addQuizApi = ({ params }, cancelToken) => {
  return client.post('/add-quiz', params, { cancelToken });
};

export const updateQuizOrderApi = ({ params }, cancelToken) => {
  return client.post(`/update-quiz-order/${params.course_id}`, params, { cancelToken });
};

export const deleteQuizApi = ({ params }, cancelToken) => {
  return client.delete(`/delete-quiz/${params.quizId}`, { cancelToken });
};

export const updateQuizTypeApi = ({ params }, cancelToken) => {
  return client.post(`/update-quiz-type/${params.id}`, params, { cancelToken });
};

export const moveLessonApi = ({ params }, cancelToken) => {
  return client.post('/move-lesson-to-course', params, { cancelToken });
};

export const moveQuizApi = ({ params }, cancelToken) => {
  return client.post('/move-quiz-to-course', params, { cancelToken });
};

export const copyLessonApi = ({ params }, cancelToken) => {
  return client.post('/copy-lesson-to-course', params, { cancelToken });
};

export const copyQuizApi = ({ params }, cancelToken) => {
  return client.post('/copy-quiz-to-course', params, { cancelToken });
};

export const deletePackageApi = ({ params }, cancelToken) => {
  return client.delete(`/delete-course/${params.id}`, { cancelToken });
};

export const deleteFolderMainApi = ({ params }, cancelToken) => {
  return client.delete(`/delete-folder/${params.id}`, { cancelToken });
};

export const getPackagesFoldersApi = ({ params }, cancelToken) => {
  return client.get(`/packages`, {
    cancelToken,
    params: {
      search: params.search,
    },
  });
};

export const getFolderInfoApi = ({ params }, cancelToken) => {
  return client.get(`/courses/${params.folderId}`, {
    cancelToken,
  });
};

export const changeFolderOrderApi = ({ params }, cancelToken) => {
  return client.post('/update-folder-order', params, { cancelToken });
};

export const changePackageOrderApi = ({ params }, cancelToken) => {
  return client.post('/update-course-order', params, { cancelToken });
};

export const addFolderMainApi = ({ params }, cancelToken) => {
  return client.post('/add-folder', params, { cancelToken });
};

export const updateFolderApi = ({ params }, cancelToken) => {
  return client.post(`/update-folder/${params.id}`, params, { cancelToken });
};

export const getPermissionGroupsApi = ({ params }, cancelToken) => {
  return client.get('/user-group-data', {
    cancelToken,
  });
};

export const updateLessonApi = ({ params }, cancelToken) => {
  return client.post(`/update-lesson/${params.id}`, params, { cancelToken });
};

export const updateQuizApi = ({ params }, cancelToken) => {
  return client.post(`/update-quiz/${params.id}`, params, { cancelToken });
};
