import { makeRequestAction } from './action-generator';
import { setAuthorizationData } from '../utils/localStorage';
import history from '../history';
import { errorToast, successToast } from '../components/Ui/Notification';

export const signInAction = makeRequestAction('SIGN_IN', {
  onSuccess(params, response) {
    if (params.onSuccess) {
      params.onSuccess();
    }
    const data = { ...response.user_detail };
    data.access_token = response.access_token;
    setAuthorizationData(data);
  },
  onFailure(params, error) {
    if (params.onFailure) {
      params.onFailure(error?.error?.message);
    }
  },
});

export const getResetPasswordTokenAction = makeRequestAction('GET_RESET_PASSWORD_TOKEN', {
  onSuccess(params, response) {
    if (params.onSuccess) {
      params.onSuccess();
    }
    return {
      token: response.data.token,
    };
  },
  onFailure(params, error) {
    if (params.onFailure) {
      params.onFailure();
    }
    errorToast(error.error.message);
    history.push('/sign-in');
  },
});

export const resetCoachPasswordAction = makeRequestAction('RESET_COACH_PASSWORD', {
  onSuccess(params, response) {
    if (params.onSuccess) {
      params.onSuccess();
    }
    history.push('/sign-in');
    const isError = response?.code >= 400;
    if (isError) {
      errorToast(response?.message);
    } else {
      successToast(response?.message);
    }

    return response;
  },
  onFailure(params, error) {
    if (params.onFailure) {
      params.onFailure();
    }
    errorToast(error?.error?.message);
  },
});
