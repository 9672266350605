import { makeRequestAction } from './action-generator';
import { errorToast } from '../components/Ui/Notification/index';

export const getLeaderBoardAction = makeRequestAction('GET_ANALYTICS_LEADER_BOARD', {
  onSuccess(params, response) {
    return {
      message: response.message,
      code: response.code,
      leaderBoardData: response.data.result.leaderBoardData || [],
      dataCount: response.data.result.totalCount,
    };
  },
});

export const getLeaderBoardFiltersAction = makeRequestAction('GET_ANALYTICS_LEADER_BOARD_FILTERS', {
  onSuccess(params, response) {
    return {
      message: response.message,
      code: response.code,
      leaderBoardFilters: response.data.leaderBoardFilters || [],
    };
  },
});

export const getLeaderBoardForDownloadAction = makeRequestAction(
  'GET_ANALYTICS_LEADER_BOARD_FOR_DOWNLOAD',
  {
    onSuccess(params, response) {
      return {
        message: response.message,
        code: response.code,
        allLeaderBoardData: response.data.result.leaderBoardData || [],
        dataCount: response.data.result.totalCount,
      };
    },
  },
);

export const leaderBoardDownloadCleanup = {
  type: 'LEADERBOARD_ANALYTICS_DOWNLOAD_CLEANUP',
};

export const getQuizzesAction = makeRequestAction('GET_ANALYTICS_QUIZZES', {
  onSuccess(params, response) {
    return {
      message: response.message,
      code: response.code,
      quizzes: response.data.result.quizzes || [],
      dataCount: response.data.result.totalCount,
    };
  },
});

export const getQuizFiltersAction = makeRequestAction('GET_ANALYTICS_QUIZ_FILTERS', {
  onSuccess(params, response) {
    return {
      message: response.message,
      code: response.code,
      quizFilters: response.data.quizFilters || [],
    };
  },
});

export const getQuizDetailsAction = makeRequestAction('GET_QUIZ_DETAILS', {
  onSuccess(params, response) {
    return {
      message: response.message,
      code: response.code,
      quizDetails: response.data.result.quizDetails || [],
      quizId: params.id,
    };
  },
  onRequest(params) {
    return {
      quizId: params.id,
    };
  },
});

export const gradeUserAnswerAction = makeRequestAction('GRADE_USER_ANSWER', {
  onSuccess(params, response) {
    params.onSuccess && params.onSuccess();
    return {
      message: response.message,
      code: response.code,
      quizId: params.quizId,
      id: params.id,
      seeAllAnswers: params.seeAllAnswers,
      customFeedback: params.customFeedback,
    };
  },
  onRequest(params) {
    return {
      quizId: params.quizId,
      id: params.id,
    };
  },
});

export const getQuizAllAnswersAction = makeRequestAction('GET_QUIZ_ALL_ANSWERS', {
  onSuccess(params, response) {
    return {
      message: response.message,
      code: response.code,
      quizAllAnswers: response.data.quizAllAnswers,
    };
  },
});

export const getQuizSlidesInfoAction = makeRequestAction('GET_QUIZ_SLIDES_INFO', {
  onSuccess(params, response) {
    return {
      message: response.message,
      code: response.code,
      quizSlidesInfo: response.data.result,
    };
  },
});

export const getQuizAnswersForDownloadAction = makeRequestAction('GET_QUIZ_ANSWERS_FOR_DOWNLOAD', {
  onSuccess(params, { message, code, data }) {
    return {
      message: message,
      code: code,
      quizAllAnswers: data.quizAllAnswers,
    };
  },
});

export const quizAnswersForDownloadCleanup = {
  type: 'QUIZ_ANSWERS_ANALYTICS_DOWNLOAD_CLEANUP',
};

export const getLessonsAction = makeRequestAction('GET_ANALYTICS_LESSONS', {
  onSuccess(params, response) {
    return {
      message: response.message,
      code: response.code,
      lessons: response.data.result.lessons || [],
      dataCount: response.data.result.totalCount,
    };
  },
});

export const getLessonFiltersAction = makeRequestAction('GET_ANALYTICS_LESSON_FILTERS', {
  onSuccess(params, response) {
    return {
      message: response.message,
      code: response.code,
      lessonFilters: response.data.lessonFilters || [],
    };
  },
});

export const getLessonUsersAction = makeRequestAction('GET_LESSON_USERS', {
  onSuccess(params, response) {
    return {
      message: response.message,
      code: response.code,
      lessonUsers: response.data.result.lessonUsers || [],
      lessonId: params.id,
    };
  },
  onRequest(params) {
    return {
      lessonId: params.id,
    };
  },
});

export const getQuizUsersAction = makeRequestAction('GET_QUIZ_USERS', {
  onSuccess(params, response) {
    return {
      message: response.message,
      code: response.code,
      quizUsers: response.data.result.quizUsers || [],
      quizId: params.id,
    };
  },
  onRequest({ id }) {
    return {
      quizId: id,
    };
  },
});

export const notifyLessonUsersAction = makeRequestAction('NOTIFY_ANALYTICS_LESSON_USERS', {
  onSuccess(params, response) {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure(params, error) {
    return errorToast(error.error.message);
  },
});

export const notifyQuizUsersAction = makeRequestAction('NOTIFY_ANALYTICS_QUIZ_USERS', {
  onSuccess(params, response) {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure(params, error) {
    return errorToast(error.error.message);
  },
});
