import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import {
  env,
  isOnDoDNetwork,
  isOnProduction,
  isOnStaging,
  SENTRY_DSN,
  CURR_COMMIT_ID,
} from '../../Environment';

const SAMPLE_RATE = 0.85;

const SentryInitializer = ({ history, children }) => {
  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {
    if ((isOnProduction || isOnStaging) && !isOnDoDNetwork) {
      if (history && !isInitialized) {
        Sentry.init({
          dsn: SENTRY_DSN,
          integrations: [
            new Integrations.BrowserTracing({
              routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
            }),
          ],
          environment: env,
          release: CURR_COMMIT_ID,
          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          tracesSampleRate: SAMPLE_RATE,
        });
        setInitialized(true);
      }
    }
  }, [isInitialized, history]);

  return children;
};

export default SentryInitializer;
