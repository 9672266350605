import cc from 'classcat';
import PropTypes from 'prop-types';

import './styles.less';

// The default level is 4 = 16px, because
// all modern vendor browsers use 16px for a paragraph
export const Text = ({
  level = 4,
  children,
  className,
  bold,
  italic,
  color,
  block,
  inline,
  truncate,
  ieTruncate,
  breakWord,
  forwardRef,
  underlined,
  ...props
}) => (
  <span
    ref={forwardRef}
    className={cc([
      `paragraph-${level}`,
      {
        'paragraph--ie-truncate': ieTruncate,
        'paragraph--bold': bold,
        'paragraph--italic': italic,
        'paragraph--block': block,
        'paragraph--inline': inline,
        'paragraph--truncate': truncate,
        'paragraph--break-word': breakWord,
        'paragraph--underlined': underlined,
        ...(color && {
          [`paragraph--color--${color}`]: true,
        }),
      },
      className,
    ])}
    {...props}>
    {children}
  </span>
);

Text.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, '1', '2', '3', '4', '5', '6', '7', '8']),
  children: PropTypes.node,
  className: PropTypes.string,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  color: PropTypes.oneOf([
    '',
    'black',
    'dark',
    'grey-1',
    'grey-2',
    'bg-light-blue-1',
    'bg-light-blue-2',
    'blue',
    'light-blue',
    'cyan-green',
    'green',
    'light-green',
    'red',
    'light-red',
    'yellow',
    'light-yellow',
    'white',
    'blue-grey',
    'inherit',
    'dark-blue',
    'orange',
  ]),
  block: PropTypes.bool,
  inline: PropTypes.bool,
  truncate: PropTypes.bool,
  ieTruncate: PropTypes.bool,
  breakWord: PropTypes.bool,
};

Text.defaultProps = {
  level: 4,
  children: null,
  className: '',
  bold: false,
  italic: false,
  color: '',
  block: false,
  inline: false,
  truncate: false,
  ieTruncate: false,
  breakWord: true,
  underlined: false,
};

export default Text;
