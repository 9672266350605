import cc from 'classcat';
import PropTypes from 'prop-types';

import './styles.less';

export const Title = ({
  level,
  children,
  bold,
  italic,
  className,
  color,
  noMargin,
  align,
  ieTruncate,
  breakWord,
  underlined,
  ...props
}) => {
  const Element = `h${level}`;

  return (
    <Element
      className={cc([
        `headline-${level}`,
        className,
        {
          'headline--bold': bold,
          'headline--italic': italic,
          'headline--no-margin': noMargin,
          'headline--ie-truncate': ieTruncate,
          'paragraph--break-word': breakWord,
          'paragraph--underlined': underlined,
          ...(color && {
            [`headline--color--${color}`]: true,
          }),
          ...(align && {
            [`headline--align--${align}`]: true,
          }),
        },
      ])}
      {...props}>
      {children}
    </Element>
  );
};

Title.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6, '1', '2', '3', '4', '5', '6']).isRequired,
  children: PropTypes.node,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    '',
    'black',
    'dark',
    'grey-1',
    'grey-2',
    'bg-light-blue-1',
    'bg-light-blue-2',
    'blue',
    'light-blue',
    'cyan-green',
    'green',
    'light-green',
    'red',
    'light-red',
    'yellow',
    'light-yellow',
    'white',
    'blue-grey',
    'inherit',
  ]),
  noMargin: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
  ieTruncate: PropTypes.bool,
  breakWord: PropTypes.bool,
};

Title.defaultProps = {
  children: null,
  bold: false,
  italic: false,
  className: '',
  color: '',
  noMargin: true,
  ieTruncate: false,
  breakWord: true,
  underlined: false,
};

export default Title;
