import produce from 'immer';
import {
  getFolderGroupsAction,
  getPackagesAction,
  getPackageAction,
} from '../../../actions/V2/packages.actions';

export const defaultState = {
  folderGroups: [],
  packages: [],
  package: {},
};

const packagesState = produce((state, action) => {
  switch (action.type) {
    case getFolderGroupsAction.requestTypes.SUCCESS:
      state.folderGroups = action.data.folderGroups;
      break;
    case getPackagesAction.requestTypes.SUCCESS:
      state.packages = action.data.result.packages;
      state.folderGroups = action.data.result.package_folder_groups;
      break;
    case getPackageAction.requestTypes.SUCCESS:
      state.package = action.data.package;
      break;
    default:
      break;
  }
}, defaultState);

export default packagesState;
