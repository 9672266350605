import { createSelector } from 'reselect';
import createLoadingSelector from '../create-loading-selector';
import createErrorSelector from '../create-error-selector';
import {
  getPositionGroupsAction,
  getGroupUsersAction,
  createPositionGroupsAction,
  editPositionGroupsAction,
  deletePositionGroupAction,
} from '../../actions/userGroups.actions';

const groupsState = state => state.groups;

export const PositionGroupsSelector = createSelector(groupsState, state => state.groups);
export const GroupUsersSelector = createSelector(groupsState, state => state.groupUsers);

export const getUserGroupsLoading = createLoadingSelector(getPositionGroupsAction.actionName);

export const getGroupUsersLoading = createLoadingSelector(getGroupUsersAction.actionName);

export const createPositionGroupsLoading = createLoadingSelector(
  createPositionGroupsAction.actionName,
);
export const createPositionGroupsError = createErrorSelector(createPositionGroupsAction.actionName);

export const editPositionGroupsLoading = createLoadingSelector(editPositionGroupsAction.actionName);
export const editPositionGroupsError = createErrorSelector(editPositionGroupsAction.actionName);

export const deletePositionGroupsLoading = createLoadingSelector(
  deletePositionGroupAction.actionName,
);
export const deletePositionGroupsError = createErrorSelector(deletePositionGroupAction.actionName);
