import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import loading from './loading';
import auth from './auth.reducer';
import profile from './profile.reducer';
import error from './error';
import contentBank from './contentBank.reducer';
import users from './users.reducer';
import groups from './userGroups.reducer';
import packages from './packages.reducer';
import app from './app.reducer';
import analytics from './analytics.reducer';
import utility from './utility.reducer';
import httpState from './httpState.reducer';
import packagesState from './V2/packages.reducer';
import contentBankState from './V2/content-bank.reducer';

const combinedReducers = {
  loading,
  error,
  form: formReducer,
  auth,
  profile,
  contentBank,
  users,
  groups,
  packages,
  app,
  analytics,
  utility,
  httpState,
  packagesState,
  contentBankState,
};

export default combineReducers(combinedReducers);
