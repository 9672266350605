import 'core-js/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ClientContextProvider } from 'react-fetching-library';
import createBackend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { ApolloProvider } from '@apollo/client';

import App from './App';
import Auth0ProviderWithHistory from './auth/auth0ProviderWithHistory';
import store from './store';
import client from './api/client';
import graph from './api/graphql';
import history from './history';

import SentryInitializer from './components/Sentry';
import GoogleAnalytics from './components/GoogleAnalytics';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/font.css';
import './assets/fonts/icomoon/style.less';

const rootEl = document.getElementById('root');

const render = Component => {
  const rootComponent = (
    <SentryInitializer history={history}>
      <Auth0ProviderWithHistory>
        <Provider store={store}>
          <ClientContextProvider client={client}>
            <ApolloProvider client={graph}>
              <DndProvider backend={createBackend}>
                <GoogleAnalytics history={history} />
                <Component />
              </DndProvider>
            </ApolloProvider>
          </ClientContextProvider>
        </Provider>
      </Auth0ProviderWithHistory>
    </SentryInitializer>
  );

  return ReactDOM.render(rootComponent, rootEl);
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
