import { call, select, put } from 'redux-saga/effects';
import {
  addPackageAction,
  getPackagesInfoAction,
  addLessonAction,
  addQuizAction,
  copyLessonAction,
  copyQuizAction,
  deleteLessonAction,
  deleteQuizAction,
  moveLessonAction,
  moveQuizAction,
  updateLessonOrderAction,
  updateLessonTypeAction,
  updatePackageAction,
  updateQuizOrderAction,
  updateQuizTypeAction,
  movePackageIntoFolderAction,
  addFolderAction,
  deletePackageAction,
  getPackagesFoldersAction,
  addPackageMainAction,
  deletePackageMainAction,
  getFolderInfoAction,
  changeFolderOrderAction,
  deleteFolderMainAction,
  addFolderMainAction,
  movePackageIntoFolderMainAction,
  changePackageOrderAction,
  updateFolderAction,
  getPermissionGroupsAction,
  updateLessonAction,
  updateQuizAction,
} from '../../actions/packages.actions';
import { errorToast } from '../../components/Ui/Notification';
import {
  addPackageError,
  changeFolderOrderError,
  changePackageOrderError,
  copyLessonError,
  copyQuizError,
  deleteFolderMainError,
  deleteLessonError,
  deletePackageError,
  deletePackageMainError,
  deleteQuizError,
  getCurrentPackage,
  moveLessonError,
  movePackageIntoFolderError,
  movePackageIntoFolderMainError,
  moveQuizError,
  updateFolderError,
  updateLessonOrderError,
  updateLessonTypeError,
  updatePackageError,
  updateQuizOrderError,
  updateQuizTypeError,
  updateLessonError,
  updateQuizError,
} from '../../selectors/packages.selectors';
import {
  addPackageApi,
  getPackagesInfoApi,
  addLessonApi,
  addQuizApi,
  copyLessonApi,
  copyQuizApi,
  deleteLessonApi,
  deleteQuizApi,
  moveLessonApi,
  moveQuizApi,
  updateLessonOrderApi,
  updateLessonTypeApi,
  updatePackageApi,
  updateQuizOrderApi,
  updateQuizTypeApi,
  movePackageIntoFolderApi,
  addFolderApi,
  deletePackageApi,
  getPackagesFoldersApi,
  getFolderInfoApi,
  changeFolderOrderApi,
  deleteFolderMainApi,
  addFolderMainApi,
  changePackageOrderApi,
  updateFolderApi,
  getPermissionGroupsApi,
  updateLessonApi,
  updateQuizApi,
} from '../../services/packages.service';

const updateErr = 'Update Failed';

export const watchAddPackageInner = {
  ...addPackageAction,
  api: addPackageApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(addPackageError);
    if (err) {
      const pckg = yield select(getCurrentPackage);
      yield put(getPackagesInfoAction.request({ packageId: pckg.id }));
    }
  },
};

export const watchGetPackagesInfo = {
  ...getPackagesInfoAction,
  api: getPackagesInfoApi,
};

export const watchAddLesson = {
  ...addLessonAction,
  api: addLessonApi,
};

export const watchAddFolder = {
  ...addFolderAction,
  api: addFolderApi,
};

export const watchAddQuiz = {
  ...addQuizAction,
  api: addQuizApi,
};

export const watchCopyLesson = {
  ...copyLessonAction,
  api: copyLessonApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(copyLessonError);
    if (err) {
      const pckg = yield select(getCurrentPackage);
      yield put(getPackagesInfoAction.request({ packageId: pckg.id }));
    }
  },
};

export const watchCopyQuiz = {
  ...copyQuizAction,
  api: copyQuizApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(copyQuizError);
    if (err) {
      const pckg = yield select(getCurrentPackage);
      yield put(getPackagesInfoAction.request({ packageId: pckg.id }));
    }
  },
};

export const watchDeleteLesson = {
  ...deleteLessonAction,
  api: deleteLessonApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(deleteLessonError);
    if (err) {
      const pckg = yield select(getCurrentPackage);
      yield put(getPackagesInfoAction.request({ packageId: pckg.id }));
    }
  },
};

export const watchDeleteQuiz = {
  ...deleteQuizAction,
  api: deleteQuizApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(deleteQuizError);
    if (err) {
      const pckg = yield select(getCurrentPackage);
      yield put(getPackagesInfoAction.request({ packageId: pckg.id }));
    }
  },
};

export const watchMoveLesson = {
  ...moveLessonAction,
  api: moveLessonApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(moveLessonError);
    if (err) {
      const pckg = yield select(getCurrentPackage);
      yield put(getPackagesInfoAction.request({ packageId: pckg.id }));
    }
  },
};

export const watchMoveQuiz = {
  ...moveQuizAction,
  api: moveQuizApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(moveQuizError);
    if (err) {
      const pckg = yield select(getCurrentPackage);
      yield put(getPackagesInfoAction.request({ packageId: pckg.id }));
    }
  },
};

export const watchUpdateLessonOrder = {
  ...updateLessonOrderAction,
  api: updateLessonOrderApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(updateLessonOrderError);
    if (err) {
      const pckg = yield select(getCurrentPackage);
      yield put(getPackagesInfoAction.request({ packageId: pckg.id }));
    }
  },
};

export const watchUpdateLessonType = {
  ...updateLessonTypeAction,
  api: updateLessonTypeApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(updateLessonTypeError);
    if (err) {
      const pckg = yield select(getCurrentPackage);
      yield put(getPackagesInfoAction.request({ packageId: pckg.id }));
    }
  },
};

export const watchUpdatePackage = {
  ...updatePackageAction,
  api: updatePackageApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(updatePackageError);
    if (err) {
      errorToast(updateErr, JSON.stringify(err?.data));
      const pckg = yield select(getCurrentPackage);
      yield put(getPackagesInfoAction.request({ packageId: pckg.id }));
    }
  },
};

export const watchMovePackageIntoFolder = {
  ...movePackageIntoFolderAction,
  api: movePackageIntoFolderApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(movePackageIntoFolderError);
    if (err) {
      const pckg = yield select(getCurrentPackage);
      if (pckg) {
        yield put(getPackagesInfoAction.request({ packageId: pckg.id }));
      } else {
        yield put(getPackagesFoldersAction.request());
      }
    }
  },
};

export const watchUpdateQuizOrder = {
  ...updateQuizOrderAction,
  api: updateQuizOrderApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(updateQuizOrderError);
    if (err) {
      const pckg = yield select(getCurrentPackage);
      yield put(getPackagesInfoAction.request({ packageId: pckg.id }));
    }
  },
};

export const watchUpdateQuizType = {
  ...updateQuizTypeAction,
  api: updateQuizTypeApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(updateQuizTypeError);
    if (err) {
      const pckg = select(getCurrentPackage);
      yield put(getPackagesInfoAction.request({ packageId: pckg.id }));
    }
  },
};

export const watchDeletePackage = {
  ...deletePackageAction,
  api: deletePackageApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(deletePackageError);
    if (err) {
      const pckg = select(getCurrentPackage);
      yield put(getPackagesInfoAction.request({ packageId: pckg.id }));
    }
  },
};

export const watchGetPackagesFolders = {
  ...getPackagesFoldersAction,
  api: getPackagesFoldersApi,
};

export const watchAddPackageMain = {
  ...addPackageMainAction,
  api: addPackageApi,
};

export const watchDeletePackageMain = {
  ...deletePackageMainAction,
  api: deletePackageApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(deletePackageMainError);
    if (err) {
      errorToast(
        'Deletion Failed',
        'The package is not deleted due to encountered error. Please, try again later!',
      );
      if (action.params.folderId) {
        yield put(getFolderInfoAction.request({ folderId: action.params.folderId }));
      } else {
        yield put(getPackagesFoldersAction.request());
      }
    }
  },
};

export const watchDeleteFolderMain = {
  ...deleteFolderMainAction,
  api: deleteFolderMainApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(deleteFolderMainError);
    if (err) {
      errorToast(
        'Deletion Failed',
        'The folder is not deleted due to encountered error. Please, try again later!',
      );
      yield put(getPackagesFoldersAction.request());
    }
  },
};

export const watchGetFolderInfo = {
  ...getFolderInfoAction,
  api: getFolderInfoApi,
};

export const watchChangeFolderOrder = {
  ...changeFolderOrderAction,
  api: changeFolderOrderApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(changeFolderOrderError);
    if (err) {
      errorToast(
        'Reordering Failed',
        'The folder is not reordered due to encountered error. Please, try again later!',
      );
      yield put(getPackagesFoldersAction.request());
    }
  },
};

export const watchUpdatePackagesFolder = {
  ...updateFolderAction,
  api: updateFolderApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(updateFolderError);
    if (err) {
      errorToast(
        updateErr,
        'The folder title is not updated due to encountered error. Please, try again later!',
      );
      yield put(getPackagesFoldersAction.request());
    }
  },
};

export const watchChangePackageOrder = {
  ...changePackageOrderAction,
  api: changePackageOrderApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(changePackageOrderError);
    if (err) {
      errorToast(
        'Reordering Failed',
        'The package is not reordered due to encountered error. Please, try again later!',
      );
      if (action.params.folderId) {
        yield put(getFolderInfoAction.request({ folderId: action.params.folderId }));
      } else {
        yield put(getPackagesFoldersAction.request());
      }
    }
  },
};

export const watchAddFolderMain = {
  ...addFolderMainAction,
  api: addFolderMainApi,
};

export const watchMovePackageIntoFolderMain = {
  ...movePackageIntoFolderMainAction,
  api: movePackageIntoFolderApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(movePackageIntoFolderMainError);
    if (err) {
      errorToast(
        'Moving Failed',
        'The package is not moved due to encountered error. Please, try again later!',
      );
      if (action.params.currentFolderId) {
        yield put(getFolderInfoAction.request({ folderId: action.params.currentFolderId }));
      } else {
        yield put(getPackagesFoldersAction.request());
      }
    }
  },
};

export const watchGetPermissionGroups = {
  ...getPermissionGroupsAction,
  api: getPermissionGroupsApi,
};

export const watchUpdateQuiz = {
  ...updateQuizAction,
  api: updateQuizApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(updateQuizError);
    if (err) {
      errorToast(updateErr, 'The quiz update failed, please, try again later!');
      const pckg = select(getCurrentPackage);
      yield put(getPackagesInfoAction.request({ packageId: pckg.id }));
    }
  },
};

export const watchUpdateLesson = {
  ...updateLessonAction,
  api: updateLessonApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(updateLessonError);
    if (err) {
      errorToast(updateErr, 'The lesson update failed, please, try again later!');
      const pckg = select(getCurrentPackage);
      yield put(getPackagesInfoAction.request({ packageId: pckg.id }));
    }
  },
};
