import { PENDO_API_KEY, isOnProduction, isOnStaging } from '../Environment';

const initializePendo = instructorProfile => {
  if (instructorProfile && (isOnProduction || isOnStaging)) {
    (function(apiKey) {
      (function(p, e, n, d, o) {
        var v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w)
          (function(m) {
            o[m] =
              o[m] ||
              function() {
                o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
              };
          })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z?.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');

      // appending env to id's to ensure uniqueness
      const env = isOnProduction ? 'prod' : 'staging';
      const school = instructorProfile.school || {};
      window.pendo.initialize({
        visitor: {
          id: `${instructorProfile.id}-${env}`,
          email: instructorProfile.email,
          full_name: instructorProfile.name,
          role: instructorProfile.type,
        },
        account: {
          id: `${school.id}-${env}`,
          name: school.name,
          is_paying: !school.is_blocked,
          creationDate: school?.created_at,
        },
      });
    })(PENDO_API_KEY);
  }
};

export default initializePendo;
