import { notification } from 'antd';
import Text from '../Text';
import { CloseNotification, Info, ThumbsUp, Warn } from '../Icons';
import styles from './styles.module.css';

const defaultOptions = {
  closeIcon: <CloseNotification />,
  duration: 4.5,
  description: null,
};

export const info = ({ message, ...options }) => {
  notification.open({
    ...defaultOptions,
    ...options,
    className: `${styles.alert} ${styles.info}`,
    icon: <Info width={17} height={17} />,
    message: <Text.Lato level={3}>{message}</Text.Lato>,
  });
};

export const error = ({ message, ...options }) => {
  notification.open({
    ...defaultOptions,
    ...options,
    className: `${styles.alert} ${styles.error}`,
    icon: <Warn />,
    message: (
      <Text.Lato level={3} color='white'>
        {message}
      </Text.Lato>
    ),
  });
};

export const success = ({ message, ...options }) => {
  notification.open({
    ...defaultOptions,
    ...options,
    className: `${styles.alert} ${styles.success}`,
    icon: <ThumbsUp />,
    message: (
      <Text.Lato level={3} color='white'>
        {message}
      </Text.Lato>
    ),
  });
};

export const warn = ({ message, ...options }) => {
  notification.open({
    ...defaultOptions,
    ...options,
    className: `${styles.alert} ${styles.warn}`,
    icon: <Warn />,
    message: (
      <Text.Lato level={3} color='white'>
        {message}
      </Text.Lato>
    ),
  });
};
