import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIncidentsListAction, getMaintenanceListAction } from '../../actions/app.actions';
import { getIncidents, getMaintenance } from '../../selectors/app.selectors';
import { isOnProduction } from '../../Environment';

export const useSystemStatus = () => {
  const incidents = useSelector(getIncidents);
  const maintenance = useSelector(getMaintenance);
  const dispatch = useDispatch();

  const getIncidentsList = useCallback(() => {
    return dispatch(getIncidentsListAction.request());
  }, [dispatch]);
  const getMaintenanceList = useCallback(() => {
    return dispatch(getMaintenanceListAction.request());
  }, [dispatch]);

  const watchOverIncidents = useCallback(() => {
    getIncidentsList();
    getMaintenanceList();
    return setInterval(() => {
      getIncidentsList();
      getMaintenanceList();
    }, 120000);
  }, [getIncidentsList, getMaintenanceList]);

  useEffect(() => {
    if (isOnProduction) {
      const t = watchOverIncidents();
      return () => clearInterval(t);
    }
  }, [watchOverIncidents]);

  return [incidents, maintenance];
};

export default useSystemStatus;
