import client from '../client';

export const getUserGroupsApi = ({ params }, cancelToken) => {
  return client.get('/group-list', {
    params: {
      page: params.page,
      search: params.search,
    },
    cancelToken,
  });
};

export const getGroupUsersApi = ({ params }, cancelToken) => {
  const id = params.id ? `/${params.id}` : '';
  return client.get(`/edit-group${id}`, {
    params: {
      search: params.search,
    },
    cancelToken,
  });
};

export const createPositionGroupApi = ({ params }, cancelToken) =>
  client.post('/create-group', params, { cancelToken });

export const editPositionGroupApi = ({ params }, cancelToken) =>
  client.post(`/update-group/${params.id}`, params, { cancelToken });

export const removePositionGroupApi = ({ params }, cancelToken) =>
  client.delete(`/delete-group/${params.id}`, { cancelToken });
