import {
  getSignedStorageURLAction,
  getSignedStorageURLBulkAction,
} from '../actions/utility.actions';
import { getSignedStorageURLApi, getSignedStorageURLBulkApi } from '../services/utility.service';

export const watchSignedStorageURL = {
  ...getSignedStorageURLAction,
  api: getSignedStorageURLApi,
};

export const watchSignedStorageBulkURL = {
  ...getSignedStorageURLBulkAction,
  api: getSignedStorageURLBulkApi,
};
