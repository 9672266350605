import produce from 'immer';

import {
  getUsersAction,
  getSingleUser,
  inviteUsersAction,
  editUserAction,
  removeUsersAction,
  removeCoachesAction,
  getGeneratedPassword,
  deleteGeneratedPassword,
} from '../../actions/users.actions';

export const defaultState = {
  user: {},
  users: [],
  coaches: [],
  managers: [],
  code: null,
  message: '',
  dataCount: 0,
  error: null,
  generatedPassword: '',
};

const users = produce((state, action) => {
  switch (action.type) {
    case getUsersAction.requestTypes.SUCCESS:
      state.users = action.users || [];
      state.code = action.code;
      state.dataCount = action.dataCount || null;
      break;
    case getSingleUser.requestTypes.SUCCESS:
      state.user = action.user || {};
      break;
    case inviteUsersAction.requestTypes.SUCCESS:
    case editUserAction.requestTypes.SUCCESS:
      state.users = action.users || [];
      state.code = action.code;
      state.message = action.message || '';
      state.dataCount = action.dataCount || null;
      break;
    case inviteUsersAction.requestTypes.FAILURE:
      state.message = action.message;
      state.error = action.error;
      break;
    case removeUsersAction.requestTypes.SUCCESS:
      state.users = action.users || [];
      state.code = action.code;
      state.message = action.message || '';
      break;
    case removeCoachesAction.requestTypes.SUCCESS:
      state.managers = action.managers || [];
      state.coaches = action.coaches || [];
      state.code = action.code;
      state.message = action.message || '';
      break;
    case getGeneratedPassword.requestTypes.SUCCESS:
      state.generatedPassword = action.generatedPassword;
      break;
    case deleteGeneratedPassword.actionName:
      state.generatedPassword = '';
      break;
    default:
      break;
  }
}, defaultState);

export default users;
