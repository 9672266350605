import { HEAP_APP_KEY } from '../Environment/index';

const initializeHeap = instructorProfile => {
  const heapScriptExists = document.getElementById('heapScript');
  if (instructorProfile && !heapScriptExists) {
    const heapScript = document.createElement('script');
    heapScript.innerHTML = `
            window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};   
            heap.load(${HEAP_APP_KEY}); 
        `;
    heapScript.id = 'heapScript';
    document.head.appendChild(heapScript);

    const school = instructorProfile.school || {};
    window.heap.identify(instructorProfile.id);
    window.heap.addUserProperties({
      user_id: instructorProfile.id,
      email: instructorProfile.email,
      name: instructorProfile.name,
      role: instructorProfile.type,
      account_id: school.id,
      account_name: school.name,
    });
  }
};

export default initializeHeap;
