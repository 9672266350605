import produce from 'immer';
import {
  getLeaderBoardAction,
  getLeaderBoardForDownloadAction,
  getLeaderBoardFiltersAction,
  leaderBoardDownloadCleanup,
  getQuizzesAction,
  getQuizFiltersAction,
  getQuizDetailsAction,
  gradeUserAnswerAction,
  getQuizAllAnswersAction,
  getQuizSlidesInfoAction,
  getQuizAnswersForDownloadAction,
  quizAnswersForDownloadCleanup,
  getLessonsAction,
  getLessonFiltersAction,
  getLessonUsersAction,
  notifyLessonUsersAction,
  notifyQuizUsersAction,
  getQuizUsersAction,
} from '../../actions/analytics.actions';
import downloadState from '../../constants/downloadState';

export const defaultState = {
  leaderBoard: [],
  leaderBoardCount: 0,
  leaderBoardFilters: [],
  leaderBoardForDownload: [],
  leaderBoardDownloadStatus: downloadState.NOT_STARTED,
  quizzes: [],
  quizCount: 0,
  quizFilters: [],
  quizzesDetails: {},
  message: '',
  error: null,
  loading: {},
  gradeLoading: {},
  quizAllAnswers: { totalCount: 0, data: [] },
  quizSlidesInfo: [],
  quizAnswersForDownload: [],
  quizAnswersForDownloadStatus: downloadState.NOT_STARTED,
  lessonData: [],
  lessonFilters: [],
  lessonsLoading: {},
  lessonsUsers: {},
  lessonsCount: 0,
  quizUsers: {},
  quizLoading: {},
};

const DATA_PER_PAGE = 20;

const analytics = produce((state, action) => {
  switch (action.type) {
    case getLeaderBoardAction.requestTypes.SUCCESS:
      state.leaderBoard = action.leaderBoardData || [];
      state.code = action.code;
      state.leaderBoardCount = action.dataCount || null;
      break;
    case getLeaderBoardForDownloadAction.requestTypes.REQUEST:
      state.leaderBoardDownloadStatus = downloadState.STARTED;
      break;
    case getLeaderBoardForDownloadAction.requestTypes.SUCCESS:
      state.leaderBoardForDownload = action.allLeaderBoardData;
      if (state.leaderBoardForDownload.length === state.leaderBoardCount) {
        state.leaderBoardDownloadStatus = downloadState.COMPLETED;
      }
      break;
    case getLeaderBoardForDownloadAction.requestTypes.FAILURE:
      state.leaderBoardForDownload = [];

      if (state.leaderBoardForDownload.length === state.leaderBoardCount) {
        state.leaderBoardDownloadStatus = downloadState.COMPLETED;
      }
      break;
    case leaderBoardDownloadCleanup.type:
      state.leaderBoardForDownload = [];
      state.leaderBoardDownloadStatus = downloadState.NOT_STARTED;
      break;
    case getLeaderBoardFiltersAction.requestTypes.SUCCESS:
      state.leaderBoardFilters = action.leaderBoardFilters || [];
      state.code = action.code;
      break;
    case getQuizzesAction.requestTypes.SUCCESS:
      state.quizzes = action.quizzes || [];
      state.code = action.code;
      state.quizCount = action.dataCount;
      state.quizzesDetails = {};
      break;
    case getQuizFiltersAction.requestTypes.SUCCESS:
      state.quizFilters = action.quizFilters || [];
      state.code = action.code;
      break;
    case getQuizDetailsAction.requestTypes.REQUEST:
      state.loading[action.quizId] = true;
      break;
    case getQuizDetailsAction.requestTypes.SUCCESS:
      state.loading[action.quizId] = false;
      if (action.quizDetails) {
        state.quizzesDetails[action.quizId] = action.quizDetails;
      }
      state.code = action.code;
      break;
    case gradeUserAnswerAction.requestTypes.REQUEST:
      state.gradeLoading[action.id] = true;
      break;
    case gradeUserAnswerAction.requestTypes.SUCCESS:
      state.gradeLoading[action.id] = false;
      if (action.quizDetails) {
        if (action.seeAllAnswers) {
          state.quizAllAnswers.data = action.quizDetails;
        } else {
          state.quizzesDetails[action.quizId] = action.quizDetails;
        }
      }
      state.code = action.code;
      break;
    case getQuizAllAnswersAction.requestTypes.SUCCESS:
      state.quizAllAnswers = action.quizAllAnswers || defaultState.quizAllAnswers;
      state.code = action.code;
      break;
    case getQuizSlidesInfoAction.requestTypes.SUCCESS:
      state.quizSlidesInfo = action.quizSlidesInfo || [];
      state.code = action.code;
      break;
    case getQuizAnswersForDownloadAction.requestTypes.REQUEST:
      state.quizAnswersForDownloadStatus = downloadState.STARTED;
      break;
    case getQuizAnswersForDownloadAction.requestTypes.SUCCESS:
      const answers = action?.quizAllAnswers?.data || [];
      state.quizAnswersForDownload.push(answers);
      state.code = action.code;

      if (
        state.quizAnswersForDownload.length ===
        Math.ceil(action.quizAllAnswers.totalCount / DATA_PER_PAGE)
      ) {
        state.quizAnswersForDownloadStatus = downloadState.COMPLETED;
      }
      break;
    case getQuizAnswersForDownloadAction.requestTypes.FAILURE:
      state.quizAnswersForDownload.push(null);
      state.code = action.code;

      if (
        state.quizAnswersForDownload.length ===
        Math.ceil(action.quizAllAnswers.totalCount / DATA_PER_PAGE)
      ) {
        state.quizAnswersForDownloadStatus = downloadState.COMPLETED;
      }
      break;
    case quizAnswersForDownloadCleanup.type:
      state.quizAnswersForDownload = [];
      state.quizAnswersForDownloadStatus = downloadState.NOT_STARTED;
      break;
    case getLessonsAction.requestTypes.SUCCESS:
      state.lessonData = action.lessons || [];
      state.code = action.code;
      state.lessonsCount = action.dataCount || null;
      break;
    case getLessonFiltersAction.requestTypes.SUCCESS:
      state.lessonFilters = action.lessonFilters || [];
      state.code = action.code;
      break;

    case getQuizUsersAction.requestTypes.REQUEST:
      state.quizLoading[action.quizId] = true;
      break;

    case getQuizUsersAction.requestTypes.SUCCESS:
      state.quizLoading[action.quizId] = false;
      state.quizUsers[action.quizId] = action.quizUsers || [];
      break;

    case notifyQuizUsersAction.requestTypes.SUCCESS:
      state.code = action.code;
      break;

    case getLessonUsersAction.requestTypes.REQUEST:
      state.lessonsLoading[action.lessonId] = true;
      break;
    case getLessonUsersAction.requestTypes.SUCCESS:
      state.lessonsLoading[action.lessonId] = false;
      state.lessonsUsers[action.lessonId] = action.lessonUsers || [];
      state.code = action.code;
      break;
    case notifyLessonUsersAction.requestTypes.SUCCESS:
      state.code = action.code;
      break;
  }
}, defaultState);

export default analytics;
