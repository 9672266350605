import { makeRequestAction } from './action-generator';

export const sendSupportMessageAction = makeRequestAction('SEND_SUPPORT_MESSAGE', {
  onSuccess(params, response) {
    params.onSuccess && params.onSuccess(response);
  },
  onFailure(params, error) {
    params.onFailure && params.onFailure(error?.error?.data?.message);
  },
});
