import { useState, useLayoutEffect } from 'react';
import { usePrevious } from './usePrevious';

const KEY = {
  UP: 38,
  DOWN: 40,
  SPACE: 32,
};

const ROW_HEIGHT = 46;

const useArrowNavigation = ({ getOptionsLength, getContainerRef }) => {
  const [index, setIndex] = useState(-1);
  const getPreviousIndex = usePrevious(index);

  const resetIndex = idx => setIndex(idx);

  const handleArrowKeys = e => {
    // eslint-disable-next-line default-case
    switch (e.keyCode) {
      case KEY.UP:
        e.preventDefault();
        setIndex(i => Math.max(i - 1, 0));
        break;
      case KEY.DOWN:
        e.preventDefault();
        setIndex(i => Math.min(i + 1, getOptionsLength - 1));
        break;
    }
  };

  useLayoutEffect(() => {
    const container = getContainerRef.current;
    if (container) {
      const getIndexDifference = index - getPreviousIndex;
      const offsetTop = ROW_HEIGHT * (index + getIndexDifference);
      const getScrollHeight = getOptionsLength * ROW_HEIGHT;
      const getScrollTop = container.scrollTop;
      const getContainerClientHeiht = container.clientHeight;

      if (getScrollHeight > getContainerClientHeiht) {
        const scrollBottom = container.clientHeight + getScrollTop;

        if (offsetTop >= scrollBottom) {
          container.scrollTop = offsetTop - getContainerClientHeiht;
        } else if (offsetTop <= getScrollTop) {
          container.scrollTop = offsetTop - ROW_HEIGHT;
        }
      }
    }
  }, [index, getOptionsLength, getContainerRef]);

  return { handleArrowKeys, resetIndex, getActiveIndex: index };
};

export { useArrowNavigation };
