import { ApolloClient, InMemoryCache, makeVar, ApolloLink, from } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { apiOrigin, isOnDoDNetwork, getAuthScheme } from '../Environment';
import { localStorageKeys } from '../constants/localStorageKeys';
import { pullContext } from './helpers/index';
const GQL_ENDPOINT = new URL('graphql', apiOrigin);
const RESULTS_PER_PAGE = 10;

const httpLink = createUploadLink({
  uri: GQL_ENDPOINT,
});

const authLink = new ApolloLink((operation, forward) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(localStorageKeys.user);
  // return the headers to the context so httpLink can read them
  const authHeaders = !isOnDoDNetwork
    ? {
        authorization: token ? `Bearer ${token}` : '',
        'L2W-Client-Auth-Scheme': getAuthScheme(),
        'L2W-Client-User-Type': 'instructor',
      }
    : {};
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...authHeaders,
    },
  }));
  return pullContext(forward, operation);
});

// Initializes to true if localStorage includes a 'user' key,
// false otherwise
export const isLoggedInVar = makeVar(!!localStorage.getItem(localStorageKeys.user));

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
      },
    },
    School: {
      fields: {
        wording: {
          read(wording) {
            return wording ? JSON.parse(wording) : {};
          },
        },
      },
    },
    Instructor: {
      fields: {
        isAdmin: {
          read(isAdmin, { readField }) {
            const type = readField('type');
            return isAdmin ?? type === 'admin';
          },
        },
        isManager: {
          read(isManager, { readField }) {
            const type = readField('type');
            return isManager ?? type === 'analyst';
          },
        },
      },
    },
    // Prefer incoming data to existing data
    PackageFolder: {
      fields: {
        packages: {
          merge: false,
        },
      },
    },
    Package: {
      fields: {
        package_item: {
          merge: false,
        },
      },
    },
    PackageFolderGroup: {
      fields: {
        package_folder: {
          merge: false,
        },
      },
    },
    StudentPaginator: {
      keyFields: [],
      fields: {
        data: {
          merge(existing = [], incoming, { variables }) {
            if (variables.page) {
              const merged = existing ? existing.slice(0) : [];
              const offset = (variables.page - 1) * RESULTS_PER_PAGE;
              for (let i = 0; i < incoming.length; ++i) {
                merged[offset + i] = incoming[i];
              }
              return merged;
            }

            return incoming;
          },
        },
      },
    },
  },
});

const graph = new ApolloClient({
  link: from([authLink, httpLink]),
  cache,
});

export default graph;
