import { useCallback } from 'react';
import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';

import { Title, Text } from '../Ui/Typography';

import ResetForm from '../ResetPassword/ResetForm';
import { updateProfileAction } from '../../actions/profile.actions';
import laptop from '../../assets/images/laptop.png';

const PasswordExpired = props => {
  const dispatch = useDispatch();

  const handleResetRequest = useCallback(
    data => {
      dispatch(updateProfileAction.request({ ...data, isSSO: props.isSSO }));
    },
    [dispatch],
  );

  return (
    <div className='sub-content'>
      <div className='update-message'>
        <img src={laptop} alt='Laptop' className='laptop-icon' />
        <div className='update-message-wrapper'>
          <Title level={2} className='title'>
            YOUR PREVIOUS PASSWORD EXPIRED
          </Title>
          <div className='subtitle'>
            <Text color='grey-1'>
              Please change your password. The password must be different from your 3 previous
              passwords.
            </Text>
          </div>
        </div>
      </div>
      <div className='login-page-wrapper'>
        <Row type='flex' justify='center'>
          <Col xs={24} md={20} lg={18} xl={12} xxl={11} className='login-form-col'>
            <Row type='flex' justify='center'>
              <Col xxl={18}>
                <ResetForm handleResetRequest={handleResetRequest} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PasswordExpired;
