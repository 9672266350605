import { takeEvery } from '@redux-saga/core/effects';
import { call, put, select } from 'redux-saga/effects';
import {
  getCollectionsAction,
  getAlbumsAction,
  changeCollectionOrderAction,
  getUserGroupsAction,
  addAlbumAction,
  updateAlbumAction,
  deleteAlbumAction,
  changeAlbumOrderAction,
  getAllMediaAction,
  addMediaAction,
  updateMediaAction,
  deleteMediaAction,
  changeMediaOrderAction,
  addPresentationAction,
} from 'actions/contentBank.actions';
import {
  getAlbumsApi,
  changeCollectionOrderApi,
  getUserGroupsApi,
  addAlbumApi,
  updateAlbumApi,
  deleteAlbumApi,
  changeAlbumOrderApi,
  getAllMediaApi,
  addMediaApi,
  updateMediaApi,
  deleteMediaApi,
  changeMediaOrderApi,
  addPresentationApi,
} from 'services/contentBank.service';
import {
  addAlbumError,
  updateAlbumError,
  deleteAlbumError,
  changeAlbumOrderError,
  addMediaError,
  deleteMediaError,
  changeMediaOrderError,
  addPresentationError,
  updateMediaError,
} from 'selectors/contentBank.selector';

export const watchGetAllPlaybookFolders = {
  ...getAlbumsAction,
  api: getAlbumsApi,
};

export const watchGetUserGroups = {
  ...getUserGroupsAction,
  api: getUserGroupsApi,
};

export const watchAddAlbum = {
  ...addAlbumAction,
  api: addAlbumApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(addAlbumError);
    if (!err) {
      yield put(getAlbumsAction.request());
    }
  },
};

export const watchUpdateAlbum = {
  ...updateAlbumAction,
  api: updateAlbumApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(updateAlbumError);
    if (!err) {
      yield put(getAlbumsAction.request());
    }
  },
};

export const watchDeleteAlbum = {
  ...deleteAlbumAction,
  api: deleteAlbumApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(deleteAlbumError);
    if (!err) {
      yield put(getAlbumsAction.request());
    }
  },
};

export const watchChangeAlbumOrder = {
  ...changeAlbumOrderAction,
  api: changeAlbumOrderApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(changeAlbumOrderError);
    if (err) {
      yield put(getAlbumsAction.request());
    }
  },
};

export const watchAddMedia = {
  ...addMediaAction,
  api: addMediaApi,
  take: takeEvery,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(addMediaError);
    if (!err) {
      yield put(getAlbumsAction.request());
      yield put(
        getAllMediaAction.request({
          search: '',
          content_bank_folder_id: action.params.content_bank_folder_id,
        }),
      );
    }
  },
};

export const watchAddPresentation = {
  ...addPresentationAction,
  api: addPresentationApi,
  take: takeEvery,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(addPresentationError);
    if (!err) {
      yield put(getAlbumsAction.request());
      yield put(
        getAllMediaAction.request({
          search: '',
          content_bank_folder_id: action.params.content_bank_folder_id,
        }),
      );
    }
  },
};

export const watchUpdateMedia = {
  ...updateMediaAction,
  api: updateMediaApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(updateMediaError);
    if (!err) {
      yield put(
        getAllMediaAction.request({
          search: '',
          content_bank_folder_id: action.params.fromFolderId,
        }),
      );
    }
  },
};

export const watchGetAllMedia = {
  ...getAllMediaAction,
  api: getAllMediaApi,
};

export const watchDeleteMedia = {
  ...deleteMediaAction,
  api: deleteMediaApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(deleteMediaError);
    if (!err) {
      yield put(
        getAllMediaAction.request({
          search: '',
          content_bank_folder_id: action.params.content_bank_folder_id,
        }),
      );
    }
  },
};

export const watchChangeMediaOrder = {
  ...changeMediaOrderAction,
  api: changeMediaOrderApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(changeMediaOrderError);
    if (err) {
      yield put(
        getAllMediaAction.request({
          search: '',
          content_bank_folder_id: action.params.content_bank_folder_id,
        }),
      );
    }
  },
};
