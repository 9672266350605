import { requestActionFactory } from '../action-generator';

// Content Bank Folders
export const getContentFoldersAction = requestActionFactory('GET_CONTENT_FOLDERS');
export const getContentFoldersTreeAction = requestActionFactory('GET_CONTENT_FOLDERS_TREE');
export const getContentFolderByIdAction = requestActionFactory('GET_CONTENT_FOLDER_BY_ID');
export const createContentFolderAction = requestActionFactory('CREATE_CONTENT_FOLDER');
export const updateContentFolderAction = requestActionFactory('UPDATE_CONTENT_FOLDER');
export const moveContentFolderAction = requestActionFactory('MOVE_CONTENT_FOLDER');
export const reorderContentFoldersAction = requestActionFactory('REORDER_CONTENT_FOLDERS');
export const deleteContentFolderAction = requestActionFactory('DELETE_CONTENT_FOLDER');

// Content Bank Files
export const addContentFileAction = requestActionFactory('ADD_CONTENT_FILE');
export const addPdfPptContentAction = requestActionFactory('ADD_PDF_PPT_CONTENT');
export const updateContentFileAction = requestActionFactory('UPDATE_CONTENT_FILE');
export const moveContentFilesAction = requestActionFactory('MOVE_CONTENT_FILES');
export const reorderContentFilesAction = requestActionFactory('REORDER_CONTENT_FILES');
export const deleteContentFilesAction = requestActionFactory('DELETE_CONTENT_FILE');
