import { makeAction, makeRequestAction } from './action-generator';

export const resetCurrentPackageInfo = makeAction('RESET_CURRENT_PACKAGE_INFO');

export const addPackageAction = makeRequestAction('[PACKAGES_INNER] ADD_PACKAGE', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess(response.data.courseDetail);
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const getPackagesInfoAction = makeRequestAction('GET_ALL_PACKAGES_INFO', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return {
      folders: response.data.result.allCourses,
      currentPackage: response.data.result.course,
      packagesWithoutFolders: response.data.result.courses_without_folders,
    };
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const updatePackageAction = makeRequestAction('UPDATE_PACKAGE', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return {
      data: response.data,
    };
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const movePackageIntoFolderAction = makeRequestAction('MOVE_PACKAGE_INTO_FOLDER', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const updateLessonTypeAction = makeRequestAction('UPDATE_LESSON_TYPE', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const addLessonAction = makeRequestAction('[PACKAGES_INNER] ADD_LESSON', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess(response.data?.lessonDetail);
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const addFolderAction = makeRequestAction('[PACKAGES_INNER] ADD_FOLDER', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess(response?.data?.id);
    return response;
  },
});

export const deleteLessonAction = makeRequestAction('[PACKAGES_INNER] DELETE_LESSON', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const updateLessonOrderAction = makeRequestAction('[PACKAGES_INNER] UPDATE_LESSON_ORDER', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const addQuizAction = makeRequestAction('[PACKAGES_INNER] ADD_QUIZ', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess(response.data?.quizDetail);
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const updateQuizOrderAction = makeRequestAction('[PACKAGES_INNER] UPDATE_QUIZ_ORDER', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const deleteQuizAction = makeRequestAction('[PACKAGES_INNER] DELETE_QUIZ', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const updateQuizTypeAction = makeRequestAction('UPDATE_QUIZ_TYPE', {
  onSuccess: ({ onSuccess }, { data }) => {
    onSuccess && onSuccess();
    return {
      data,
    };
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const moveLessonAction = makeRequestAction('[PACKAGES_INNER] MOVE_LESSON', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const moveQuizAction = makeRequestAction('[PACKAGES_INNER] MOVE_QUIZ', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const copyLessonAction = makeRequestAction('[PACKAGES_INNER] COPY_LESSON', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess(response.data);
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const copyQuizAction = makeRequestAction('[PACKAGES_INNER] COPY_QUIZ', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess(response.data);
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const deletePackageAction = makeRequestAction('[PACKAGES_INNER] DELETE_PACKAGE', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess(response.data);
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const getPackagesFoldersAction = makeRequestAction('GET_PACKAGES_FOLDERS', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess(response.data);
    return {
      folders: response.data.result?.folders || [],
      pcks: response.data.result?.courses || [],
    };
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const addPackageMainAction = makeRequestAction('ADD_PACKAGE', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess(response.data.courseDetail);
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const deletePackageMainAction = makeRequestAction('DELETE_PACKAGE', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const deleteFolderMainAction = makeRequestAction('DELETE_PACKAGE_FOLDER', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const getFolderInfoAction = makeRequestAction('GET_FOLDER_INFO', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return {
      pcks: response.data.result?.courses || [],
      folders: response.data.result?.folders || [],
      folderName: response.data.result?.folder_title || '',
    };
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const changeFolderOrderAction = makeRequestAction('CHANGE_FOLDER_ORDER', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const changePackageOrderAction = makeRequestAction('CHANGE_PACKAGE_ORDER', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const addFolderMainAction = makeRequestAction('ADD_FOLDER_MAIN', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess(response.data);
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const movePackageIntoFolderMainAction = makeRequestAction('MOVE_PACKAGE_INTO_FOLDER_MAIN', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const updateFolderAction = makeRequestAction('UPDATE_PACKAGE_FOLDER', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return response;
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const getPermissionGroupsAction = makeRequestAction('GET_PERMISSION_GROUPS', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return {
      users: response.data.result?.players,
      groups: response.data.result?.groups,
    };
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const updateLessonAction = makeRequestAction('UPDATE_LESSON', {
  onSuccess: (params, response) => {
    params.onSuccess && params.onSuccess();
    return {
      package: response.data?.package || {},
    };
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});

export const updateQuizAction = makeRequestAction('UPDATE_QUIZ', {
  onSuccess: ({ onSuccess }, { data }) => {
    onSuccess && onSuccess();
    return {
      package: data?.package || {},
    };
  },
  onFailure: (params, response) => {
    params.onFailure && params.onFailure();
    return response;
  },
});
