import { call, put, select } from 'redux-saga/effects';
import {
  getUsersAction,
  resendPasswordAction,
  terminateTokenAction,
  getSingleUser,
  inviteUsersAction,
  editUserAction,
  removeUsersAction,
  removeCoachesAction,
  getGeneratedPassword,
  resetPasswordAction,
} from '../../actions/users.actions';

import {
  getUsersApi,
  resendPasswordApi,
  terminateTokenApi,
  getSingleUserApi,
  inviteUserApi,
  editUserApi,
  removeUsersApi,
  removeCoachesApi,
  getGeneratedPasswordApi,
  resetPasswordAPi,
} from '../../services/users.service';

import {
  inviteUsersError,
  editUserError,
  removeUsersError,
  removeCoachesError,
} from '../../selectors/users.selector';
import history from '../../history';

export const watchGetUsers = {
  ...getUsersAction,
  api: getUsersApi,
};

export const watchResendPassword = {
  ...resendPasswordAction,
  api: resendPasswordApi,
};

export const watchTerminateToken = {
  ...terminateTokenAction,
  api: terminateTokenApi,
};

export const watchGetSingleUsers = {
  ...getSingleUser,
  api: getSingleUserApi,
};

export const watchInviteUsers = {
  ...inviteUsersAction,
  api: inviteUserApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const errorInvite = yield select(inviteUsersError);
    if (!errorInvite) {
      history.push('/users');
    } else {
      console.log('error');
    }
  },
};

export const watchEditUser = {
  ...editUserAction,
  api: editUserApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const error = yield select(editUserError);
    if (!error) {
      history.push('/users');
    } else {
      console.log('error');
    }
  },
};

export const watchRemoveUsers = {
  ...removeUsersAction,
  api: removeUsersApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(removeUsersError);
    if (!err) {
      yield put(getUsersAction.request());
      history.push('/users');
    }
  },
};

export const watchRemoveCoaches = {
  ...removeCoachesAction,
  api: removeCoachesApi,
  *load(fetch, action) {
    yield call(fetch, action);
    const err = yield select(removeCoachesError);
    if (!err) {
      yield put(
        getUsersAction.request({
          type: action.params.type,
          page: action.params.page,
        }),
      );
    }
  },
};

export const watchGetGeneratedPassword = {
  ...getGeneratedPassword,
  api: getGeneratedPasswordApi,
};

export const watchResetPassword = {
  ...resetPasswordAction,
  api: resetPasswordAPi,
};
