import client from '../client';

import {
  STATUSPAGE_PAGE_ID as pageId,
  STATUSPAGE_AUTH_TOKEN as statusPageAuthToken,
} from '../../Environment';

export const getIncidentsListApi = (action, cancelToken) => {
  return client.get(`https://api.statuspage.io/v1/pages/${pageId}/incidents/unresolved`, {
    headers: {
      Authorization: `OAuth ${statusPageAuthToken}`,
    },
    cancelToken,
  });
};

export const getMaintenanceListApi = (action, cancelToken) => {
  return client.get(`https://api.statuspage.io/v1/pages/${pageId}/incidents/active_maintenance`, {
    headers: {
      Authorization: `OAuth ${statusPageAuthToken}`,
    },
    cancelToken,
  });
};
