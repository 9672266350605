import { call, put, select } from 'redux-saga/effects';

import {
  getProfileAction,
  initialLoginAction,
  updateProfileAction,
  passwordCheckAction,
} from '../../actions/profile.actions';
import {
  getProfileApi,
  initialLoginApi,
  updateProfileApi,
  passwordCheckApi,
} from '../../services/profile.service';

import { updateProfileError } from '../../selectors/profile.selector';

export const watchGetProfile = {
  ...getProfileAction,
  api: getProfileApi,
};

export const watchUpdateProfile = {
  ...updateProfileAction,
  api: updateProfileApi,
  *load(fetch, data) {
    yield call(fetch, data);
    const err = yield select(updateProfileError);
    if (!err) {
      yield put(getProfileAction.request());
    }
  },
};

export const watchInitialLogin = {
  ...initialLoginAction,
  api: initialLoginApi,
};

export const watchPasswordCheck = {
  ...passwordCheckAction,
  api: passwordCheckApi,
};
