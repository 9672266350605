import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

import { isOnDoDNetwork, GA_TRACKING_ID, GTM_ID } from '../../Environment';

const GoogleAnalytics = ({ history }) => {
  const currentUser = useSelector(({ users: { user } }) => user);

  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!isOnDoDNetwork && history) {
      if (!isInitialized) {
        ReactGA.initialize(GA_TRACKING_ID, { testMode: process.env.NODE_ENV === 'test' });
        TagManager.initialize({
          gtmId: GTM_ID,
        });
        history.listen(location => {
          ReactGA.set({ page: location.pathname });
          ReactGA.pageview(location.pathname);
        });
        ReactGA.event({
          category: 'Page view',
          action: 'User has seen the landing page',
        });
        setInitialized(true);
      }
      if (currentUser) {
        ReactGA.set({
          userId: currentUser.id,
          userEmail: currentUser.email,
          userName: currentUser.name,
          activeTime: currentUser.active,
          schoolName: currentUser.team_name,
        });
      }
    }
  }, [isInitialized, currentUser, history]);

  return null;
};

export default GoogleAnalytics;
