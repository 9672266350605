import { createSelector } from 'reselect';
import createLoadingSelector from '../create-loading-selector';
import createErrorSelector from '../create-error-selector';
import {
  getLeaderBoardAction,
  getQuizzesAction,
  getQuizAllAnswersAction,
  getQuizSlidesInfoAction,
  getLessonsAction,
  notifyLessonUsersAction,
  notifyQuizUsersAction,
  gradeUserAnswerAction,
} from '../../actions/analytics.actions';

const analyticsState = state => state.analytics;

/*
  Analytics leader board
*/
export const getLeaderBoardData = createSelector(analyticsState, state => state.leaderBoard);
export const getLeaderBoardCount = createSelector(analyticsState, state => state.leaderBoardCount);
export const getLeaderBoardFilters = createSelector(
  analyticsState,
  state => state.leaderBoardFilters,
);
export const getLeaderBoardForDownload = createSelector(
  analyticsState,
  state => state.leaderBoardForDownload,
);
export const getLeaderBoardDownloadStatus = createSelector(
  analyticsState,
  state => state.leaderBoardDownloadStatus,
);

export const getLeaderBoardError = createErrorSelector(getLeaderBoardAction.actionName);
export const getLeaderBoardLoading = createLoadingSelector(getLeaderBoardAction.actionName);

/*
  Analytics quizzes
*/
export const getQuizzesData = createSelector(analyticsState, state => state.quizzes);
export const getQuizzesCount = createSelector(analyticsState, state => state.quizCount);
export const getQuizFilters = createSelector(analyticsState, state => state.quizFilters);
export const getQuizzesDetails = createSelector(analyticsState, state => state.quizzesDetails);
export const getQuizzesAllAnswers = createSelector(analyticsState, state => state.quizAllAnswers);
export const getQuizSlidesInfo = createSelector(analyticsState, state => state.quizSlidesInfo);
export const getQuizAnswersForDownload = createSelector(
  analyticsState,
  state => state.quizAnswersForDownload,
);
export const getQuizAnswersForDownloadStatus = createSelector(
  analyticsState,
  state => state.quizAnswersForDownloadStatus,
);

export const getQuizzesError = createErrorSelector(getQuizzesAction.actionName);
export const getQuizzesLoading = createLoadingSelector(getQuizzesAction.actionName);
export const getGradeUserAnswerLoading = params =>
  createSelector(analyticsState, state => state.gradeLoading[params.id]);
export const getQuizDetailsLoading = params =>
  createSelector(analyticsState, state => state.loading[params.id]);
export const gradeUserAnswerError = createErrorSelector(gradeUserAnswerAction.actionName);
export const getQuizAllAnswersLoading = createLoadingSelector(getQuizAllAnswersAction.actionName);
export const getQuizSlidesInfoLoading = createLoadingSelector(getQuizSlidesInfoAction.actionName);

export const getQuizUserData = params =>
  createSelector(
    analyticsState,
    state => state.quizUsers[params.id]?.slice().sort((a, b) => a.progress - b.progress) || [],
  );

export const getQuizzesUsers = createSelector(analyticsState, state => state.quizUsers);

export const getQuizUsersLoading = params =>
  createSelector(analyticsState, state => state.quizLoading[params.id]);

export const notifyQuizUsersLoading = createLoadingSelector(notifyQuizUsersAction.actionName);

/*
Analytics lessons
*/
export const getLessonData = createSelector(analyticsState, state => state.lessonData);
export const getLessonsCount = createSelector(analyticsState, state => state.lessonsCount);
export const getLessonFilters = createSelector(analyticsState, state => state.lessonFilters);
export const getLessonsUsers = createSelector(analyticsState, state => state.lessonsUsers);

export const getLessonUserData = params =>
  createSelector(
    analyticsState,
    state => state.lessonsUsers[params.id]?.slice().sort((a, b) => a.progress - b.progress) || [],
  );

export const getLessonsLoading = createLoadingSelector(getLessonsAction.actionName);
export const getLessonUsersLoading = params =>
  createSelector(analyticsState, state => state.lessonsLoading[params.id]);
export const notifyLessonUsersLoading = createLoadingSelector(notifyLessonUsersAction.actionName);
