import { createSelector } from 'reselect';
import createErrorSelector from '../create-error-selector';
import createLoadingSelector from '../create-loading-selector';
import {
  addPackageAction,
  updateQuizTypeAction,
  updateQuizOrderAction,
  updatePackageAction,
  updateLessonTypeAction,
  updateLessonOrderAction,
  moveQuizAction,
  moveLessonAction,
  deleteLessonAction,
  deleteQuizAction,
  copyQuizAction,
  copyLessonAction,
  addQuizAction,
  addLessonAction,
  getPackagesInfoAction,
  movePackageIntoFolderAction,
  addFolderAction,
  deletePackageAction,
  getPackagesFoldersAction,
  addPackageMainAction,
  deletePackageMainAction,
  getFolderInfoAction,
  changeFolderOrderAction,
  deleteFolderMainAction,
  addFolderMainAction,
  movePackageIntoFolderMainAction,
  changePackageOrderAction,
  updateFolderAction,
  updateLessonAction,
  updateQuizAction,
} from '../../actions/packages.actions';

export const updateQuizTypeError = createErrorSelector(updateQuizTypeAction.actionName);
export const updateQuizTypeLoading = createLoadingSelector(updateQuizTypeAction.actionName);

export const updateQuizOrderError = createErrorSelector(updateQuizOrderAction.actionName);
export const updateQuizOrderLoading = createLoadingSelector(updateQuizOrderAction.actionName);

export const addPackageError = createErrorSelector(addPackageAction.actionName);
export const addPackageLoading = createLoadingSelector(addPackageAction.actionName);

export const updatePackageError = createErrorSelector(updatePackageAction.actionName);
export const updatePackageLoading = createLoadingSelector(updatePackageAction.actionName);

export const movePackageIntoFolderError = createErrorSelector(
  movePackageIntoFolderAction.actionName,
);

export const updateLessonTypeError = createErrorSelector(updateLessonTypeAction.actionName);
export const updateLessonTypeLoading = createLoadingSelector(updateLessonTypeAction.actionName);

export const updateLessonOrderError = createErrorSelector(updateLessonOrderAction.actionName);
export const updateLessonOrderLoading = createLoadingSelector(updateLessonOrderAction.actionName);

export const moveQuizError = createErrorSelector(moveQuizAction.actionName);
export const moveQuizLoading = createLoadingSelector(moveQuizAction.actionName);

export const moveLessonError = createErrorSelector(moveLessonAction.actionName);
export const moveLessonLoading = createLoadingSelector(moveLessonAction.actionName);

export const deleteLessonError = createErrorSelector(deleteLessonAction.actionName);
export const deleteLessonLoading = createLoadingSelector(deleteLessonAction.actionName);

export const deletePackageError = createErrorSelector(deletePackageAction.actionName);
export const deletePackageLoading = createLoadingSelector(deletePackageAction.actionName);

export const deleteQuizError = createErrorSelector(deleteQuizAction.actionName);
export const deleteQuizLoading = createLoadingSelector(deleteQuizAction.actionName);

export const copyQuizError = createErrorSelector(copyQuizAction.actionName);
export const copyQuizLoading = createLoadingSelector(copyQuizAction.actionName);

export const copyLessonError = createErrorSelector(copyLessonAction.actionName);
export const copyLessonLoading = createLoadingSelector(copyLessonAction.actionName);

export const addQuizError = createErrorSelector(addQuizAction.actionName);
export const addQuizLoading = createLoadingSelector(addQuizAction.actionName);

export const addFolderError = createErrorSelector(addFolderAction.actionName);
export const addFolderLoading = createLoadingSelector(addFolderAction.actionName);

export const addLessonError = createErrorSelector(addLessonAction.actionName);
export const addLessonLoading = createLoadingSelector(addLessonAction.actionName);

export const getPackagesInfoError = createErrorSelector(getPackagesInfoAction.actionName);
export const getPackagesInfoLoading = createLoadingSelector(getPackagesInfoAction.actionName);

export const getPackagesFoldersError = createErrorSelector(getPackagesFoldersAction.actionName);
export const getPackagesFoldersLoading = createLoadingSelector(getPackagesFoldersAction.actionName);

export const addPackageMainError = createErrorSelector(addPackageMainAction.actionName);
export const addPackageMainLoading = createLoadingSelector(addPackageMainAction.actionName);

export const deletePackageMainError = createErrorSelector(deletePackageMainAction.actionName);
export const deletePackageMainLoading = createLoadingSelector(deletePackageMainAction.actionName);

export const deleteFolderMainError = createErrorSelector(deleteFolderMainAction.actionName);
export const deleteFolderMainLoading = createLoadingSelector(deleteFolderMainAction.actionName);

export const getFolderInfoError = createErrorSelector(getFolderInfoAction.actionName);
export const getFolderInfoLoading = createLoadingSelector(getFolderInfoAction.actionName);

export const changeFolderOrderError = createErrorSelector(changeFolderOrderAction.actionName);
export const changeFolderOrderLoading = createLoadingSelector(changeFolderOrderAction.actionName);

export const changePackageOrderError = createErrorSelector(changePackageOrderAction.actionName);
export const changePackageOrderLoading = createLoadingSelector(changePackageOrderAction.actionName);

export const addFolderMainError = createErrorSelector(addFolderMainAction.actionName);
export const addFolderMainLoading = createLoadingSelector(addFolderMainAction.actionName);

export const updateFolderError = createErrorSelector(updateFolderAction.actionName);
export const updateFolderLoading = createLoadingSelector(updateFolderAction.actionName);

export const updateLessonError = createErrorSelector(updateLessonAction.actionName);
export const updateLessonLoading = createLoadingSelector(updateLessonAction.actionName);

export const updateQuizError = createErrorSelector(updateQuizAction.actionName);
export const updateQuizLoading = createLoadingSelector(updateQuizAction.actionName);

export const movePackageIntoFolderMainError = createErrorSelector(
  movePackageIntoFolderMainAction.actionName,
);
export const movePackageIntoFolderMainLoading = createLoadingSelector(
  movePackageIntoFolderMainAction.actionName,
);

export const getPackagesState = state => state.packages;

export const permissionGroupsSelector = createSelector(
  getPackagesState,
  state => state.permissionGroups,
);

export const getFoldersList = createSelector(getPackagesState, state => state.foldersList);

export const getCurrentPackage = createSelector(getPackagesState, state => state.currentPackage);

export const getPackageTitle = createSelector(
  getCurrentPackage,
  currentPackage => currentPackage?.title,
);

export const getPackageDesc = createSelector(
  getCurrentPackage,
  currentPackage => currentPackage?.description,
);

export const getPackageFolderId = createSelector(
  getCurrentPackage,
  currentPackage => currentPackage?.course_folder_id,
);

export const getLessonsList = createSelector(getCurrentPackage, state => state?.lessons);

export const getCurrentPackageFolder = createSelector(
  getCurrentPackage,
  getFoldersList,
  (pck, folders) => {
    if (pck?.course_folder_id && folders?.length) {
      return folders.find(f => f.id === pck.course_folder_id);
    }
    return null;
  },
);

export const getPackagesWithoutFolders = createSelector(
  getPackagesState,
  state => state.packagesWithoutFolders,
);

export const getQuizzesList = createSelector(getCurrentPackage, state => state?.quizzes);

export const getFolders = createSelector(getPackagesState, state => state.folders);

export const getPackages = createSelector(getPackagesState, state => state.packages);

export const getFolderName = createSelector(getPackagesState, state => state.folderName);

export const moveCopyLoading = createSelector(
  copyLessonLoading,
  copyQuizLoading,
  moveLessonLoading,
  moveQuizLoading,
  (cl, cq, ml, mq) => cl || cq || ml || mq,
);

export const itemLoadingSelector = createSelector(
  addLessonLoading,
  addQuizLoading,
  updateLessonLoading,
  updateQuizLoading,
  updateLessonTypeLoading,
  updateQuizTypeLoading,
  (al, aq, ul, uq, ult, uqt) => al || aq || ul || uq || ult || uqt,
);
